<a class="node-card" [routerLink]="['/', hive.handle, 'about']" *ngIf="type == 'join'">
    <div class="node-interaction">
        <div class="node-tile">
            <div class="node-tile-image">
                <img *ngIf="!hive.cover_image" src="/assets/icon-hives.svg" alt="hive image">
                <img *ngIf="hive.cover_image" src="{{hive.cover_image}}" alt="hive image">
            </div>
            
            <div class="node-tile-info">
                <p>{{ hive.name }}</p>
                <p class="text-muted" *ngIf="hive.members">{{ hive.members.length | formatNumber }} {{ 'Member' | pluralize:hive.members.length }}</p>
            </div>
        </div>
        <button *ngIf="!loading" class="btn btn-primary btn-rounded" [ngClass]="{'joined': joined}" (click)="joinHive($event)">{{ joinBtnTxt }}</button>
        <loading-spinner *ngIf="loading"></loading-spinner>
    </div>
    <div class="node-description text-muted" *ngIf="hive.description">{{ hive.description }}</div>
</a>
<a class="node-card" [routerLink]="['/', hive.handle, 'businesses']" *ngIf="type == 'preview'">
    <div class="node-interaction">
        <div class="node-tile">
            <div class="node-tile-image">
                <img *ngIf="!hive.cover_image" src="/assets/icon-hives.svg" alt="hive image">
                <img *ngIf="hive.cover_image" src="{{hive.cover_image}}" alt="hive image">
            </div>
            
            <div class="node-tile-info">
                <p>{{ hive.name }}</p>
                <p class="text-muted" *ngIf="hive.members">{{ hive.members.length | formatNumber }} {{ 'Member' | pluralize:hive.members.length }}</p>
            </div>
        </div>
    </div>
    <div class="node-description text-muted" *ngIf="hive.description">{{ hive.description }}</div>
</a>


<a [routerLink]="['/', hive.handle, 'posts']" class="node-tile" *ngIf="type == 'tile'">
    <div class="node-tile-image square">
        <img *ngIf="!hive.cover_image" src="/assets/icon-hives.svg" alt="hive image">
        <img *ngIf="hive.cover_image" src="{{hive.cover_image}}" alt="hive image">    
    </div>
    
    <div class="node-tile-info">
        <p>{{ hive.name }}</p>
        <p class="text-muted" *ngIf="hive.members">{{ hive.members.length | formatNumber }} {{ 'Member' | pluralize:hive.members.length }}</p>
    </div>
</a>

<div class="node-card no-boundary" *ngIf="type == 'view'" (click)="viewClicked('/'+hive.handle+'/posts')">
    <div class="node-interaction">
        <node-tile 
            [link]="'/'+hive.handle+'/posts'"
            [defaultImage]="'/assets/icon-hives.svg'"
            [image]="hive.cover_image"
            [primaryTxt]="hive.name"
            [secondaryTxt]="secondaryTxt" 
            >
        </node-tile>
        <a [routerLink]="['/', hive.handle, 'posts']" class="btn btn-secondary btn-rounded" >View</a>
    </div>
    <div class="node-description text-muted" *ngIf="hive.description">{{ hive.description }}</div>
</div>

<a [routerLink]="['/', hive.handle, 'posts']" class="node-tile" *ngIf="type == 'activity'">
    <div class="node-tile-image square">
        <img *ngIf="!hive.cover_image" src="/assets/icon-hives.svg" alt="hive image">
        <img *ngIf="hive.cover_image" src="{{hive.cover_image}}" alt="hive image">    
    </div>
    
    <div class="node-tile-info">
        <p>{{ hive.name }}</p>
        <div *ngIf="hive.post_count != 0 || hive.comment_count != 0" class="notification-container">
            <div class="unread"></div>
            <p class="text-muted">
                <span *ngIf="hive.post_count != 0">{{ hive.post_count | formatNumber }} {{ 'new post' | pluralize:hive.post_count }}</span>
                <span *ngIf="hive.post_count != 0 && hive.comment_count != 0"> & </span>
                <span *ngIf="hive.comment_count != 0">{{ hive.comment_count | formatNumber }} {{ 'new comment' | pluralize:hive.comment_count }}</span>
            </p>
        </div>
        <p class="text-muted" *ngIf="hive.members && hive.post_count == 0 && hive.comment_count == 0">{{ hive.members.length | formatNumber }} {{ 'Member' | pluralize:hive.members.length }}</p>
    </div>
</a>