<div class="invite-hives">

    <search [searchType]="searchType" [isModalSearch]="true" (recordOutput)="setHives($event)"></search>

    <p *ngIf="!hives">No hives found</p>
    
    <div class="hives" *ngIf="hives">
        <div *ngFor="let hive of hives" class="flex space-between flex-center hive">
            <hive-card  [type]="'tile'" [hive]="hive"></hive-card>
            <button class="add-btn btn btn-secondary btn-rounded" (click)="toggleHiveSelection(hive.id)" [ngClass]="{'added': selectedHiveIds.includes(hive.id)}">
                Add
            </button>
        </div>
    </div>
</div>