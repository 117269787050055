<div class="tag-input">
    <div class="tags">
        <span *ngFor="let tag of tags" class="tag">
            {{ tag }}
            <span class="remove-tag" (click)="removeTag(tag)">×</span>
        </span>
    </div>
    <div *ngIf="tags && tags.length < maxTags" class="field-group fancy-shmancy">
        <div>
            <input
                hasContent
                type="text"
                [(ngModel)]="tagInput"
                (input)="onTagInputChange()"
                (keydown)="onInputKeydown($event)"
                (keyup)="onInputKeyup($event)"
                [disabled]="tags.length >= maxTags"
            >
            <label *ngIf="existingTags.length < 1">Search Tags</label>  
            <label *ngIf="existingTags.length >= 1">Search Tags (ex. {{ existingTags[0]}})</label>
        </div>
    </div>
    
    <ul class="suggestions" *ngIf="suggestions.length">
        <li *ngFor="let suggestion of suggestions; let i = index"
            (mouseenter)="onSuggestionMouseEnter()"
            (mouseleave)="onSuggestionMouseLeave()"
            [class.hover]="!isHovering && i === 0"
            (click)="addTag(suggestion)">
            {{ suggestion }}
        </li>
    </ul>
</div>
  