import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HubhiveEvent } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class EventService {
    currentEvent: BehaviorSubject<HubhiveEvent|null> = new BehaviorSubject<HubhiveEvent|null>(null)
    userIsAttending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    refreshHives: Subject<void> = new Subject<void>()
    
    constructor() { }
}
