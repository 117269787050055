<div class="flex align-center hives" *ngIf="hives && hives.length" (click)="showHives($event)">
    <img src="/assets/icon-hive-dark.svg" alt="hive icon">
    <div>
        <span>{{ !shared ? 'In' : 'Shared with' }} <span class="highlight">{{ hives[0].name }}</span></span> 
        <span *ngIf="hives.length > 1"> & <span class="highlight">{{ hives.length - 1 }} {{ 'other' | pluralize:hives.length - 1 }}</span></span>
    </div>
</div>

<ng-template #modal let-data="data">
    <div class="hives">
        <hive-card *ngFor="let h of hives" [hive]="h" [type]="'view'" (clicked)="closeModal()"></hive-card>
    </div>
</ng-template>