import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductAPIService } from 'src/app/_api-services/product-api.service';
import { SearchType } from 'src/app/_enums';
import { Product } from 'src/app/_models';
import { ModalService } from 'src/app/_services';

@Component({
	selector: 'product-create-options',
	templateUrl: './product-create-options.component.html',
	styleUrl: './product-create-options.component.scss'
})
export class ProductCreateOptionsComponent implements OnDestroy{
    private subscriptions: Subscription = new Subscription()

	showManual: boolean = false
	showScanner: boolean = false
	showResults: boolean = false
	searchType: SearchType = SearchType.Product
	results: Product | null = null
	searchString: string | null = null
	products: Product[] | null = null

	constructor (
		private modalService: ModalService,
		private productAPIService: ProductAPIService
	) {}

	ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

	toggleScanner() {
		if (this.showScanner == false) {
			this.showScanner = true
			return
		}

		this.showScanner = false
	}

	toggleManual() {
		if (this.showManual == false) {
			this.showManual = true

			this.modalService.activateFooterBtn()
        	this.modalService.updateFooterBtnTxt('Create Product')
			return
		}

		this.showManual = false
	}

	close() {
		this.modalService.close()
	}

	showProductResults(){
		this.showResults = true
		this.showManual = false
		this.showScanner = false
		this.modalService.activateFooterBtn()
		this.modalService.updateTitle('Results')

		if (!this.results?.variants?.length) {
			this.modalService.updateFooterBtnTxt("Return to scanner")
			return
		}
		this.modalService.updateFooterBtnTxt('Add Product')
	}



	onResults(results: Product){
		this.results = results
		this.showProductResults()
	}

	setProducts(results: Product[] | null) {
		this.products = results
	}

	selectSearchProduct(inProduct: Product) {
		//the product being send in isn't complete
		this.subscriptions.add(
            this.productAPIService.getProduct(inProduct.id).subscribe( product => {
                this.results = product
				this.showProductResults()
            })
        )
	}
}
