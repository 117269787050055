import { Component, Input, OnInit} from '@angular/core';
import { Business } from 'src/app/_models';
@Component({
  selector: 'business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss']
}) 
export class BusinessCardComponent {
    @Input() business: Business | null = null
    @Input() context: string = "normal"

    loading: boolean = true
    
    showForm: boolean = false
    link: string[] = ['/', 'business']
    
    constructor() {
        this.loading = false
    }
    
    toggleInfoForm(): void {
        this.showForm = !this.showForm
    }
    
    getDetailLink(businessHandle: string): string[] {
        return [...this.link, businessHandle, 'details']
    }

}
