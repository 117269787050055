<div *ngIf="business">
    <div class="company-specs">
        <part-of-hives [hives]="business.hives"></part-of-hives>
        <div class="flex align-center" *ngIf="business.show_address && business.city && business.state" >
            <img src="/assets/icon-location.svg" alt="location icon">
            <div class="business-location">
                <span>
                    <span *ngIf="business.street">{{ business.street }} </span>    
                    <span>{{ business.city }}, {{ business.state }} </span>
                    <span *ngIf="business.zip">{{ business.zip }}</span>
                </span>
            </div>
        </div>
    </div>
    <business-admin-bar [handle]="business.handle" *ngIf="owner"></business-admin-bar>
    <div class="company-details">
        <h2>Company Details</h2>
        
        <div *ngIf="business.summary">
            <p>{{ business.summary }}</p>
        </div>
    </div>
</div>

