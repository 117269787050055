import { Injectable, TemplateRef } from '@angular/core';
import { ModalBottomComponent } from '../shared/common/components/modal-bottom/modal-bottom.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalBottomService {
    private modalComponent: ModalBottomComponent | null = null
    
    doCloseAction: Subject<void> = new Subject<void>()
    doOpenAction: Subject<void> = new Subject<void>()
    doPrimaryAction: Subject<void> = new Subject<void>()
    
    bindModalComponent(component: ModalBottomComponent): void {
        this.modalComponent = component
    }
    
    open(title: string, content: TemplateRef<any>, context?: any): void {
        if(this.modalComponent) {
            this.modalComponent.open(title, content, context)
            this.doOpenAction.next()
        } else {
            console.error('modal not bound')
        }
    }
    
    updateTitle(title: string): void {
        if(this.modalComponent) {
            this.modalComponent.title = title    
        }
    }
    
    activatePrimaryBtn(text: string): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.showPrimaryBtn = true
        this.modalComponent.primaryBtnTxt = text
    }
    
    activateCancelBtn(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.showCancelBtn = true
    }
    
    primaryBtnClicked(): void {
        this.doPrimaryAction.next()
    }

    close(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.doCloseAction
        this.updateTitle("")
        this.modalComponent.showCancelBtn = false
        this.modalComponent.showPrimaryBtn = false
        this.modalComponent.close()
    }
    
    transform(): void {
        if(!this.modalComponent) {
            return
        }    
        
        this.modalComponent.transform = true
    }
    
    noTransform(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.transform = false
    }
    
    startLoading(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.startLoading()
    }
    
    stopLoading(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.stopLoading()
    }
}
