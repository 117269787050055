import { Component, Input, Output, EventEmitter, TemplateRef, OnDestroy   } from '@angular/core';
import { ModalService } from 'src/app/_services';
@Component({
  selector: 'plus-button-dialog',
  templateUrl: './plus-button-dialog.component.html',
  styleUrl: './plus-button-dialog.component.scss'
}
)


export class PlusButtonDialogComponent implements OnDestroy {
    @Input() isActive: boolean = false;
    @Output() close = new EventEmitter<void>()

    constructor (
        private modalService: ModalService,
    ){}
    
    ngOnDestroy(): void {
        this.modalService.close()
    }

    closeModal() {
        this.isActive = false
        this.close.emit()
    }

    createHive(content: TemplateRef<any>): void {
        this.close.emit()
        this.modalService.open("Add new hive", content)
    }

    createBusiness(content: TemplateRef<any>): void {
        this.close.emit()
        this.modalService.open("Create New Business", content)
    }

    createEvent(content: TemplateRef<any>): void {
        this.close.emit()
        this.modalService.open("Create Event", content)
    }
    
    createProduct(content: TemplateRef<any>): void {
        this.close.emit()
        this.modalService.open("Create New Product", content)
    }

}