import { Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { ModalService } from 'src/app/_services';
import { ClipboardHelper } from 'src/app/_helpers/clipboard.helper';

@Component({
    selector: 'business-admin-bar',
    templateUrl: './business-admin-bar.component.html',
    styleUrls: ['./business-admin-bar.component.scss']
})
export class BusinessAdminBarComponent implements OnDestroy{
    @Input() handle!: string
    shareMessage: string = ""
    
    constructor(
        private modalService: ModalService,
        public clipboardHelper: ClipboardHelper,
    ) {}
    
    ngOnDestroy(): void {
        this.modalService.close()
    }

    createEvent(content: TemplateRef<any>): void {
        this.modalService.open("Create Event", content)
    }
    
    createProduct(content: TemplateRef<any>): void {
        this.modalService.open("Create New Product", content)
    }

    handleShare() {
        this.clipboardHelper.handleShare(`/business/${this.handle}/details`) 
    }

    handleAddToHives(content: TemplateRef<any>): void {
        this.modalService.open("Select Hives to share your business with", content) 
    }
}
