export enum SearchType {
    Business = "BUSINESS",
    Hive = "HIVE",
    User = "USER",
    HiveBusinesses = "HIVE_BUSINESSES",
    HiveProducts = "HIVE_PRODUCTS",
    HiveUsers = "HIVE_USERS",
    HivePeers = "HIVE_PEERS",
    BusinessProducts = "BUSINESS_PRODUCTS",
    Product = "PRODUCTS",
    UserHives = "USER_HIVES",
    Event = "EVENT",
    Anything = "ANYTHING",
}