import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Hive, User } from 'src/app/_models';
import { ModalService, AuthenticationService } from 'src/app/_services';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HiveService } from 'src/app/_services/hive.service';
import { Router  } from '@angular/router';
import { SearchType } from 'src/app/_enums';
import { HiveAPIService, UserAPIService } from 'src/app/_api-services';

@Component({
  selector: 'hive-add-members',
  templateUrl: './hive-add-members.component.html',
  styleUrls: ['./hive-add-members.component.scss']
})
export class HiveAddMembersComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    
    defaultMembers: User[] | null = null
    defaultHives: Hive[] | null = null
    members: User[] | null = null
    hives: Hive[] | null = null
    addForm!: FormGroup
    selectedMemberIds: string[] = [];
    selectedHiveIds: string[] = [];
    hiveHandle!: string | null
    hivePeerSearch: SearchType = SearchType.HivePeers
    hiveSearch: SearchType = SearchType.Hive
    searchType: SearchType = SearchType.HivePeers
    activeTab: string = 'Contacts';
    unregisteredEmails: string[] = []
    
    constructor(
        private userAPIService: UserAPIService,
        private hiveAPIService: HiveAPIService,
        private modalService: ModalService,
        private fb: FormBuilder,
        private authService: AuthenticationService,
        private hiveService: HiveService,
        private router: Router,
    ) {
        this.modalService.activateFooterBtn()
        this.modalService.updateTitle('Add Members')
        this.modalService.updateFooterBtnTxt('Continue')
        
        this.subscriptions.add(
            this.hiveService.currentHive.subscribe( currentHive => {
                this.hiveHandle = currentHive ? currentHive.handle : null
            })
        )
        
        this.subscriptions.add(
            this.modalService.doFooterAction.subscribe( action => {
                this.inviteMembers()
            })
        )
        
        this.setActiveTab('Contacts')
        this.formSetup()
    }

    ngOnDestroy(): void {
        this.formTakeDown()
        this.subscriptions.unsubscribe()
    }

    setActiveTab(tabName: string): void {
        this.activeTab = tabName
        
        let title: string
        
        switch(tabName) {
            case 'Contacts':
                title = 'Add Members'
                this.searchType = this.hivePeerSearch
                this.setButtonMembers()
                
                this.subscriptions.add(
                    this.userAPIService.getCurrentUserHiveContacts().subscribe({
                        next: (members) => {
                            this.defaultMembers = members
                            this.members = this.defaultMembers
                        },
                        error: (err) => {
                            console.log(err)
                        }
                    })    
                )
            break
            
            case 'Hives':
                title = 'Share in a hive'
                this.searchType = this.hiveSearch
                this.setButtonHives()
                
                this.subscriptions.add(
                    this.hiveAPIService.getUserHives().subscribe({
                        next: (hives) => {
                            this.defaultHives = hives
                            this.hives = this.defaultHives
                        },
                        error: (err) => {
                            console.log(err)
                        }
                    })    
                )
            break
            
            default:
                title = 'Add Members'
        }
        
        
        this.modalService.updateTitle(title)
    }

    isActive(tabName: string): boolean {
        return this.activeTab === tabName
    }


    get name() {
        return this.addForm?.get("name")
    }

    get type() {
        return this.addForm?.get("type")
    }

    formSetup(): void {
        this.addForm = this.fb.group({
            name: new FormControl('', Validators.required),
            type: new FormControl('public',Validators.required)
        })
    }

    formTakeDown(): void {
        if (this.addForm) {
            this.addForm.reset({
                name: '',
                type: ''
            })
        }
        this.modalService.close()
    }

    toggleMemberSelection(memberId: string): void {
        const index = this.selectedMemberIds.indexOf(memberId)
        
        if (index >= 0) {
            this.selectedMemberIds.splice(index, 1)
        } else {
            this.selectedMemberIds.push(memberId)
        }
        
        this.updateButton()
    }
    
    toggleHiveSelection(hiveId: string): void {
        const index = this.selectedHiveIds.indexOf(hiveId)
        
        if (index >= 0) {
            this.selectedHiveIds.splice(index, 1)
            this.removeSelectedHiveMembers(hiveId)
        } else {
            this.selectedHiveIds.push(hiveId)
            this.addSelectedHiveMembers(hiveId)
        }
        
        this.updateButton()
    }
    
    updateButton(): void {
        if(this.selectedHiveIds.length > 0 && this.activeTab == 'Hives') {
            this.modalService.updateFooterBtnTxt('Share to Selected Hives')
            return
        }
        
        if(this.selectedMemberIds.length > 0 && this.activeTab == 'Contacts') {
            this.modalService.updateFooterBtnTxt('Invite Selected')
            return
        }
        
        this.modalService.updateFooterBtnTxt('Continue')
    }
    
    setButtonHives(): void {
        if(this.selectedHiveIds.length <= 0) {
            return
        }
        
        this.modalService.updateFooterBtnTxt('Share to Selected Hives')
    }
    
    setButtonMembers(): void {
        if(this.selectedMemberIds.length <= 0) {
            return
        }
        
        this.modalService.updateFooterBtnTxt('Invite Selected')
    }

    addSelectedHiveMembers(id: string): void { 
        if(!this.hives) {
            return
        }
        
        for(const hive of this.hives) {
            if(hive.id != id) {
                continue
            }
            
            for(const member of hive.members) {
                if(this.selectedMemberIds.includes(member.id)) {
                    continue
                }
                
                this.selectedMemberIds.push(member.id)
            }
            
            break
        }
    }
    
    removeSelectedHiveMembers(id: string): void {
        if(!this.hives) {
            return
        }
        
        for(const hive of this.hives) {
            if(hive.id != id) {
                continue
            }
            
            for(const member of hive.members) {
                if(!this.selectedMemberIds.includes(member.id)) {
                    continue
                }
                
                const index = this.selectedMemberIds.indexOf(member.id)
                this.selectedMemberIds.splice(index, 1)
            }
            
            break
        }
    }
    
    inviteMembers(): void{
        if (this.selectedMemberIds.length > 0 && this.hiveHandle) {
            this.modalService.doLoading.next(true)
            this.subscriptions.add(
                this.hiveAPIService.invite(this.hiveHandle, this.selectedMemberIds).subscribe({
                    next: (response) => {
                        this.modalService.doLoading.next(false)
                        this.modalService.setRequestSuccess()
                        let to = setTimeout(() => {
                            this.router.navigate([this.hiveHandle, 'about'])
                            this.formTakeDown()
                            clearTimeout(to)
                        }, 3000)
                    },
                    error: err => {
                        this.modalService.doLoading.next(false)
                        this.modalService.setRequestFailure(err.message)
                    }
                })  
            )
            
            return
        }
        
        this.router.navigate([this.hiveHandle, 'about'])
        this.modalService.close()
    }

    setMembers(data: [User[] | null, boolean, string[]]): void {
        const removeDuplicates = (arr: User[], key: keyof User) => {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        }
        
        let users = data[0]
        let bool = data[1]
        let unregisteredEmails = data[2]
        
        if (users && users.length < 1 && unregisteredEmails.length < 1) {
            this.members = null
            this.unregisteredEmails = this.getEmailsThatAreInvited()
            return
        }
        
        if (users && users.length > 0) {
            let memberPlaceholder = users.filter(person => person.id !== this.authService.currentUserValue.user.id)
            this.members = removeDuplicates(memberPlaceholder, 'id')
            
            if(bool) {
                this.members.forEach((member: any) => {
                    if(!this.selectedMemberIds.includes(member.id)) {
                        this.selectedMemberIds.push(member.id)
                    }
                })
            }
            
        } else {
            this.members = this.defaultMembers    
        }

        if (unregisteredEmails.length > 0) {
            this.unregisteredEmails = this.getEmailsThatAreInvited()
            this.unregisteredEmails = [...new Set([...this.unregisteredEmails, ...unregisteredEmails])]
            this.selectedMemberIds.push(...this.unregisteredEmails)
            this.selectedMemberIds = [...new Set(this.selectedMemberIds)]
        }
        
        this.sortMembers()
        
        if(this.selectedMemberIds.length) {
            this.modalService.updateFooterBtnTxt('Invite Members')
        }
        
    }
    
    getEmailsThatAreInvited(): string[] {
        if(!this.unregisteredEmails) {
            return []
        }
        
        let invitedEmails = this.selectedMemberIds.filter( id => this.unregisteredEmails?.includes(id))
        return invitedEmails
    }
    
    setHives(hives: Hive[]): void {
        if (hives && hives.length < 1) {
            this.hives = null
            return
        }
        
        if (hives && hives.length > 0) {
            this.hives = hives
            return
        } 
        
        this.hives = this.defaultHives
    }
    
    sortMembers(): void {
        if(!this.members) {
            return
        }
        
        this.members.sort((a, b) => {
            const indexA = this.selectedMemberIds.indexOf(a.id)
            const indexB = this.selectedMemberIds.indexOf(b.id)
            
            if(indexA > -1 && indexB > -1) {
                return indexA - indexB
            }
            
            if(indexA > -1) {
                return -1
            }
            
            if(indexB > -1) {
                return 1
            }
            
            return 0
        })
    }
}
