<div class="input-group">
    <input
        type="text"
        class="country-input"
        placeholder="Country"
        autocomplete="new-text"
        [(ngModel)]="selectedCountryName"
        (focus)="showDropdown = true"
        (input)="filterCountries()"
        (keyup)="manageKeyPress($event)"
        (blur)="onBlur()"
    />
    <div class="dropdown" *ngIf="showDropdown">
        <div class="dropdown-item"
            *ngFor="let country of filteredCountries"
            (mousedown)="selectCountry(country)">
            
            {{ country.name }}
        </div>
    </div>
</div>