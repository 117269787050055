import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollPositionService {
    private positions: { [url: string]: number } = {}
    private order: string[] = []
    
    constructor() { }
    
    saveScrollPosition(url: string): void {
        if(!this.positions[url]) {
            this.order.push(url)
        }
        
        this.positions[url] = window.scrollY
        
        if(this.order.length > 3) {
            const oldestKey = this.order.shift()
            if(oldestKey) {
                delete this.positions[oldestKey]
            }
        }
    }
    
    getScrollPosition(url: string): number {
        return this.positions[url] || 0
    }
    
    clearScrollPosition(url: string): void {
        delete this.positions[url]
    }
    
    hasScrollPosition(url: string): boolean {
        return this.positions[url] !== undefined
    }
}
