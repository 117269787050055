import { Component, EventEmitter, Output } from '@angular/core';
import { Hive } from 'src/app/_models';

@Component({
  selector: 'create-hive-container',
  templateUrl: './hive-create-container.component.html',
  styleUrl: './hive-create-container.component.scss'
})
export class CreateHiveContainerComponent {
    isHiveCreated: boolean = false
    
    constructor(
    ) {
    }

	handleHiveCreated(hive: Hive) {
		this.isHiveCreated = true
	}
}
