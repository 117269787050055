export enum FilterType {
    Business = "BUSINESS",
    Hive = "HIVE",
    User = "USER",
    HiveBusinesses = "HIVE_BUSINESSES",
    HiveProducts = "HIVE_PRODUCTS",
    HiveUsers = "HIVE_USERS",
    HiveEvents = "HIVE_EVENTS",
    HivePeers = "HIVE_PEERS",
    BusinessProducts = "BUSINESS_PRODUCTS",
    Product = "PRODUCTS",
    UserHives = "USER_HIVES",
    HubhiveEvent = "HubhiveEvent",
    Global = "GLOBAL",
}