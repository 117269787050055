<div class="dropdown">
    <button class="dropdown-button" (click)="toggleModal()"></button>
</div>

<div class="modal-background" [class.active]="isDropdownOpen" (click)="toggleModal()">
    <div class="modal-content" stopPropagation>
        <ul>
            <li *ngFor="let option of options" (click)="onOptionClick(option.action, option.keep_open)">{{ option.label }}</li>
        </ul>
        <div *ngIf="cbh.copiedMessageVisable" class="message">{{ cbh.shareMessage }}</div>
    </div>
</div>