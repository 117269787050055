<a class="node-card no-boundary" [routerLink]="['/user', user.id]" *ngIf="!tileOnly">
    <div class="node-interaction">
        <div class="node-tile">
            <div class="node-tile-image">
                <img *ngIf="user.avatar" src="{{ user.avatar }}" alt="user avatar">
                <missing-avatar *ngIf="!user.avatar" [user]="user"></missing-avatar>
            </div>
            
            <div class="node-tile-info">
                <p title="{{ user.first_name}} {{ user.last_name }}">{{ user.first_name }} {{ user.last_name }}</p>
                <p class="text-muted" *ngIf="relationship">{{ relationship }}</p>
            </div>
        </div>
        <loading-spinner *ngIf="loading"></loading-spinner>
    </div>
</a>


<a routerLink="/user/{{ user.id }}" class="node-tile" *ngIf="tileOnly && isLink">
    <div class="node-tile-image">
        <img *ngIf="user.avatar" src="{{ user.avatar }}" alt="user avatar">
        <missing-avatar *ngIf="!user.avatar" [user]="user"></missing-avatar>
    </div>
    
    <div class="node-tile-info">
        <p class="info-title" title="{{ user.first_name}} {{ user.last_name }}">{{ user.first_name }} {{ user.last_name }}</p>
        <p class="discussion-timestamp" *ngIf="timeStamp">{{timeStamp}}</p>
        <p class="text-muted" *ngIf="relationship">{{ relationship }}</p>
    </div>
</a>

<div class="node-tile" *ngIf="tileOnly && !isLink">
    <div class="node-tile-image">
        <img *ngIf="user.avatar" src="{{ user.avatar }}" alt="user avatar">
        <missing-avatar *ngIf="!user.avatar" [user]="user"></missing-avatar>
    </div>
    
    <div class="node-tile-info">
        <p title="{{ user.first_name}} {{ user.last_name }}">{{ user.first_name }} {{ user.last_name }}</p>
        <p class="discussion-timestamp" *ngIf="timeStamp">{{timeStamp}}</p>
        <p class="text-muted" *ngIf="relationship">{{ relationship }}</p>
    </div>
</div>