<loading-spinner *ngIf="loading"></loading-spinner>

<section *ngIf="business && context=='normal'">
    <div class="flex flex-column businesses">
        <div class="business normal">
            <a [routerLink]="getDetailLink(business.handle)">
                <business-review-overview 
                    [averageRating]="business.average_rating" 
                    [totalReviews]="business.total_reviews" 
                    [trustScore]="business.hive_score"
                    [companyName]="business.name"
                    [context]="'lightweight'"
                    [claimed]="business.claimed"
                    [logo]="business.logo">
                </business-review-overview>
                
                <div *ngIf="business.industries; " class="industry">
                    <span *ngFor="let i of business.industries; index as j" class="industry">{{ i.name }} <span *ngIf="j != business.industries.length - 1">&#x2022;</span> </span>
                </div>

                <div *ngIf="business.claimed && business.approved" class="verified-business">
                    <img src="assets/icon-verified.svg" alt="verified icon">
                    <p>VERIFIED</p>
                </div>

                <p class="location" *ngIf="business.city && business.state">
                    <span>{{ business.city }}, {{ business.state }}</span> 
                </p>
                
                <part-of-hives [hives]="business.hives"></part-of-hives>
                
                <p class="summary" *ngIf="business.summary">{{ business.summary }}</p>
            </a>
            
            <!-- <div class="btn-container">
                <a class="btn btn-primary btn-rounded" (click)="toggleInfoForm()">Request Info</a>
            </div> -->
        </div>
    </div>
</section>

<section *ngIf="business && context=='lightweight'">
    <div class="business lightweight">
        <a [routerLink]="getDetailLink(business.handle)">
            <business-review-overview 
                [averageRating]="business.average_rating" 
                [totalReviews]="business.total_reviews" 
                [trustScore]="business.hive_score"
                [companyName]="business.name"
                [context]="'lightweight'"
                [claimed]="business.claimed">
            </business-review-overview>

            <p class="location" *ngIf="business.city && business.state">
                <span>{{ business.city }}, {{ business.state }}</span> 
            </p>

            <div *ngIf="business.claimed && business.approved" class="verified-business">
                <img src="assets/icon-verified.svg" alt="verified icon">
                <p>VERIFIED</p>
            </div>
        </a>
                    
        <div *ngIf="business.industries; " class="industry">
            <span *ngFor="let i of business.industries; index as j" class="industry">{{ i.name }} <span *ngIf="j != business.industries.length - 1">&#x2022;</span> </span>
        </div>
        
        <part-of-hives [hives]="business.hives"></part-of-hives>
        
        <!-- <div class="btn-container">
            <a class="btn btn-primary btn-rounded" (click)="toggleInfoForm()">Request Info</a>
        </div> -->
    </div>
</section>

<formless *ngIf="showForm" (closeForm)="toggleInfoForm()"></formless>