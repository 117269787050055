import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Renderer2, TemplateRef } from '@angular/core';
import { ModalBottomService } from 'src/app/_services';

@Component({
  selector: 'modal-bottom',
  templateUrl: './modal-bottom.component.html',
  styleUrl: './modal-bottom.component.scss'
})
export class ModalBottomComponent implements AfterViewInit {
    content: TemplateRef<any> | null = null
    context: any = {}
    htmlBody: any
    title: string = ''
    primaryBtnTxt: string = ''
    isVisible: boolean = false
    showCancelBtn: boolean = false
    showPrimaryBtn: boolean = false
    transform: boolean = true
    loading: boolean = false
    
    constructor(
        private renderer: Renderer2,
        private el: ElementRef, 
        private cd: ChangeDetectorRef,
        private bModalService: ModalBottomService,
    ){}
    
    ngAfterViewInit(): void {
        this.htmlBody = this.el.nativeElement.ownerDocument.body
    }
    
    open(title: string, content: TemplateRef<any>, context: any): void {
        this.content = content
        this.context = context
        this.title = title
        this.isVisible = true
        this.cd.detectChanges()
        this.makeBodyUnscrollable()
    }
    
    close(): void {
        this.resetModal()
        this.isVisible = false
        this.bModalService.doCloseAction.next()
    }
    
    doPrimaryAction(): void {
        this.bModalService.primaryBtnClicked()
    }
    
    startLoading(): void {
        this.loading = true
        this.showPrimaryBtn = false
    }
    
    stopLoading(): void {
        this.loading = false
        this.showPrimaryBtn = true
    }
    
    makeBodyUnscrollable(): void {
        this.renderer.addClass(this.htmlBody, 'no-scroll')
    }
    
    makeBodyScrollable(): void {
        this.renderer.removeClass(this.htmlBody, 'no-scroll')
    }
    
    resetModal(): void {
        this.makeBodyScrollable()
        this.showCancelBtn = false
        this.showPrimaryBtn = false
        this.loading = false        
    }
}
