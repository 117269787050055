<div *ngIf="!link" class="node-tile">
    <div class="node-tile-image">
        <img *ngIf="image" src="{{ image }}" alt="tile image">
        <img *ngIf="defaultImage && !image" src="{{ defaultImage }}" alt="tile image">
    </div>
    
    <div class="node-tile-info">
        <p title="{{ primaryTxt }}">{{ primaryTxt }}</p>
        <p class="text-muted" *ngIf="secondaryTxt">{{ secondaryTxt }}</p>
    </div>
</div>

<a *ngIf="link" [routerLink]="link" [queryParams]="queryParams" class="node-tile">
    <div class="node-tile-image">
        <img *ngIf="image" src="{{ image }}" alt="tile image">
        <img *ngIf="defaultImage && !image" src="{{ defaultImage }}" alt="tile image">
    </div>
    
    <div class="node-tile-info">
        <p title="{{ primaryTxt }}">{{ primaryTxt }}</p>
        <p class="text-muted" *ngIf="secondaryTxt">{{ secondaryTxt }}</p>
    </div>
</a>