import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime'
})
export class DateTime implements PipeTransform {

   constructor() {}

  transform(date: string): string {
    const d = new Date(date);

    let formattedDate = d.toLocaleString(undefined, {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });

      const parts = formattedDate.split(/, | at /);
      const result = `${parts[0]}, ${parts[1]} - ${parts[2]}`;

      return result
  }
    
}