import { Component, OnDestroy, ViewChild, TemplateRef, EventEmitter, Output, ElementRef, OnInit, Input, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ModalService } from 'src/app/_services';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl, ValidationErrors, FormControlState } from '@angular/forms';
import { HiveService } from 'src/app/_services/hive.service';
import { Subscription, Observable, of } from 'rxjs';
import { CommonValidators } from 'src/app/_validators/common.validators';
import { map } from 'rxjs/operators';
import { Hive, HiveCategory } from 'src/app/_models';
import { HiveAPIService } from 'src/app/_api-services';
import { AccountNavigationService } from 'src/app/_services/account-navigation.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager'

@Component({
    selector: 'hive-create',
    templateUrl: './hive-create.component.html',
    styleUrls: ['./hive-create.component.scss']
})
export class HiveCreateComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild('modalContent') modalContent: TemplateRef<any> | null = null
    @ViewChild('nameInput') nameInput!: ElementRef
    @ViewChild('descriptionInput') descriptionInput!: ElementRef
    @Input('updateHive') uptHive: Hive | null = null
    @Output() hiveCreated: EventEmitter<Hive> = new EventEmitter<Hive>()

    private subscriptions: Subscription = new Subscription()

    hiveForm!: FormGroup
    hiveHandle!: string
    hiveImage: File | null = null
    initialName: string = ''
    categories: HiveCategory[] = []
    subCategories: HiveCategory[] = []
    tags: HiveCategory[] = []
    tagNames: string[] = []

    constructor(
        private hiveAPIService: HiveAPIService,
        private modalService: ModalService,
        private fb: FormBuilder,
        private hiveService: HiveService,
        private accountNavigationService: AccountNavigationService,
        private gtmService: GoogleTagManagerService,
    ) {
        this.modalService.activateFooterBtn()

        this.subscriptions.add(
            this.hiveAPIService.getHiveCategories().subscribe({
                next: hc => {
                    this.categories = hc
                    if (this.uptHive) {
						this.setInitCategories()
                    }
                },
                error: err => {
                    console.log(err)
                }
            })
        )

    }

    ngOnInit(): void {
        if (this.uptHive) {
            this.initialName = this.uptHive.name
            this.modalService.updateTitle('Update Your Hive')
            this.modalService.updateFooterBtnTxt('Update Hive')
            this.subscriptions.add(
                this.modalService.doFooterAction.subscribe(action => {
                    this.updateHive()
                })
            )
        } else {
            this.modalService.updateTitle('Create New Hive')
            this.modalService.updateFooterBtnTxt('Create Hive')

            this.subscriptions.add(
                this.modalService.doFooterAction.subscribe(action => {
                    this.addHive()
                })
            )
        }
        this.formSetup()
    }
    
    ngAfterViewChecked(): void {
        if (this.hiveForm && this.uptHive) {
            this.doChangeOnAllInputs()
        }
        
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    formSetup(): void {
        let subCategoryId = ''
        let tagNames: any[] | FormControlState<any[]> = []
        
        if (this.uptHive && this.uptHive.category.sub_categories) {
            subCategoryId = this.uptHive.category.sub_categories[0].id
            
            if(this.uptHive.category.sub_categories[0].tags) {
                tagNames = this.uptHive.category.sub_categories[0].tags.map(tag => tag.name)    
            }
        }
        
        let type = this.uptHive ? this.uptHive.type : 'public'
        let name = this.uptHive ? this.uptHive.name : '' 
        let description = this.uptHive ? this.uptHive.description : ''
        let category = this.uptHive ? this.uptHive.category.id : ''
        let subCat = subCategoryId ? subCategoryId : ''
        
        this.hiveForm = this.fb.group({
            type: new FormControl(type, Validators.required),
            image: new FormControl('', [
                CommonValidators.isImage(() => this.hiveImage),
                CommonValidators.maxFileSize(5, () => this.hiveImage)
            ]),
            name: new FormControl(name, {
                validators: [Validators.required],
                asyncValidators: [this.checkIfNameExists.bind(this)],
            }),
            description: new FormControl(description),
            category: new FormControl(category),
            sub_category: new FormControl(subCat),
            tags: new FormControl(tagNames),
        })
    }

    get name() {
        return this.hiveForm?.get("name")
    }

    get type() {
        return this.hiveForm?.get("type")
    }

    formTakeDown(): void {
        if (!this.hiveForm) {
            return
        }

        this.hiveForm.reset({
            name: '',
            type: ''
        })
    } 

    doChangeOnAllInputs(): void {
        if (!this.uptHive) {
            return
        }

        if (this.uptHive.name) {
            this.nameInput.nativeElement.classList.add("has-content")
        }

        if (this.uptHive.description) {
            this.descriptionInput.nativeElement.classList.add("has-content")
        }
    }

    setHiveImage(file: File | null) {
        this.hiveImage = file
    }

    prepareForm() {
        if (!this.hiveForm?.valid) {
            this.hiveForm?.markAllAsTouched()
            return
        }
        
        this.modalService.doLoading.next(true)

        const formData = new FormData()

        formData.append('name', this.hiveForm!.get('name')!.value)
        formData.append('description', this.hiveForm!.get('description')!.value)
        formData.append('type', this.hiveForm!.get('type')!.value) 
        
        if(this.uptHive) {
            formData.append('category', this.hiveForm!.get('category')!.value)
            formData.append('sub_categories', this.hiveForm!.get('sub_category')!.value)
            formData.append('tags', this.hiveForm!.get('tags')!.value)
        }

        if (this.hiveImage) {
            formData.append('image', this.hiveImage)
        }
        
        return formData
    }

    addHive() {
        const formData = this.prepareForm() || new FormData()

        this.subscriptions.add(this.hiveAPIService.createHive(formData).subscribe({
            next: (newHive) => {
                let tag = {
                    event: 'hive_created',
                    category: 'Form',
                    label: 'Create Hive',
                    value: newHive,
                }
                
                this.gtmService.pushTag(tag)
                this.hiveHandle = newHive.handle
                this.hiveService.currentHive.next(newHive)
                this.modalService.doLoading.next(false)
                this.accountNavigationService.addMyHive(newHive)
                this.formTakeDown()
                this.hiveCreated.emit(newHive)
            },
            error: (err) => {
                this.modalService.doLoading.next(false)
                this.modalService.setRequestFailure(err.error)
                console.log(err)
            }
        }))
    }

    updateHive() {
        const formData = this.prepareForm() || new FormData()
        if (this.uptHive) {
            formData.append("id", this.uptHive.id)
        }

        this.subscriptions.add(this.hiveAPIService.updateHive(formData).subscribe({
            next: (updatedHive) => {
                this.hiveHandle = updatedHive.handle
                this.hiveService.currentHive.next(updatedHive)
                this.accountNavigationService.updateHives(updatedHive)
                this.formTakeDown()
                this.hiveCreated.emit(updatedHive)
            },
            error: (err) => {
                console.log(err)
            }
        }))
    }

    checkIfNameExists(control: AbstractControl): Observable<ValidationErrors | null> {
        if (this.uptHive && control.value === this.initialName) {
            return of(null);
        }
        return this.hiveAPIService.checkIfNameExists(control.value).pipe(
            map((response: { exists: boolean }) => {
                return response.exists ? { nameExists: true } : null;
            })
        )
    }

    setSubCategories(e: any) {
        const cid = e.target.value
        for (let c of this.categories) {
            if (c.id == cid) {
                this.subCategories = c.sub_categories
            }
        }
    }

    setTags(e: any) {
        const cid = e.target.value
        for (let c of this.subCategories) {
            if (c.id == cid) {
                this.tags = c.tags
                this.tagNames = this.tags.map(tag => tag.name)
            }
        }
    }

    onTagChange(tags: string[]) {
        this.hiveForm.get('tags')?.setValue(tags);
    }

	setInitCategories() {
		this.categories.forEach((cat) => {
			if (cat.id == this.uptHive?.category.id) {
				this.subCategories = cat.sub_categories
			}
		})
		this.subCategories.forEach((cat) => {
			if (cat.id == this.uptHive?.category.sub_categories[0].id) {
				this.tags = cat.tags
				this.tagNames = this.tags.map(tag => tag.name)
			}
		})
	} 
}
