import { Component, Input } from '@angular/core';

@Component({
  selector: 'node-tile',
  templateUrl: './node-tile.component.html',
  styleUrl: './node-tile.component.scss'
})
export class NodeTileComponent {
    @Input() link: string | undefined
    @Input() queryParams: {} | undefined
    @Input() image: string | undefined
    @Input() defaultImage: string | undefined
    @Input() primaryTxt!: string
    @Input() secondaryTxt: string | null = null
    
    
}
