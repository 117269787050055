<div class="alert-backdrop" *ngIf="isVisible">
    <div class="alert-dialog">
        <h3>{{ title }}</h3>
        <p>{{ message }}</p>
        <div class="alert-buttons">
            <button *ngFor="let button of buttons; let i = index"
                [ngClass]="{'btn-primary': i == 0, 'btn-secondary': i == 1,'btn': true}"
                (click)="button.action()">{{ button.name }}
            </button>
        </div>
    </div>
</div>