import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnChanges, OnDestroy, Renderer2, SimpleChanges, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/_services';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnDestroy, OnChanges, AfterViewInit, AfterViewChecked {
    private subscriptions: Subscription = new Subscription()
    
    title: string = ''
    isVisible = false
    content: TemplateRef<any> | null = null
    context: any = {}
    backBtnActivated: boolean = false
    footerBtnActivated: boolean = false
    showLoader: boolean = false
    footerBtnTxt: string = ''
    htmlBody: any
    errorMessage: string = ''
    
    requestSuccess: boolean = false
    requestFailure: boolean = false
    
    
    constructor(
        private el: ElementRef, 
        private renderer: Renderer2,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef,
    ) {
        this.subscriptions.add(
            this.modalService.doLoading.subscribe( action => {
                this.setShowLoader(action)
            })
        )
        
        this.subscriptions.add(
            this.modalService.doSuccess.subscribe( success => {
                this.setRequestSuccess()
            })
        )
    }
    
    @HostListener('window:resize')
    onWindowResize(): void {
        this.adjustModalHeight()
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['isVisible'] && changes['isVisible'].currentValue) {
            this.adjustModalHeight()
        }
    }
    
    ngAfterViewInit(): void {
        this.adjustModalHeight()
        this.htmlBody = this.el.nativeElement.ownerDocument.body
    }
    
    ngAfterViewChecked(): void {
        if(this.isVisible) {
            this.adjustModalHeight()
        }
    }

    private adjustModalHeight(): void {
        const vh = window.innerHeight * 0.01
        let modal = this.el.nativeElement.querySelector('.modal')
        
        if(modal) {
            modal.style.setProperty('--vh', `${vh}px`)    
        }
    }
  
    open(title: string, content: TemplateRef<any>, context: any): void {
        this.content = content
        this.context = context
        this.title = title
        this.isVisible = true
        this.cdr.detectChanges()
        
        this.makeBodyUnscrollable()
    }
    
    setTitle(title: string): void {
        this.title = title
        this.cdr.detectChanges()
    }
    
    goBack(): void {
        this.modalService.goBack.next(true)
    }
    
    footerAction(): void {
        this.modalService.doFooterAction.next(true)
    }

    close(): void {
        this.resetModal()
        this.makeBodyScrollable()
    }
    
    
    
    setShowLoader(active: boolean): void {
        this.showLoader = active
    }
    
    makeBodyUnscrollable(): void {
        this.renderer.addClass(this.htmlBody, 'no-scroll')
    }
    
    makeBodyScrollable(): void {
        this.renderer.removeClass(this.htmlBody, 'no-scroll')
    }
    
    resetRequestStatus(): void {
        this.requestFailure = false 
        this.requestSuccess = false
        this.errorMessage = ''
    }
    
    setRequestSuccess(): void {
        this.requestSuccess = true
        this.requestFailure = false
    }
    
    setRequestFailure(message: string): void {
        this.requestSuccess = false
        this.requestFailure = true
        this.errorMessage = message
        setTimeout(() => {
            this.requestFailure = false
            this.errorMessage = ""
        }, 3000)
    }
    
    resetModal(): void {
        this.isVisible = false
        this.setShowLoader(false)
        this.backBtnActivated = false
        this.footerBtnActivated = false
        this.title = ""
        this.requestSuccess = false
        this.requestFailure = false
    }
}
