import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Hive, Config, Post, Business, HiveCategory, HubhiveEvent } from '../_models'
import { Observable, ObservedValueOf } from 'rxjs'
import { Socket } from 'ngx-socket-io'

@Injectable({ providedIn: 'root' })
export class HiveAPIService {
    constructor(private http: HttpClient, private socket: Socket) { }
    
    getHiveById(id: string): Observable<Hive> {
        return this.http.get<Hive>(`${Config.apiUrl}/hive/${id}`)
    }
    
    getHiveByHandle(handle: string): Observable<Hive> {
        return this.http.get<Hive>(`${Config.apiUrl}/hive/${handle}`)
    }
    
    createHive(data: FormData): Observable<Hive> {
        return this.http.post<Hive>(`${Config.apiUrl}/hive`, data)
    }
    
    updateHive(data: FormData): Observable<Hive> {
        return this.http.patch<Hive>(`${Config.apiUrl}/hive/${data.get("id")}`, data)
    }
    
    deleteHive(id: string): any {
        return this.http.delete<any>(`${Config.apiUrl}/hive/${id}`)
    }
    
    list(limit?: number, search?: string): Observable<Hive[]> {
        let params: any = {
            limit: limit ? limit : 0
        }
        
        if(search) {
            params['search'] = search
        }
        
        return this.http.get<Hive[]>(`${Config.apiUrl}/hive`, { params: params } )
    }
    
    join(hiveHandle: string): Observable<Hive> {
        return this.http.post<Hive>(`${Config.apiUrl}/hive/join`, { handle: hiveHandle })
    }
    
    leave(hiveHandle: string, userId: string): Observable<Hive> {
        return this.http.delete<Hive>(`${Config.apiUrl}/hive/${hiveHandle}/user/${userId}`)
    }

    invite(hiveHandle: string, invitees: string[]): Observable<Hive> {
        return this.http.post<Hive>(`${Config.apiUrl}/hive/invite`, { handle: hiveHandle, invite: invitees })
    }

    getUserHives(id?: string): Observable<Hive[]> {
        let params: any
        
        if(id) {
            params['id'] = id
        }
    
        return this.http.get<Hive[]>(`${Config.apiUrl}/hive/user`, { params: params } )
    }
    
    isUserInHive(hiveId: string): Observable<{ in_hive: boolean }> {
        return this.http.get<{ in_hive: boolean }>(`${Config.apiUrl}/hive/` + hiveId + `/user`)
    }
    
    getHivesUserNotIn(limit?: number, userId?: string): Observable<Hive[]> {
        let params: any = {}
        
        if(userId) {
            params['userId'] = userId
        }
        
        if(limit) {
            params['limit'] = limit
        }
        
        return this.http.get<Hive[]>(`${Config.apiUrl}/hive/not-in`, { params: params } )
    }
    
    getUserHivesNotInEvent(eventId: string, userId?: string): Observable<Hive[]> {
        let params: any = {
            "event_id": eventId,
            "user_id": userId
        }
        
        return this.http.get<Hive[]>(`${Config.apiUrl}/hive/event/not-in`, { params: params })
    }

    getUserHivesNotInBusiness(businessId: string): Observable<Hive[]> {
        let params: any = {
            "business_id": businessId,
        }       
        return this.http.get<Hive[]>(`${Config.apiUrl}/hive/business/not-in`, { params: params })
    }

    checkIfNameExists(name: string): Observable<{ exists: boolean }> {
        return this.http.get<{ exists: boolean }>(`${Config.apiUrl}/hive/exists`, { params: { name } })
    }
    
    attachBusinesses(hiveHandle: string, businessIds: string[]): Observable<Business[]> {
        return this.http.post<Business[]>(`${Config.apiUrl}/hive/${hiveHandle}/business`, { businessIds: businessIds })
    }

    attachEvents(hiveHandle: string, eventIds: string[]): Observable<HubhiveEvent[]> {
        return this.http.post<HubhiveEvent[]>(`${Config.apiUrl}/hive/${hiveHandle}/event`, { eventIds: eventIds })
    }
    
    getMyHives(): Observable<Hive[]> {
        return this.http.get<Hive[]>(`${Config.apiUrl}/hive/my-hives`)
    }
    
    getHiveOwner(id: string): Observable<Hive> {
        return this.http.get<Hive>(`${Config.apiUrl}/hive/${id}`)
    }

    getHiveCategories(): Observable<HiveCategory[]> {
        return this.http.get<HiveCategory[]>(`${Config.apiUrl}/hive/categories`)
    }
    
    updateImage(id: string, data: {}): Observable<Hive> {
        return this.http.patch<Hive>(`${Config.apiUrl}/hive/${id}/image`, data)
    }
}