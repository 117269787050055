<div class="slider-container"
    (mousedown)="startDrag($event)" 
    (mousemove)="onDrag($event)" 
    (mouseup)="endDrag($event)"
    (mouseleave)="endDrag($event)"
    (touchstart)="startDrag($event)" 
    (touchmove)="onDrag($event)" 
    (touchend)="endDrag($event)">
    
    <div class="slider">
        <div class="slide" 
            *ngFor="let slide of slides; let i = index" 
            [class.active]="i === activeSlideIndex" 
            [class.solo]="slides.length == 1"
            [class.clickable]="slide.callback != null"
            [ngStyle]="{ transform: 'translateX(-' + activeSlideIndex * 100 + '%)' }"
            (click)="slide?.callback()"
            >
            
            <div class="slide-content">
                <div class="slide-image">
                    <img [src]="slide.src" [srcset]="slide.srcset" [alt]="slide.title">
                </div>
                <div class="slide-text">
                    <h4>{{ slide.title }}</h4>
                    <p>{{ slide.caption }}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Slider Dots -->
    <div class="slider-dots" *ngIf="slides.length > 1">
        <span
            *ngFor="let slide of slides; let i = index"
            [class.active]="i === activeSlideIndex"
            (click)="goToSlide(i)"
        ></span>
    </div>
</div>
  