export enum NotificationType {
    BusinessClaim = "business_claim",
	BusinessClaimApproved = "business_claim_approved",
	EventInvite = "event_invite",
	HiveInvite = "hive_invite",
    JoinHive = "join_hive",
	EventRSVP = "event_rsvp",
	PostComment = "post_comment",
	PostLike = "post_like",
    CommentLike = "comment_like",
    ReplyLike = "reply_like",
	PostEntity = "post_entity",
	PostMention = "post_mention",
    CommentMention = "comment_mention",
    ReplyMention = "reply_mention",
	PostHive = "post_hive",
	PostBusiness = "post_business",
	PostEvent = "post_event",
	PostReply = "post_reply",
    
}