import { Injectable } from '@angular/core'
import { Business, Entity, Hive, Post, Product } from '../_models';

@Injectable({ providedIn: 'root' })
export class EntityHelper {
    
    toEntity(item: Business | Hive | Product | Post): Entity {
        //Have to use else ifs to satisfy compiler
        if('phone' in item) { //Business
            return {
                id: item.id,
                name: item.name,
                handle: item.handle,
                type: 'Business',
                image: item.logo || '',
                avatar_color: '',
            }
        } else if('members' in item) { //Hive
            return {
                id: item.id,
                name: item.name,
                handle: '',
                type: 'Hive',
                image: item.cover_image,
                avatar_color: '',
            }
        } else if('brand' in item) { // Product
            return {
                id: item.id,
                name: item.name,
                handle: '',
                type: 'Product',
                image: item.images[0],
                avatar_color: '',
            }
        }  else {
            return {
                id: item.id,
                name: item.text.toString(),
                handle: '',
                type: 'Post',
                image: item.images[0],
                avatar_color: '',
            }
        }
    }
}