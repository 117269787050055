import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Business, Hive } from 'src/app/_models';
import { BusinessService } from 'src/app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.scss']
})
export class BusinessDetailsComponent {
    @Input() hiveHandle: string | null = null
    
    
    private subscriptions: Subscription = new Subscription()

    
    business: Business | null = null
    businessHives: Hive[] | undefined
    owner: boolean = false
    
    constructor(
        private titleService: Title,
        private businessService: BusinessService,
    ) {    
        this.subscriptions.add(
            this.businessService.currentBusiness.subscribe( business => {
                if(!business) {
                    //do 404 redirect
                    return
                }
                
                this.business = business
                //I'm setting this separate because on the server we need to differentiate businessWithOwnership with Business structs
                this.businessHives = business.hives
                this.titleService.setTitle(this.business.name + ' Details | Hub Hive')
            })
        )
        this.subscriptions.add(
            this.businessService.userIsOwner.subscribe(isOwner => {
              this.owner = isOwner
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    showInfoForm(): void {
        this.businessService.showInfoForm.next(true)      
    }
}
