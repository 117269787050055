import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HubhiveEvent } from 'src/app/_models';
import { ModalService } from 'src/app/_services';
import { EventCreateComponent } from '../event-create/event-create.component';
import { EventService } from 'src/app/_services/event.service';
import { EventAPIService } from 'src/app/_api-services';
import { AccountNavigationService } from 'src/app/_services/account-navigation.service';

@Component({
  selector: 'event-create-container',
  templateUrl: './event-create-container.component.html',
  styleUrl: './event-create-container.component.scss'
})
export class EventCreateContainerComponent implements OnChanges, OnDestroy {
    @Input() step = 1
    @Input() event: HubhiveEvent | null = null
    @Input() isUpdate: boolean = false
    @Output() changeModalTitle: EventEmitter<string> = new EventEmitter<string>()
    @Output() setMembersDone: EventEmitter<void> = new EventEmitter<void>()
    @ViewChild('eventCreate') eventCreate!: EventCreateComponent;
     
    
    private subscriptions: Subscription = new Subscription()
    
    modalTitle: string = ''
    formDataEvent: FormData | null = null
    members: string[] = []
    hives: string[] = []  
    
    constructor(
        private modalService: ModalService,
        private eventAPIService: EventAPIService,
        private eventService: EventService,
        private router: Router,
        private accountNavigationService: AccountNavigationService,
    ) {
        this.subscriptions.add(
            this.modalService.goBack.subscribe( goBack => {
                this.setStep(this.step - 1)
            })
        )
    }

    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['step']) {
            this.step = changes['step'].currentValue
            this.setStep(this.step)
        }
    }
    
    setStep(step: number): void {
        this.step = step
        
        switch(step) {
            case 2:
                this.modalTitle = 'Invite Members'
            break;
            
            case 3:
                this.modalTitle = 'Share to Hives'
            break;
            
            default:
                this.modalTitle = 'Create Event'
        }
        
        this.modalService.updateTitle(this.modalTitle)
    }
    
    setEvent(event: HubhiveEvent): void {
        this.event = event
    }
    
    createEvent(event: FormData): void {
        this.modalService.doLoading.next(true)

        if(this.eventCreate.isUpdate){
            this.eventAPIService.update(event).subscribe({
                next: e => {
                    this.modalService.doLoading.next(false)
                    this.event = e
                    this.eventService.currentEvent.next(this.event)
                    this.accountNavigationService.updateEvent(e)
                    this.setStep(2)
                },
                error: err => {
                    this.modalService.doLoading.next(false)
                }
            })
            
            return
        }
        
        this.eventAPIService.create(event).subscribe({
            next: e => {
                this.modalService.doLoading.next(false)
                this.event = e
                this.router.navigate(['event', e.name], { queryParams: { id: e.id } } )
                this.accountNavigationService.addEvent(e)
                this.setStep(2)
            },
            error: err => {
                this.modalService.doLoading.next(false)
            }
        })
    }
    
    setMembers(memberIds: string[]): void {
        if(!memberIds.length) {
            this.setMembersDone.emit()
            this.setStep(3)
            return
        }
        
        this.modalService.doLoading.next(true)
        this.members = memberIds
        
        if (this.event) {
            this.eventAPIService.inviteToEvent(this.event.id, memberIds).subscribe({
                next: e => {
                    this.modalService.updateFooterBtnTxt('Invites Sent!')
                    this.modalService.setRequestSuccess()
                    this.modalService.doLoading.next(false)

                    let to = setTimeout(() => {
                        this.setMembersDone.emit()
                        this.modalService.setRequestReset()
                        this.setStep(3)
                        clearTimeout(to)
                    }, 2000)
                },
                error: err => {
                    this.modalService.doLoading.next(false)
                    this.modalService.setRequestFailure(err.message)
                }
            })

        }
    }
    
    setHives(hiveIds: string[]): void {
        if(!this.event) {
            return    
        }
        
        this.hives = hiveIds
        this.modalService.doLoading.next(true)
        
        this.subscriptions.add(
            this.eventAPIService.postEventToHive(this.event.id, hiveIds).subscribe({
                next: () => {
                    this.eventService.refreshHives.next()
                    this.modalService.close()
                    this.modalService.doLoading.next(false)
                },
                error: err => {
                    this.modalService.doLoading.next(false)
                    this.modalService.setRequestFailure(err.error)
                }
            })    
        )
        
        
    }
}
