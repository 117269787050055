import { Component, EventEmitter, Output } from '@angular/core';
import { ModalService } from 'src/app/_services';

@Component({
  selector: 'product-create-business',
  templateUrl: './product-create-business.component.html',
  styleUrl: './product-create-business.component.scss'
})
export class ProductCreateBusinessComponent {
    @Output() createBusiness: EventEmitter<null> = new EventEmitter<null>()
    
    constructor(private modalService: ModalService) {}
    
    emitCreateBusiness(): void {
        this.createBusiness.emit()
    }
    
    closeModal(): void {
        this.modalService.close()
    }
}
