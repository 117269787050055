import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Business, Hive, HubhiveEvent } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class AccountNavigationService {
    myBusinesses: BehaviorSubject<Business[]|null> = new BehaviorSubject<Business[]|null>(null)
    myHives: BehaviorSubject<Hive[]|null> = new BehaviorSubject<Hive[]|null>(null)
    myBusinessClaims: BehaviorSubject<Business[]|null> = new BehaviorSubject<Business[]|null>(null)
    myEvents: BehaviorSubject<HubhiveEvent[]|null> = new BehaviorSubject<HubhiveEvent[]|null>(null)
    
    constructor() { }
    
    private spliceArray(array: Business[] | Hive[] | HubhiveEvent[], key: string): Business[] | Hive[] | HubhiveEvent[] {
        for(var i = 0; i < array.length; i++) {
            if(array[i].id == key) {
                array.splice(i, 1)
                break
            }
        }
        
        return array
    }
    
    private updateArray(array: Business[] | Hive[] | HubhiveEvent[], key: Business | Hive | HubhiveEvent ): Business[] | Hive[] | HubhiveEvent[] {
        for(var i = 0; i < array.length; i++) {
            if(array[i].id == key.id) {
                array[i] = key
                break
            }
        }
        
        return array
    }
    
    addBusinessClaim(business: Business | null): void {
        if(!business) {
            return
        }
        
        let currentClaims = this.myBusinessClaims.getValue()
        
        if(!currentClaims) {
            currentClaims = []
        }
        
        currentClaims.push(business)
        this.myBusinessClaims.next(currentClaims)
    }
    
    addMyBusiness(business: Business): void {
        let currentBusinesses = this.myBusinesses.getValue()
        
        if(!currentBusinesses) {
            currentBusinesses = []
        }
        
        currentBusinesses.push(business)
        this.myBusinesses.next(currentBusinesses)
    }
    
    updateMyBusinesses(business: Business): void {
        if(!business) {
            return
        }
        
        let currentBusinesses = this.myBusinesses.getValue()
        
        if(!currentBusinesses) {
            return
        }
        
        currentBusinesses = this.updateArray(currentBusinesses, business) as Business[]
        this.myBusinesses.next(currentBusinesses)
    }
    
    removeMyClaims(businessId: string): void {
        if(!businessId) {
            return
        }
        
        let currentClaims = this.myBusinessClaims.getValue()
        
        if(!currentClaims) {
            return
        }
        
        currentClaims = this.spliceArray(currentClaims, businessId) as Business[]
        this.myBusinessClaims.next(currentClaims)
    }
    
    addEvent(event: HubhiveEvent): void {
        if(!event) {
            return
        }
        
        let currentEvents = this.myEvents.getValue()
        
        if(!currentEvents) {
            currentEvents = []
        }
        
        currentEvents.push(event)
        this.myEvents.next(currentEvents)
    }
    
    removeEvent(eventId: string): void {
        if(!eventId) {
            return
        }
        
        let currentEvents = this.myEvents.getValue()
        
        if(!currentEvents) {
            return
        }
        
        currentEvents = this.spliceArray(currentEvents, eventId) as HubhiveEvent[]
        this.myEvents.next(currentEvents)
    }
    
    updateEvent(event: HubhiveEvent): void {
        if(!event) {
            return
        }
        
        let currentEvents = this.myEvents.getValue()
        
        if(!currentEvents) {
            return
        }
        
        currentEvents = this.updateArray(currentEvents, event) as HubhiveEvent[]
        this.myEvents.next(currentEvents)
    }
    
    addMyHive(hive: Hive): void {
        if(!hive) {
            return
        }
        
        let currentHives = this.myHives.getValue()
        
        if(!currentHives) {
            currentHives = []
        }
        
        currentHives.push(hive)
        this.myHives.next(currentHives)
    }
    
    updateHives(hive: Hive): void {
        if(!hive) {
            return
        }
        
        let currentHives = this.myHives.getValue()
        
        if(!currentHives) {
            return
        }
        
        currentHives = this.updateArray(currentHives, hive) as Hive[]
        this.myHives.next(currentHives)
    }
}
