import { Component, Input } from '@angular/core';

@Component({
  selector: 'unregistered-user-card',
  templateUrl: './unregistered-user-card.component.html',
  styleUrl: './unregistered-user-card.component.scss'
})
export class UnregisteredUserCardComponent {
    @Input() email: string = ''

    constructor() { }
    
}


