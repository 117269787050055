<!-- <div class="trust-score" *ngIf="context == 'normal'">
    <div class="score-container" (click)="toggleScoreExplanation($event)">
        <img src="/assets/icon-hive.svg" alt="hive icon">
        <div>
            <div class="relative">
                <p class="score" *ngIf="trustScore">{{ trustScore }}%</p>
                <p class="score" *ngIf="!trustScore">--%</p>
            </div>                
            <p class="score-text">Hive Score</p>
        </div>
    </div>
    <div class="star-container">
        <div class="star-rating">
            <div class="stars-outer">
                <div class="stars-inner" [ngStyle]="{width: starWidth }"></div>
            </div>
        </div>
        <p class="total-reviews" *ngIf="totalReviews">{{ totalReviews | number }} User Reviews</p>
        <p class="total-reviews" *ngIf="!totalReviews">---- No User Reviews</p>
    </div>
</div> -->

<div class="flex align-center" *ngIf="context == 'lightweight'">
    <div class="image-container logo">
        <img *ngIf="logo" src="{{ logo }}" alt="{{ companyName }}">
        <img *ngIf="!logo" src="/assets/business/default-logo.png" alt="{{ companyName }}">
    </div>
    <div class="trust-score lightweight">
        <div class="flex space-between">
            <h2 *ngIf="companyName">{{ companyName }} </h2>
            <!-- <div class="flex flex-center percentage" (click)="toggleScoreExplanation($event)">
                <img src="/assets/icon-hive.svg" alt="hive icon">
                
                <div class="relative">
                    <span class="score" *ngIf="trustScore">{{ trustScore }}%</span>
                    <span class="score" *ngIf="!trustScore">--%</span>
                </div>
            </div> -->
        </div>
        
        <!-- <div class="star-container">
            <div class="star-rating">
                <div class="stars-outer">
                    <div class="stars-inner" [ngStyle]="{width: starWidth }"></div>
                </div>
            </div>
            <p class="total-reviews" *ngIf="totalReviews">{{ totalReviews | number }}</p>
            <p class="total-reviews" *ngIf="!totalReviews">----</p>
        </div> -->
    </div>
</div>

<div class="modal-background" [ngClass]="{'active': showScoreExplanation}" (click)="closeModal($event)">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <h2>What is a hive trust score?</h2>
        <p>
            The hive trust score is our little formula that measures how likely you are to 
            interact with a business. We measure this score on a number of things including the 
            number of reviews it has, the average review, and the negative or positive tone of 
            comments mentioned about this company.
        </p>

        <p>
            Our goal is to continually improve this algorithm to be the most trustworthy scoring 
            based on your communities and your needs.
        </p>
    </div>
</div>
