import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { HubhiveEvent, Config } from '../_models'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class EventAPIService {
    constructor(private http: HttpClient) { }
    
    create(data: FormData): Observable<HubhiveEvent> {
        return this.http.post<HubhiveEvent>(`${Config.apiUrl}/event`, data)
    }
        
    update(event: FormData): Observable<HubhiveEvent> {
        return this.http.patch<HubhiveEvent>(`${Config.apiUrl}/event`, event)
    }  
    delete(eventId: string): Observable<any> {
        return this.http.delete<string>(`${Config.apiUrl}/event/${eventId}`)
    }
    
    inviteToEvent(eventId: string, userIds: string[]): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/event/invite`, {eventId: eventId, userIds: userIds})
    }

    postEventToHive(eventId: string, hiveIds: string[]): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/event/hive/post`, {eventId: eventId, hiveIds: hiveIds})
    }
    
    removeFromHive(eventId: string, hiveId: string): Observable<HubhiveEvent> {
        return this.http.delete<HubhiveEvent>(`${Config.apiUrl}/event/${eventId}/hive/${hiveId}`)
    }

    getEvent(id: string): Observable<HubhiveEvent> {
        return this.http.get<HubhiveEvent>(`${Config.apiUrl}/event/${id}`)    
    }

    addEvent(event: HubhiveEvent, handle: string): Observable<HubhiveEvent> {
        return this.http.post<HubhiveEvent>(`${Config.apiUrl}/event`, { event: event, hive_handle: handle })
    }

    
    list(limit: number = 0): Observable<HubhiveEvent[]> {
        return this.http.get<HubhiveEvent[]>(`${Config.apiUrl}/event`, { params: { limit: limit } })
    }
    
    getHiveEvents(id: string): Observable<HubhiveEvent[]> {
        return this.http.get<HubhiveEvent[]>(`${Config.apiUrl}/event/hive/${id}`)
    }
    
    getBusinessEvents(id: string): Observable<HubhiveEvent[]> {
        return this.http.get<HubhiveEvent[]>(`${Config.apiUrl}/event/business/${id}`)
    }
    
    userIsAttendingEvent(eventId: string, userId: string | null = null): Observable<HubhiveEvent> {
        let params: any = {}
        
        
        // Leaves option open to pass a specifc user or user current logged in user
        if(userId) {
            params['userId'] = userId
        }
        
        return this.http.get<HubhiveEvent>(`${Config.apiUrl}/event/` + eventId + `/user`, { params: params })
    }
    
    rsvp(eventId: string, userId: string | null = null): Observable<HubhiveEvent> {
        let params: any = {}
        
        if(userId) {
            params['userId'] = userId
        }
        
        return this.http.post<HubhiveEvent>(`${Config.apiUrl}/event/` + eventId + `/user`, { params: params })
    }
    
    withdrawRSVP(eventId: string, userId: string | null = null): Observable<HubhiveEvent> {
        let params: any = {}
        
        if(userId) {
            params['userId'] = userId
        }
        
        return this.http.delete<HubhiveEvent>(`${Config.apiUrl}/event/` + eventId + `/user`, { params: params })
    }

    getMyEvents(): Observable<HubhiveEvent[]> {
        return this.http.get<HubhiveEvent[]>(`${Config.apiUrl}/event/my-events`)
    }
    
    getMyEventsNotIn(hive: string): Observable<HubhiveEvent[]> {
        return this.http.get<HubhiveEvent[]>(`${Config.apiUrl}/event/my-events/not-in/hive/${hive}`)
    }
}