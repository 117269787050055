<div>
    
    <input type="file" #choosePhoto accept="image/png, image/jpeg, image/jpg, image/webp" (change)="setPreview($event)">
    
    <div *ngIf="editorType == 'circle'" class="image" [style.background-image]="'url('+tempURL+')'" (click)="selectFile()">
        <img *ngIf="!tempURL" src="/assets/icon-camera.svg" alt="camera icon">
    </div>
    
    <div *ngIf="editorType == 'rect'" class="image rect" [style.background-image]="'url('+tempURL+')'" (click)="selectFile()">
        <img *ngIf="!tempURL" src="/assets/icon-camera.svg" alt="camera icon">
    </div>

</div>

<ul>
    <li (click)="selectFile()">Choose Photo</li>
    <li *ngIf="tempURL" (click)="repositionClicked()">Reposition</li>
</ul>

<image-editor *ngIf="repositioning" [imageUrl]="baseTempURL" [cropType]="editorType" (canceled)="cancelRepositioning()" (applied)="applyCrop($event)"></image-editor>