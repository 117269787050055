<div class="preview-container">
    <div class="preview-controls flex space-between">
        <div (click)="cancel()">Cancel</div>
        <div (click)="apply()">Apply</div>
    </div>
    <div class="zoom-controls">
        <button (click)="zoomIn()" (tap)="zoomIn()">+</button>
        <button (click)="zoomOut()" (tap)="zoomOut()">-</button>
    </div>
    <canvas #canvas></canvas>
</div>