import { Component } from '@angular/core';

@Component({
  selector: 'the-end',
  templateUrl: './the-end.component.html',
  styleUrl: './the-end.component.scss'
})
export class TheEndComponent {

}
