<form [formGroup]="productForm" class="modal-form">
    <div class="field-group">
        <user-owned-entities-dropdown [form]="productForm" [type]="'product'"></user-owned-entities-dropdown>    
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <entity-search 
                [label]="'Product Name'" 
                [group]="productForm" 
                [name]="'name'"
                [filterType]="filterType"
                (itemSelected)="selectDropdownItem($event)">
            </entity-search>
            <div *ngIf="name?.errors && name?.touched" class="error">Product Name is required</div>
        </div>
    </div>
    
    <div class="field-group">
        <div class="image-upload-container" formArrayName="images">
            <div *ngFor="let image of images.controls; let i = index" [formGroupName]="i" class="image-group">
                <file-dropzone 
                    [label]="'Add Product Image'" 
                    [inputName]="'image'" 
                    [form]="getGroup(i)" 
                    [maxFileSize]="1"
                    (doFileOutput)="appendImage($event, i)">
                </file-dropzone>
                <div *ngIf="i !== 0 && productImages[i]" class="btn btn-primary" (click)="removeImage(i)" class="remove-image">
                    <img src="/assets/icon-close.svg" alt="close icon">
                </div>
            </div>
        </div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <textarea formControlName="description" hasContent></textarea>
            <label>Description</label>
        </div>
        <div *ngIf="description?.errors && description?.touched" class="error">Description is required</div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <input priceMask type="text" formControlName="price" hasContent>
            <label>Price</label>
        </div>
        
        <div *ngIf="price?.errors && price?.touched" class="error">Enter a valid price (0.00)</div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <input type="text" formControlName="home_link" hasContent>
            <label>Link to product</label>
        </div>
        
        <div *ngIf="homeLink?.errors && homeLink?.touched" class="error">Invalid URL</div>
    </div>
    
    <div class="field-group fancy-shmancy" (click)="revealSelector()">
        <div>
            <div class="select" [ngClass]="{'has-content': category.name}">
                {{category.name}}
            </div>
            <label>Category</label>
        </div>
    </div>

    <product-category-selector *ngIf="showSelector" (closeSelector)="closeSelector()" (selectedCategory)="setCategory($event)"></product-category-selector>
    
    <div>
        <p class="attribute-toggle" (click)="toggleAttributes()">Want to add some pizaz? Click me <span>&darr;</span></p>
        <div class="attributes" [ngClass]="{'open': showAttributes }">
            <div class="field-group fancy-shmancy">
                <input type="text" formControlName="color" hasContent>
                <label>Color</label>
            </div>
            <div class="field-group fancy-shmancy">
                <input type="number" formControlName="width" hasContent>
                <label>Width (in.)</label>
            </div>
            <div class="field-group fancy-shmancy">
                <input type="number" formControlName="height" hasContent>
                <label>Height (in.)</label>
            </div>
            <div class="field-group fancy-shmancy">
                <input type="number" formControlName="weight" hasContent>
                <label>Weight (lbs)</label>
            </div>
            <div class="field-group fancy-shmancy">
                <input type="text" formControlName="material" hasContent>
                <label>Material</label>
            </div>
            <div class="field-group fancy-shmancy">
                <input type="text" formControlName="sku" hasContent>
                <label>SKU</label>
            </div>
        </div>
    </div>
</form>