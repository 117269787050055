import { Component, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core'
import { ZXingScannerComponent } from '@zxing/ngx-scanner'
import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/library'
import { ProductAPIService } from 'src/app/_api-services/product-api.service'
import { Subscription } from 'rxjs'
import { Product } from 'src/app/_models'
import { ModalService } from 'src/app/_services'

@Component({
	selector: 'product-scanner',
	templateUrl: './product-scanner.component.html',
	styleUrl: './product-scanner.component.scss'
})
export class ProductScannerComponent implements OnInit {
	@Output('results') results: EventEmitter<Product> = new EventEmitter<Product>()

	availableDevices: MediaDeviceInfo[] = []
	selectedDevice: MediaDeviceInfo | undefined
	barcodeResult: string = ''
	loading: boolean = false
	product: Product | null = null
	showProductDetails: boolean = true

	allowedFormats = [
		BarcodeFormat.AZTEC,
		BarcodeFormat.CODABAR,
		BarcodeFormat.CODE_128,
		BarcodeFormat.CODE_39,
		BarcodeFormat.CODE_93,
		BarcodeFormat.DATA_MATRIX,
		BarcodeFormat.EAN_13,
		BarcodeFormat.EAN_8,
		BarcodeFormat.ITF,
		BarcodeFormat.PDF_417,
		BarcodeFormat.RSS_14,
		BarcodeFormat.RSS_EXPANDED,
		BarcodeFormat.UPC_A,
		BarcodeFormat.UPC_E,
		BarcodeFormat.UPC_EAN_EXTENSION,
	]
	private subscriptions: Subscription = new Subscription()
	
  
	constructor(
		private productAPIService: ProductAPIService,
		private modalService: ModalService,
	) {}
  
	ngOnInit(): void {
		const codeReader = new BrowserMultiFormatReader()
		codeReader
			.listVideoInputDevices()
				.then((devices) => {
					this.availableDevices = devices
					if (devices.length > 0) {
						this.selectedDevice = devices[0]
					}
				})
			.catch((err) => console.error(err))
	}
  
	onCodeResult(barcode: string) {
		this.loading = true
		this.barcodeResult = barcode
		this.subscriptions.add(this.productAPIService.getProductByBarcode(barcode).subscribe({
			next: (product: Product) => {
				this.product = product
				this.results.emit(this.product)
				this.loading = false
			},	
			error: (error: any) => {

			}
		}))
	}
		
	onDeviceSelectChange(event: Event) {
		const target = event.target as HTMLSelectElement
		const selectedValue = target?.value ?? ''
		const device = this.availableDevices.find(device => device.deviceId === selectedValue)
		this.selectedDevice = device
	}

	close() {
		this.modalService.close()
	}

  }