<div class="icon-links">
    <div class="icon-link" (click)="createProduct(addProductModal)">
        <img src="/assets/icon-products-purple.svg" alt="Products">
        <div>Add Products</div>
    </div>
    <div class="icon-link" (click)="handleShare()">
        <img src="/assets/icon-people-purple.svg" alt="Hives">
        <div>Share with friends</div>
    </div>
    <div class="icon-link" (click)="handleAddToHives(addHiveModal)">
        <img src="/assets/icon-hives-purple.svg" alt="Hives">
        <div>Add to Hive</div>
    </div>
    <div class="icon-link" (click)="createEvent(addEventModal)">
        <img src="/assets/icon-calendar-purple.svg" alt="Events">
        <div>Add Events</div>
    </div>
</div>
<div>
    <div *ngIf="clipboardHelper.copiedMessageVisable" class="message">
        {{clipboardHelper.shareMessage}}
    </div>
</div>

<ng-template #addProductModal let-data="data">
    <product-create-container></product-create-container>
</ng-template>
<ng-template #shareFriendsModal let-data="data"></ng-template>
<ng-template #addHiveModal let-data="data">
    <business-add-hives [businessId]="handle"></business-add-hives>
</ng-template>
<ng-template #addEventModal let-data="data">
    <event-create-container></event-create-container>
</ng-template>