import { Injectable } from '@angular/core'

import { GeneralNotification } from '../_models'
import { Socket } from 'ngx-socket-io'
import { AuthenticationService } from './authentication.service'
import { NotificationService } from './notification.service'

@Injectable({ providedIn: 'root' })
export class SocketioService {
    currentUserId = ""
    constructor(
        private socket: Socket,
        private authService: AuthenticationService,
        private notificationService: NotificationService,
    ) { 
        this.currentUserId = this.authService.currentUserValue.user.id

        this.socket.on('connect', () => {
            // this.initListeners()
            this.connect()
        })
        
        this.socket.on('disconnect', (reason: any) => {
            console.warn('Disconnected:', reason)
        })
    }
    
    connect() {
        if(this.currentUserId){
            this.socket.emit("io_connect", this.currentUserId)
        }
    }
    
    initListeners(): void {
        this.socket.on('new_notification', (notification: GeneralNotification) => {
            this.handleNewNotification(notification)
        })
    }

    private handleNewNotification(notification: GeneralNotification) {
        let message = notification.initiator.first_name + " " + notification.initiator.last_name + " " + notification.message
        this.notificationService.showNotification("Hive Updates", {body: message})
    }

}