import { Injectable, OnDestroy } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { Post, Config } from '../_models'
import { Observable, ObservedValueOf, Subscription } from 'rxjs'
import { Socket } from 'ngx-socket-io'
import { PostHelpers } from '../_helpers'
import { AudioService } from '../_services/audio.service'

@Injectable({ providedIn: 'root' })
export class DiscussionAPIService implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    private currentRoomId: string | null = null
    
    constructor(
        private http: HttpClient, 
        private socket: Socket, 
        private postHelper: PostHelpers,
        private audioService: AudioService,
    ) {
        this.socket.on('connect', () => {
            if (this.currentRoomId) {
                this.joinRoom(this.currentRoomId)
            }
        })
        
        this.socket.on('disconnect', () => {
            if (this.currentRoomId) {
                this.leaveRoom(this.currentRoomId)
            }
        })
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    joinRoom(roomId: string): void {
        this.currentRoomId = roomId
        this.socket.emit('join_room', roomId);
    }
    
    leaveRoom(roomId: string): void {
        this.socket.emit('leave_room', roomId)
    }
    
    list(limit: number = 0): Observable<Post[]> {
        return this.http.get<Post[]>(`${Config.apiUrl}/discussion`, { params: { limit: limit } })    
    }
    
    getUserRelevantPosts(limit: number): Observable<Post[]> {
        return this.http.get<Post[]>(`${Config.apiUrl}/discussion/user/relevant`, { params: { limit: limit } })
    }

    GetPostById(postId: string): Observable<Post> {
        return this.http.get<Post>(`${Config.apiUrl}/discussion/${postId}`)
    }
    
    getHivePosts(hiveId: string, page: number, perPage: number): Observable<Post[]> {
        return this.http.get<Post[]>(`${Config.apiUrl}/discussion/hive/${hiveId}/posts`, { params: { page: page, per_page: perPage } })
    }
    
    getEventPosts(eventId: string, page: number, perPage: number): Observable<Post[]> {
        return this.http.get<Post[]>(`${Config.apiUrl}/discussion/event/${eventId}/posts`, { params: { page: page, per_page: perPage } })
    }

    getBusinessPosts(businessId: string, page: number, perPage: number): Observable<Post[]> {
        return this.http.get<Post[]>(`${Config.apiUrl}/discussion/business/${businessId}/posts`, { params: { page: page, per_page: perPage } })
    }

    likePost(entityId: string, type: string, linksTo: Post | null = null): Observable<any> {
        let params: any = { entity_id: entityId, type: type }
        
        if(linksTo) {
            params['links_to'] = linksTo
        }
        
        let res = this.http.post<any>(`${Config.apiUrl}/discussion/post/like`, params)
        return res
    }

    unlikePost(entityId: string): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/discussion/post/unlike`, { entity_id: entityId })
    }
    
    createPost(post: FormData): Observable<Post> {
        return this.http.post<Post>(`${Config.apiUrl}/discussion/post`, post)
    }

    updatePost(post: FormData): Observable<Post> {
        return this.http.post<Post>(`${Config.apiUrl}/discussion/post/update`, post)
    }

    deletePost(postId: string): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/discussion/post/${postId}/delete`, { postid: postId });
    }
    
    listenForPost(roomId: string): Observable<Post> {
        if(roomId){
            this.joinRoom(roomId)
        }
        
        let obs = this.socket.fromEvent<Post>('new_post')
        this.subscriptions.add(
            obs.subscribe( post => {
                if(this.postHelper.hasMonkePhrase(post.text.toString())) {
                    this.audioService.playMonke()
                }
            })
        )
       
        return obs
    }
    
    listenForUpdates(roomId: string): Observable<Post> {
        if(roomId) {
            this.joinRoom(roomId)
        }
        
        let obs = this.socket.fromEvent<Post>('post_updated')
        this.subscriptions.add(
            obs.subscribe( post => {
                if(this.postHelper.hasMonkePhrase(post.text.toString())) {
                    this.audioService.playMonke()
                }
            })
        )
        
        return obs
    }

    createComment(comment: {}, entity: string): Observable<Post> {
        return this.http.post<Post>(`${Config.apiUrl}/discussion/comment`, { entity: entity, ...comment})
    }

    listenForComment(roomId: string): Observable<Post> {
        this.joinRoom(roomId)
        let obs = this.socket.fromEvent<Post>('new_comment')
        this.subscriptions.add(
            obs.subscribe( post => {
                if(this.postHelper.hasMonkePhrase(post.text.toString())) {
                    this.audioService.playMonke()
                }
            })
        )
        
        return obs
    }
}