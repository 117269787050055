<options-dropdown *ngIf="options.length" [options]="options"></options-dropdown>


<div *ngIf="product">
    <a *ngIf="!offering" [routerLink]="['/', 'products', product.name]" [queryParams]="{'id': product.id}">
        <div class="product-image"> 
            <img *ngIf="product.images && product.images.length" src="{{ product.images[0] }}" alt="{{ product.name }}">
            <img *ngIf="!product.images || !product.images.length" src="/assets/listings/default-product-image.jpeg" alt="{{ product.name }}">    
        </div>
        
        <div class="product-info">
            <p class="product-title" [title]="product.name">{{ product.name }}</p>
            <p class="price" [innerHTML]="formattedPrice"></p>
        </div>
    </a>

    <div *ngIf="offering" (click)="returnProduct()" class="a-class">
        <div class="product-image"> 
            <img *ngIf="product.images && product.images.length" src="{{ product.images[0] }}" alt="{{ product.name }}">
            <img *ngIf="!product.images || !product.images.length" src="/assets/listings/default-product-image.jpeg" alt="{{ product.name }}">    
        </div>
        
        <div class="product-info">
            <p class="product-title">{{ product.name }}</p>
            <p class="price" [innerHTML]="formattedPrice"></p>
        </div>
    </div>
</div>

<div *ngIf="productOffer">
    <a [routerLink]="['/', 'products', productOffer.variant.product.name]" [queryParams]="{'id': productOffer.variant.product.id}">
        <div class="product-image"> 
            <img *ngIf="productOffer.variant.product.images && productOffer.variant.product.images.length" src="{{ productOffer.variant.product.images[0] }}" alt="{{ productOffer.variant.product.name }}">
            <img *ngIf="!productOffer.variant.product.images || !productOffer.variant.product.images.length" src="/assets/listings/default-product-image.jpeg" alt="{{ productOffer.variant.product.name }}">    
        </div>
        
        <div class="product-info">
            <p class="product-title" [title]="productOffer.variant.product.name">{{ productOffer.variant.product.name }}</p>
            <p class="price" [innerHTML]="formattedPrice"></p>
        </div>
    </a>
</div>