import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
    private playStop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private notificationAudio = {
        "bee": new Audio('assets/sounds/bumblebee.mp3'),
        "rick": new Audio('assets/sounds/notification_alert.mp3'),
        "friday": new Audio('assets/sounds/friday_soft.mp3')
    }
    private selectedAudio!: HTMLAudioElement 
    private monkeAudio: HTMLAudioElement = new Audio('assets/sounds/monke.mp3')
    private partyAudio: HTMLAudioElement = new Audio('assets/sounds/party.mp3')
    
    constructor() {
        this.selectAudio()
        
        this.selectedAudio.preload = 'auto'
        this.selectedAudio.load()
        
        this.monkeAudio.preload = 'auto'
        this.monkeAudio.load()
    }
    
    selectAudio(): void {
        const today = new Date()
        const isFriday = today.getDay() === 5

        if (isFriday) {
            this.selectedAudio = this.notificationAudio.friday
        } else {
            this.selectedAudio = this.notificationAudio.bee
        }
    }
    
    playNotificationAlert(): void {
        this.selectAudio()
        
        this.selectedAudio.currentTime = 0
        this.selectedAudio.volume = 1
        
        const promise = this.selectedAudio.play()   
        if(promise !== undefined){
            promise.then(() => {
                // Autoplay started
            }).catch(error => {
                console.log('wtf? Interact with the document so I can spam you with rick rolls.')
            })
        }
    }
    
    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    @HostListener('window:scroll', ['$event'])
    playStopAll(): void {
        if(this.playStop.value) {
            return
        }
        
        this.playStop.next(true)
        this.selectedAudio.play()
        this.selectedAudio.pause()
        this.selectedAudio.currentTime = 0
    }
    
    playMonke(): void {
        this.monkeAudio.currentTime = 0
        this.monkeAudio.volume = 1
        const promise = this.monkeAudio.play()   
        if(promise !== undefined){
            promise.then(() => {
                // Autoplay started
            }).catch(error => {
                console.log(error)
                console.log('can\'t play monke :(')
            })
        }
    }
    
    playParty(): void {
        this.partyAudio.currentTime = 0
        this.partyAudio.volume = 1
        const promise = this.partyAudio.play() 
        if(promise !== undefined){
            promise.then(() => {
                // Autoplay started
            }).catch(error => {
                console.log(error)
                console.log('can\'t play party :(')
            })
        }
    }
}
