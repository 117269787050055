import { AfterViewInit,  Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Product, ProductCategory, ProductOffer } from 'src/app/_models';
import { ModalService } from 'src/app/_services';
import { ProductAPIService } from 'src/app/_api-services/product-api.service';
import { CommonValidators } from 'src/app/_validators/common.validators';

@Component({
  selector: 'product-results',
  templateUrl: './product-results.component.html',
  styleUrl: './product-results.component.scss'
})
export class ProductResultsComponent implements OnDestroy, OnInit, AfterViewInit {
    @Input('product') product: Product | null = null
    @Input('offer') offer: ProductOffer | null = null
    
    @Output('offerUpdate') offerUpdate: EventEmitter<ProductOffer> = new EventEmitter<ProductOffer>()

    @ViewChild('priceInput') priceInput!: ElementRef
    @ViewChild('conditionInput') conditionInput!: ElementRef

    private subscriptions: Subscription = new Subscription()

    
    productResultsForm!: FormGroup
    productImages: File[] = []
    showAttributes: boolean = false
    
    categories: ProductCategory[] = []

    filteredResults: { key: string, value: string | number }[] = []
    offerPrice: number | null = null
    
    constructor(
        private fb: FormBuilder,
        private productAPIService: ProductAPIService,
        private modalService: ModalService,
        private router: Router,
    ) {
    }
    
    ngAfterViewInit(): void {
        this.doChangeOnAllInputs()
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    ngOnInit(): void {
        if(!this.product?.variants?.length && !this.offer){
            return
        }
        
        this.offerPrice = null
        
        if(this.product?.variants?.[0]?.offers?.[0]?.price || this.product?.variants?.[0]?.offers?.[0]?.price == 0){
            this.offerPrice = this.product?.variants[0].offers[0].price
        }
        
        if(this.product) {
            this.filteredResults = this.filterResults(this.product)
            
            this.subscriptions.add(
                this.modalService.doFooterAction.subscribe( action => {
                    this.createOffer()
                })
            )
        }
        
        if(this.offer) {
            this.subscriptions.add(
                this.modalService.doFooterAction.subscribe( () => {
                    this.updateOffer()
                })
            )
        }
        
        
        
        this.formSetup()
    }
    
    formSetup(): void {
        this.productResultsForm = this.fb.group({
            entity: new FormControl('', [Validators.required]),
            price: new FormControl(this.offerPrice, [Validators.required, Validators.pattern(/[0-9]+(\\.[0-9][0-9]?)?/)]),
            home_link: new FormControl('', [CommonValidators.isURL]),
            sku: new FormControl(''),
            condition: ['', Validators.required],
        })
        
        
        if(this.offer) {
            this.productResultsForm.patchValue({
                price: this.offer.price,
                home_link: this.offer.home_link,
                sku: this.offer.sku,
                condition: this.offer.condition,
            })
        }
    }
    
    get price() {
        return this.productResultsForm.get('price')
    }
    get homeLink() {
        return this.productResultsForm.get('home_link')
    }
    get condition() {
        return this.productResultsForm.get('condition')
    }
    
    
    getInvalidInputs(): string[] {
        const invalidInputs: string[] = [];
        Object.keys(this.productResultsForm.controls).forEach(key => {
          const control = this.productResultsForm.get(key);
          if (control && control.invalid) {
            invalidInputs.push(key);
          }
        });
        return invalidInputs;
      }
      
    createOffer(): void {
        
        if(!this.productResultsForm.valid) {   
            this.productResultsForm.markAllAsTouched()
            return
        }

        if(!this.product?.variants?.length){
            return
        }
        
        this.modalService.doLoading.next(true)
        
        const formData = new FormData()
        const controls = this.productResultsForm.controls

        const offerJSON = { condition: controls['condition'].value, price: parseFloat(controls['price'].value), sku: controls['sku'].value, home_link: controls['home_link'].value }
        
    
        formData.append('offer', JSON.stringify(offerJSON))
        formData.append('business_id', controls['entity'].value)
        formData.append('variant_id', this.product.variants[0].id)

        
        if(this.productImages && this.productImages.length) {       
            for(let i = 0; i < this.productImages.length; i++) {
                formData.append(`image[${i}]`, this.productImages[i])
            }
        }

        if(!this.product){
            return
        }
        
        this.subscriptions.add(
            this.productAPIService.createOffer(formData).subscribe({
                next: product => {
                    this.modalService.doLoading.next(false)
                    this.router.navigate(['products', product.name], {queryParams: { id: product.id}})
                    this.modalService.close()
                },
                error: err => {
                    console.log(err)
                }
            })    
        )
    }
    
    updateOffer(): void {
        if(!this.offer) {
            return
        }
        
        this.modalService.doLoading.next(true)
        
        const controls = this.productResultsForm.controls
        
        this.offer.condition = controls['condition'].value
        this.offer.price = parseFloat(controls['price'].value)
        this.offer.sku = controls['sku'].value
        this.offer.home_link = controls['home_link'].value
        
        
        this.subscriptions.add(
            this.productAPIService.updateOffer(this.offer).subscribe({
                next: offer => {
                    this.offerUpdate.emit(offer)
                    this.modalService.doLoading.next(false)
                    this.modalService.close()
                },
                error: err => {
                    console.log(err)
                    this.modalService.setRequestFailure(err.error.message)
                    this.modalService.doLoading.next(false)
                }
            })    
        )
    }
    
    filterResults(product: Product): { key: string, value: string | number }[] {
        const results: { key: string, value: string | number }[] = []
        const excludedKeys: string[] = ['id', 'name']

        for (const [key, value] of Object.entries(product)) {
            if (excludedKeys.includes(key)) {
                continue
            }

            if ((typeof value === 'string' || typeof value === 'number') && value) {
                results.push({ key: this.formatKey(key), value })
            }
        }
        
        if (!product.variants[0]) {
            return results
        }

        for (const [key, value] of Object.entries(product.variants[0])) {
            if (excludedKeys.includes(key)) {
                continue
            }

            if ((typeof value === 'string' || typeof value === 'number') && value) {
                results.push({ key: this.formatKey(key), value })
            }
        }

        if (!product.variants[0].attributes) {
            return results
        }

        for (const attribute of product.variants[0].attributes) {
            results.push({ key: this.formatKey(attribute.name), value: attribute.value })
        }
        
        return results
    } 

    formatKey(key: string): string {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())
    }

    doChangeOnAllInputs(): void {
        if(!this.priceInput){
            return
        }
        if(this.offerPrice || this.offerPrice == 0) {
            this.productResultsForm.get('url')?.patchValue(this.product?.variants[0].offers[0].price)
        }
    }
}
