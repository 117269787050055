import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[mention]'
})
export class MentionDirective {
    @Output() mentionDetected: EventEmitter<string> = new EventEmitter<string>()

    constructor(private el: ElementRef) { }
    
    @HostListener('input', ['$event'])
    onInput(event: any): void {
        const text = this.el.nativeElement.value
        const mentionMatch = text.match(/@\w+/g)
        if (!mentionMatch) {
            return
        }
        
        const lastMention = mentionMatch[mentionMatch.length - 1]
        this.mentionDetected.emit(lastMention)
    }
}
