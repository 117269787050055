import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
    transform(value: string, count: number = 1): string {
        return count === 1 ? value : this.simplePluralize(value)
    }
    
    private simplePluralize(word: string): string {
        if (word.endsWith('y')) {
            return word.replace(/y$/, 'ies');
        } else if (word.endsWith('s') || word.endsWith('x') || word.endsWith('z') || word.endsWith('ch') || word.endsWith('sh')) {
            return word + 'es';
        } else {
            return word + 's';
        }
    }
}