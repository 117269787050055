import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Config } from '../_models'
import { FilterType } from '../_enums'
import { Observable } from 'rxjs'
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class FilterAPIService {
	constructor(private http: HttpClient, private router: Router) { }
    getGlobalCategories(params: any): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/global/get-filters`, params)
    }
	getFilteredRecords(params: any, type: FilterType, requestMethod: string = 'get'): Observable<any> {
		let url: any|null = ""

		switch(type) {
			case FilterType.Hive:
			case FilterType.UserHives:
				url = `${Config.apiUrl}/hive/filter`
			break
			
			case FilterType.Business:
            case FilterType.HiveBusinesses:
                url = `${Config.apiUrl}/business/filter`
            break
            
            case FilterType.User:
                url = `${Config.apiUrl}/user/filter`
            break
            
            case FilterType.Product:
            case FilterType.HiveProducts:
                url = `${Config.apiUrl}/product/filter`
            break
            
            case FilterType.BusinessProducts:
                url = `${Config.apiUrl}/product/offer/filter`
            break

			case FilterType.HubhiveEvent:
                url = `${Config.apiUrl}/event/filter`
            break
            
            case FilterType.Global:
                url = `${Config.apiUrl}/global/filter`
		}
        
        if(requestMethod == 'post') {
            return this.http.post(url, params)
        }
        
        return this.http.get(url, { params })
	}
}