<div class="container">
    <div class="tool-bar-container">
        <div class="tool-bar-content node-tile">
            <div class="back" (click)="goBack()">
                <img src="/assets/icon-back-{{backIconColor()}}.svg" alt="icon back">
            </div>
            
            <div class="node-tile-image" *ngIf="entity && entity.image" [ngClass]="{'show': !backgroundNotWhite, 'hide': backgroundNotWhite}">
                <img src="{{entity.image}}" alt="{{ entity.name }}">  
            </div>
            
            <div class="node-tile-info">
                <p *ngIf="name" [ngClass]="{'show': !backgroundNotWhite, 'hide': backgroundNotWhite}" class="flex flex-center">{{name}}</p>
                <p *ngIf="subTitle">{{ subTitle }}</p>    
            </div>
        </div>
        
        <options-dropdown *ngIf="options.length" [options]="options" [ngClass]="{'light': backgroundNotWhite}"></options-dropdown>
    </div>
</div>
