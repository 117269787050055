import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { UserAuth, Config, ForgotPassword, User } from '../_models/'
import { JwtHelperService } from '@auth0/angular-jwt'
import { jwtDecode } from 'jwt-decode'
import { FullStory } from '@fullstory/browser';
import { DetachedRouteHandleService } from './detached-route-handle.service'
import { environment } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private angularUser: UserAuth = {
        user: {
            id: '',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            street: '',
            city: '',
            state: '',
            zip: '',
            birth_date: '',
            gender: '',
            avatar: '',
            avatar_color: '',
        },
        role: 'app',
        token: environment.jwt,
    }
    
    public currentUserSubject: BehaviorSubject<UserAuth>
    public currentUser: Observable<UserAuth>

    constructor(
        private http: HttpClient, 
        public jwtHelper: JwtHelperService,
        private drhs: DetachedRouteHandleService,
    ) {
        let localUser = localStorage.getItem('hubHiveUser')
        let subject = localUser ? JSON.parse(localUser) : this.angularUser
        
        this.currentUserSubject = new BehaviorSubject<UserAuth>(subject)
        
        this.currentUser = this.currentUserSubject.asObservable()
    }

    public get currentUserValue(): UserAuth {
        if(this.isTokenExpired(this.currentUserSubject.value.token)) {
            this.logout()
        }
        
        return this.currentUserSubject.value
    }
    
    isTokenExpired(token: string): boolean {
        return this.jwtHelper.isTokenExpired(token)
    }

    setUser(user: UserAuth): UserAuth {
        if (user && user.token) {
            delete user.user.deleted
            localStorage.setItem('hubHiveUser', JSON.stringify(user))
            
            if (FullStory) {
                FullStory('setIdentity', {
                    uid:  user.id,
                    properties: {
                        displayName: user.user.first_name + ' ' + user.user.last_name,
                        email: user.user.email
                    }
                })
            }
            
            this.currentUserSubject.next(user)
        }

        return user
    }
    
    updateUserData(user: User): void {
        const hubHiveUser = localStorage.getItem('hubHiveUser')
        
        if(!hubHiveUser) {
            return
        }
        
        let userAuth = JSON.parse(hubHiveUser)
        
        userAuth.user = user
        
        this.setUser(userAuth)
    }
    
    isLoggedIn(): boolean {
        if(this.currentUserSubject.value.role == 'Angular') {
            return false
        }
        
        if(this.currentUserSubject.value.user.id) {
            return true
        }
        
        return false
    }
    
    getUserRole(): string | null {
        const token = this.currentUserSubject.value.token;
        
        if (token) {
          const decodedToken: any = jwtDecode(token)
          return decodedToken.role
        }
        
        return null
    }
    
    isRegistrationComplete(): boolean {
        const user = this.currentUserSubject.value.user
        return !!(user.first_name && user.last_name && user.email && user.phone)
    }

    register(data: any, hiveId: string | null = null, referral: string = ''): Observable<UserAuth> {
        if(hiveId) {
            data.hive_id = hiveId
        }
        
        
        data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        data.referral = referral
        
        return this.http.post<UserAuth>(`${Config.apiUrl}/user/register`, data)
            .pipe(map(user => {
                return this.setUser(user)
            }))
    }

    login(phone: string, code: string): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/user/login`, { 
            phone: phone, 
            code: code, 
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }).pipe(map(user => {
            return this.setUser(user)
        }))
    }

    logout(): void {
        localStorage.removeItem('hubHiveUser')
        this.currentUserSubject.next(this.angularUser)
        this.drhs.clear()
    }
    
    forgotPassword(email: string): Observable<ForgotPassword> {
        return this.http.post<ForgotPassword>(`${Config.apiUrl}/user/forgot-password`, { email: email })
    }
    
    forgotPasswordReset(password: string, email: string, token: string): Observable<UserAuth> {
        return this.http.post<UserAuth>(`${Config.apiUrl}/user/reset-password`, { password: password, email: email, token: token })
            .pipe(map( user => {
                return this.setUser(user)
            }))
    }
    
    confirmAccount(email: string, token: string): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/user/confirm-account`, { email: email, token: token })
    }
}