<div class="node-card" [ngClass]="{'no-boundary': noBoundary}">
    <div class="node-interaction">
        <div class="node-tile">
            <div class="node-tile-image">
                <img *ngIf="business.logo" src="{{ business.logo }}" alt="business logo">
                <img *ngIf="!business.logo" src="/assets/business/default-logo.svg" alt="business logo">
            </div>
            
            <div class="node-tile-info">
                <p title="{{ business.name }}">{{ business.name }}</p>
                <p class="text-muted" *ngIf="relationship">{{ relationship }}</p>
            </div>
        </div>
        <button class="btn btn-secondary" [ngClass]="{'added': added}" (click)="toggleAdd()">{{ addBtnTxt }}</button>
    </div>
</div>