import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductAPIService } from 'src/app/_api-services/product-api.service';
import { ProductCategory } from 'src/app/_models';

@Component({
	selector: 'product-category-selector',
	templateUrl: './product-category-selector.component.html',
	styleUrl: './product-category-selector.component.scss'
	
})
export class ProductCategorySelectorComponent {
	@Output() closeSelector: EventEmitter<void> = new EventEmitter<void>
	@Output() selectedCategory: EventEmitter<ProductCategory> = new EventEmitter<ProductCategory>

    private subscriptions: Subscription = new Subscription()

    allCategories: {[key: string]: ProductCategory[]} = {}
	history: ProductCategory[] = []

    displayCategories: ProductCategory[] = []
	rootCategoryName = "parent"
	currentParentProduct: ProductCategory | null = null
	hideBackBtn: boolean = true

	constructor (
        private productAPIService: ProductAPIService,
	) {
		this.subscriptions.add(
            this.productAPIService.getParentProductCategories().subscribe({
                next: pc => {
                    this.displayCategories = pc
					this.allCategories[this.rootCategoryName] = pc
                },
                error: err => {
                    console.log(err)
                }
            })
        )


	}

	handleSelection(category: ProductCategory) {
		if (!category.has_children) {
			this.selectedCategory.emit(category)
			this.closeSelector.emit()
			return
		}

		this.hideBackBtn = false
		this.history.push(category)
		this.getChildCategories(category.id)
	}

	getChildCategories(id: string) {
		if (id in this.allCategories) {
			this.displayCategories = this.allCategories[id]
			return
		}

		this.subscriptions.add(
            this.productAPIService.getChildProductCategories(id).subscribe({
                next: pc => {
                    this.displayCategories=pc
					this.allCategories[id] = pc
                },
                error: err => {
                    console.log(err)
                }
            })
        )
	}

	goBack() {
		this.history.pop()
		if (this.history.length == 0) {
			this.hideBackBtn = true
			this.displayCategories = this.allCategories[this.rootCategoryName]
			return
		}
		this.displayCategories = this.allCategories[this.history[this.history.length-1].id]
	}

	close() {
		this.closeSelector.emit()
	}
}
