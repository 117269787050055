import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Hive, User } from 'src/app/_models';
import { HiveAPIService } from 'src/app/_api-services'
import { ModalBottomService } from 'src/app/_services';

@Component({
  selector: 'part-of-hives',
  templateUrl: './part-of-hives.component.html',
  styleUrl: './part-of-hives.component.scss'
})
export class PartOfHivesComponent implements OnDestroy {
    @ViewChild('modal') modal!: TemplateRef<any>
    
    @Input() hives: Hive[] | undefined
    @Input() shared: boolean = false
    
    private subscriptions: Subscription = new Subscription()
    
    members: User[] | null = null
    
    constructor(
        private modalService: ModalBottomService,
    ){
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    showHives(e: Event): void {
        e.stopPropagation()
        e.preventDefault()
        
        this.modalService.open("Explore Hives", this.modal)
    }
    
    closeModal(): void {
        this.modalService.close()
    }
}
