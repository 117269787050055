<div class="modal-overlay" (click)="close()" *ngIf="isVisible">
    <div class="modal" stopPropagation>
        <div class="modal-header">
            <div class="modal-title">
                <img *ngIf="backBtnActivated" src="/assets/icon-back.svg" alt="back icon" (click)="goBack()"> 
                {{ title }}
            </div>
            <span class="close" (click)="close()">
                <img src="/assets/icon-close.svg">
            </span>
        </div>
        <div class="modal-content">
            <ng-container *ngIf="content">
                <ng-container *ngTemplateOutlet="content; context: { data: context }"></ng-container>
            </ng-container>
            
            <div *ngIf="requestFailure" class="error">{{errorMessage}}</div>
        </div>
        <div class="modal-footer" *ngIf="footerBtnActivated">
            <button 
                *ngIf="!showLoader" (click)="footerAction()"
                class="btn btn-primary btn-rounded" 
                [ngClass]="{'success': requestSuccess, 'failed': requestFailure}"
                [disabled]="requestSuccess"
            > 
                {{ footerBtnTxt }}
            </button>
            <loading-spinner *ngIf="showLoader"></loading-spinner>
        </div>
    </div>
</div>