import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchType } from 'src/app/_enums';
import { Hive, HubhiveEvent } from 'src/app/_models';
import { HiveAPIService } from 'src/app/_api-services'
import { AuthenticationService, ModalService } from 'src/app/_services';

@Component({
  selector: 'event-add-hives',
  templateUrl: './event-add-hives.component.html',
  styleUrl: './event-add-hives.component.scss'
})
export class EventAddHivesComponent implements OnInit, OnDestroy {
    @Input() selectedHiveIds: string[] = []
    @Input() event: HubhiveEvent | null = null
    
    @Output() changeStep: EventEmitter<number> = new EventEmitter<number>()
    @Output() addHives: EventEmitter<string[]> = new EventEmitter<string[]>()
    
    private subscpriptions: Subscription = new Subscription()
    
    defaultHives: Hive[] | null = null
    hives: Hive[] | null = null
    searchType: SearchType = SearchType.UserHives
    
    constructor(
        private hiveAPIService: HiveAPIService,
        private modalService: ModalService,
        private authService: AuthenticationService,
    ) {
        this.modalService.updateFooterBtnTxt('View Event')

        this.subscpriptions.add(
            this.modalService.doFooterAction.subscribe( action => {
                this.inviteHives()
            })
        )
    }
    
    ngOnInit(): void {
        if(this.event) {
            this.subscpriptions.add(
                this.hiveAPIService.getUserHivesNotInEvent(this.event.id, this.authService.currentUserValue.user.id).subscribe({
                    next: (hives) => {
                        this.defaultHives = hives
                        this.hives = this.defaultHives
                    },
                    error: (err) => {
                        console.log(err)
                    }
                })
            )    
        }
    }
    
    ngOnDestroy(): void {
        this.subscpriptions.unsubscribe()
    }
    
    toggleHiveSelection(id: string) {
        const index = this.selectedHiveIds.indexOf(id)
        
        if (index >= 0) {
            this.selectedHiveIds.splice(index, 1) 
        } else {
            this.selectedHiveIds.push(id) 
        }
        
        this.updateButton()
    }
    
    updateButton(): void {
        if(this.selectedHiveIds.length > 0) {
            this.modalService.updateFooterBtnTxt('Share to Selected HIves')
            return
        }
        
        this.modalService.updateFooterBtnTxt('Skip')
    }

    setHives(hives: Hive[] | null): void {
        if (hives && hives.length < 1) {
            this.hives = null
            return
        }
        
        if (hives && hives.length > 0) {
            this.hives = hives
            return
        } 
        
        this.hives = this.defaultHives
    }
    
    inviteHives() {
        this.addHives.emit(this.selectedHiveIds)
    }
}
