import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Entity, Product, ProductCategory } from 'src/app/_models';
import { ModalService } from 'src/app/_services';
import { ProductAPIService } from 'src/app/_api-services/product-api.service';
import { CommonValidators } from 'src/app/_validators/common.validators';
import { HttpParams } from '@angular/common/http';
import { FilterAPIService } from 'src/app/_api-services';
import { FilterType } from 'src/app/_enums';
import { EntityHelper } from 'src/app/_helpers';

@Component({
  selector: 'product-create',
  templateUrl: './product-create.component.html',
  styleUrl: './product-create.component.scss'
})
export class ProductCreateComponent implements OnDestroy {
    @ViewChild('searchInput') input!: ElementRef<HTMLInputElement>
    @Output() offerExisting: EventEmitter<Product> = new EventEmitter<Product>

    private subscriptions: Subscription = new Subscription()
    
    readonly filterType: FilterType = FilterType.Product
    productForm!: FormGroup
    productImages: File[] = []
    showAttributes: boolean = false
    loadedDropdownItems: Entity[] | null = null
    currentHighlightIndex: number = -1
    
    category: ProductCategory = {id: "", name:"", has_children: true, children:[]}
    showSelector: boolean = false
    
    constructor(
        private fb: FormBuilder,
        private productAPIService: ProductAPIService,
        private filterAPIService: FilterAPIService,
        private modalService: ModalService,
        private router: Router,
        private entityHelper: EntityHelper,
    ) {
        this.formSetup()
        
        this.subscriptions.add(
            this.modalService.doFooterAction.subscribe( action => {
                this.createProduct()
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    formSetup(): void {
        this.productForm = this.fb.group({
            entity: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            images: this.fb.array([
                this.createImageFormGroup(0),
                this.createImageFormGroup(1),
            ]),
            description: new FormControl('', [Validators.required]),
            price: new FormControl('', [Validators.required, Validators.pattern(/[0-9]+(\\.[0-9][0-9]?)?/)]),
            home_link: new FormControl('', [CommonValidators.isURL]),
            category: new FormControl(''),
            color: new FormControl(''),
            width: new FormControl(''),
            height: new FormControl(''),
            weight: new FormControl(''),
            material: new FormControl(''),
            sku: new FormControl(''),
        })
    }
    
    get name() {
        return this.productForm.get('name')
    }
    
    get images(): FormArray {
        return this.productForm.get('images') as FormArray
    }
    
    get price() {
        return this.productForm.get('price')
    }
    
    get description() {
        return this.productForm.get('description')
    }
    
    get homeLink() {
        return this.productForm.get('home_link')
    }
    
    setCategory(category: ProductCategory): void {
        if(!category) {
            return
        }
        
        this.category = category
        this.productForm.controls['category'].patchValue(category.id) 
    }
    
    getGroup(index: number): FormGroup {
        return this.images.at(index) as FormGroup
    }
    
    createImageFormGroup(index: number): FormGroup {
        return this.fb.group({
            image: ['', [
                CommonValidators.isImage(() => this.productImages[index]),
                CommonValidators.maxFileSize(5, () => this.productImages[index])
            ]]
        });
    }
    
    addImage(index: number): void {
        this.images.push(this.createImageFormGroup(index + 1))
    }
    
    removeImage(index: number): void {
        this.images.removeAt(index)
        
        if(this.productImages[index]) {
            this.productImages.splice(index, 1) //Remove image from array    
        }
    }
    
    appendImage(image: File | null, index: number): void {
        if(!image) {
            delete this.productImages[index]
        } else {
            this.productImages[index] = image
        }
        
        this.addNextDropzone(index)
    }
    
    addNextDropzone(index: number): void {
        if(index < 1 || this.productImages.length < this.images.length || index >= 4) {
            return
        }
        
        this.addImage(index)
    }
    
    toggleAttributes(): void {
        this.showAttributes = !this.showAttributes
    }
    
    getInvalidInputs(): string[] {
        const invalidInputs: string[] = [];
        Object.keys(this.productForm.controls).forEach(key => {
          const control = this.productForm.get(key);
          if (control && control.invalid) {
            invalidInputs.push(key);
          }
        });
        return invalidInputs;
      }
      
    createProduct(): void {
        if(!this.productForm.valid) {   
            this.productForm.markAllAsTouched()
            return
        }
        
        this.modalService.doLoading.next(true)
        
        const formData = new FormData()
        const controls = this.productForm.controls

        const attributes = [
            { name: 'color', value: controls['color'].value },
            { name: 'material', value: controls['material'].value }
        ]
          
        const offer = { price: parseFloat(controls['price'].value), sku: controls['sku'].value, home_link: controls['home_link'].value, business: {id: controls['entity'].value} }
        
          
        const variant = [
            { width: parseFloat(controls['width'].value), height: parseFloat(controls['height'].value), weight: parseFloat(controls['weight'].value) }
        ]

          
        formData.append('attributes', JSON.stringify(attributes))
        formData.append('offer', JSON.stringify(offer))
        formData.append('variants', JSON.stringify(variant))
        
        formData.append('name', controls['name'].value)
        formData.append('description', controls['description'].value)
        formData.append('category', controls['category'].value)


        
        if(this.productImages && this.productImages.length) {       
            for(let i = 0; i < this.productImages.length; i++) {
                formData.append(`image[${i}]`, this.productImages[i])
            }
        }

        this.productAPIService.createProduct(formData).subscribe({
            next: product => {
                this.modalService.close()
                this.router.navigate(['products', product.name], { queryParams: { id: product.id }})
            },
            error: err => {
                this.modalService.doLoading.next(false)
                this.modalService.setRequestFailure("Something went wrong on our end. Please try again.")
                console.log(err)
            }
        })
    }

    selectDropdownItem(selectSearchProduct: Entity): void {
        let p: any = { id: selectSearchProduct.id }
        this.offerExisting.emit(p as Product)
    }

    revealSelector() {
        this.showSelector = true
    }

    closeSelector() {
        this.showSelector = false
    }
}
