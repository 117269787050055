import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { GeneralNotification, Config, Post } from '../_models'
import { Observable } from 'rxjs'
import { Socket } from 'ngx-socket-io'

@Injectable({ providedIn: 'root' })
export class NotificationAPIService {
    private currentRoomId: string | null = null
    
    constructor(private http: HttpClient, private socket: Socket) {
        this.socket.on('connect', () => {
            if (this.currentRoomId) {
                this.joinRoom(this.currentRoomId)
            }
        })
    }
    
    createNotification(data: any): Observable<GeneralNotification> {
        return this.http.post<GeneralNotification>(`${Config.apiUrl}/notification`, data)
    }
    
    updateNotification(notification: GeneralNotification): Observable<GeneralNotification> {
        return this.http.patch<GeneralNotification>(`${Config.apiUrl}/notification/update`, notification)
    }
    
    deleteNotification(id: string): any {
        return this.http.delete<any>(`${Config.apiUrl}/notification/${id}`)
    }
    
    list(limit?: number): Observable<GeneralNotification[]> {
        let params: any = {
            limit: limit ? limit : 0
        }
        
        return this.http.get<GeneralNotification[]>(`${Config.apiUrl}/notification`, { params: params } )
    }
    
    joinRoom(id: string) {
        this.socket.emit('join_room', id)
    }

    listenForNotification(roomId: string): Observable<GeneralNotification> {
        this.currentRoomId = roomId
        this.joinRoom(roomId)
        return this.socket.fromEvent<GeneralNotification>('new_notification')
    }
    
    markAllAsRead(): Observable<GeneralNotification[]> {
        return this.http.patch<GeneralNotification[]>(`${Config.apiUrl}/notification/mark-as-read`, null)
    }
}