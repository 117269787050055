import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Attendee, Entity, User } from 'src/app/_models';

@Component({
  selector: 'missing-avatar',
  templateUrl: './missing-avatar.component.html',
  styleUrl: './missing-avatar.component.scss'
})
export class MissingAvatarComponent implements OnInit, OnChanges {
    @Input() user!: User
    @Input() userEntity!: Attendee | Entity
    
    initials!: string
    color!: string
    
    
    ngOnInit(): void {
        if(!this.user && !this.userEntity) {
            throw new Error('supply either user or user entity')
        }
        
        if(this.user) {
            this.generateUserAvatar()
        }
        
        if(this.userEntity) {
            this.generateEntityAvatar()
        }
        
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['user'] && changes['user'].currentValue) {
            this.generateUserAvatar()
        }
        
        if(changes['userEntity'] && changes['userEntity'].currentValue) {
            this.generateEntityAvatar()
        }
    }
    
    generateUserAvatar(): void {
        this.color = this.user.avatar_color
        
        if(!this.user.first_name[0] || !this.user.last_name[0]) {
            this.initials = ''
            return
        }
        
        this.initials = this.user.first_name[0].toUpperCase() + this.user.last_name[0].toUpperCase()
        
    }
    
    generateEntityAvatar(): void {
        let names = this.userEntity.name.split(' ')
        
        if(names.length < 2) {
            this.initials = 'N/A'
            this.color = '#ff0000'
            return
        }
        
        this.initials = names[0][0].toUpperCase() + names[1][0].toUpperCase()
        this.color = this.userEntity.avatar_color
    }
}
