import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'hive-toolbar',
  templateUrl: './hive-toolbar.component.html',
  styleUrl: './hive-toolbar.component.scss',
})
export class HiveToolbarComponent {
    @Input() hiveName: string | null = null
    
    constructor(
      private router: Router,
    ) {}

    goBack(): void {
        this.router.navigate([`/hives`])
    }
}
