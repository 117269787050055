import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, shareReplay, Subscription } from 'rxjs';
import { FilterAPIService } from 'src/app/_api-services';
import { FilterType } from 'src/app/_enums';
import { EntityHelper } from 'src/app/_helpers';
import { Business, Entity, Hive, HubhiveEvent, Post, Product } from 'src/app/_models';

@Component({
  selector: 'entity-search',
  templateUrl: './entity-search.component.html',
  styleUrl: './entity-search.component.scss'
})
export class EntitySearchComponent implements OnInit, OnDestroy {
    @Input() group: FormGroup = new FormGroup({})
    @Input() label: string | null = null
    @Input() name: string | null = null
    @Input() items: Entity[] = []
    @Input() filterType!: FilterType
    @Input() searchId: string | undefined
    @Input() placeholderText: string | undefined
    
    @Output() doSearch: EventEmitter<string> = new EventEmitter<string>()
    @Output() itemSelected: EventEmitter<Entity> = new EventEmitter<Entity>()
    
    @ViewChild('input') input!: ElementRef<HTMLInputElement>
    
    private currentHighlightIndex: number = -1
    private subscriptions: Subscription = new Subscription()
    private suggestionsCache: Map<string, Observable<any>> = new Map<string, Observable<any>>()
    private searchCache: Map<string, Observable<any>> = new Map<string, Observable<any>>()
    
    searchString: string = ''
    searchIdParamName: string | undefined
    
    constructor(
        private elem: ElementRef,
        private filterAPIService: FilterAPIService,
        private entityHelper: EntityHelper,
        private router: Router,
        private route: ActivatedRoute
    ) {}
    
    ngOnInit(): void {
        if(!this.filterType) {
            throw new Error('Input variable "filterType" must be set!')
        }
        
        switch(this.filterType) {
            case FilterType.BusinessProducts:
                this.searchIdParamName = 'business_ids'
            break
            
            case FilterType.HiveProducts:
            case FilterType.HiveBusinesses:
                this.searchIdParamName = 'hive_ids'
            break
        }
        
        this.subscriptions.add(
            this.route.queryParams.subscribe(url => {
                const params = new URLSearchParams(url['params'])
    
                const searchParam = params.get('search')
                if(searchParam){
                    this.searchString = searchParam
                }
            })    
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    bindKeys(e: KeyboardEvent): void {
        let target = e.target as HTMLInputElement

        if (target != this.input.nativeElement) {
            return
        }
        
        this.searchString = target.value
        
        if(e.key == "Enter") {
            this.search()
            this.resetDropdown()
            return
        }
        
        if (target.value.length < 3) {
            this.resetDropdown()
            return
        }

        // Escape key pressed
        if(e.key == "Escape") {
            this.resetDropdown()
            return
        }
        
        if(e.key == "ArrowDown") {
            this.moveHighlightDown()
            return
        }
        
        if(e.key == "ArrowUp") {
            this.moveHighlightUp()
            return
        }
        
        this.getSuggestions()
    }
    
    resetDropdown(): void {
        this.items = []
        this.currentHighlightIndex = -1
    }
    
    highlightDropdownItem(): void {
        let highlightedSuggestion = this.elem.nativeElement.querySelector('.highlight')
        let suggestions = this.elem.nativeElement.querySelectorAll('.suggestion')
        
        if(highlightedSuggestion) {
            highlightedSuggestion.classList.remove('highlight')
        }
        
        if(!suggestions.length) {
            return
        }
        
        suggestions[this.currentHighlightIndex].classList.add('highlight')
        
        this.scrollToItem(suggestions)
    }
    
    scrollToItem(suggestions: any): void {
        suggestions[this.currentHighlightIndex].scrollIntoView({ block: 'nearest' })
    }
    
    moveHighlightDown(): void {
        if(!this.items) {
            return
        }

        if(this.currentHighlightIndex == this.items.length - 1) {
            this.currentHighlightIndex = 0
        } else {
            this.currentHighlightIndex++
        }
        
        this.highlightDropdownItem()
    }
    
    moveHighlightUp(): void {
        if(!this.items) {
            return
        }
        
        if(this.currentHighlightIndex == 0) {
            this.currentHighlightIndex = this.items.length - 1
        } else {
            this.currentHighlightIndex--
        }
        
        this.highlightDropdownItem()
    }
    
    selectHighlightedDropdownItem(): void {
        if(!this.items) {
            return
        }
        
        this.selectDropdownItem(this.items[this.currentHighlightIndex])
    }
    
    selectDropdownItem(item: Entity): void {
        this.itemSelected.emit(item)
        this.resetDropdown()
    }
    
    stopCursorReset(event: KeyboardEvent): void {
        if(event.key == "ArrowUp" || event.key == "ArrowDown") {
            event.preventDefault()
        }
    }
    
    getSuggestions(): void {
        if (this.searchString == "") {
            this.items = []
            return
        }

        let params = new HttpParams()
        params = params.set('search_string', this.searchString)
        
        if(this.searchIdParamName && this.searchId) {
            params = params.set(this.searchIdParamName, this.searchId)
        }
        
        const cacheKey = this.searchString
        const obs = this.suggestionsCache.get(cacheKey)
        
        if(!obs) {
            this.suggestionsCache.set(
                cacheKey,
                this.filterAPIService.getFilteredRecords(params, this.filterType).pipe(shareReplay(1))
            )
        }
        
        this.subscriptions.add(
            this.suggestionsCache.get(cacheKey)?.subscribe({
                next: (filteredRecords) => {
                    this.items = []
                    
                    let records = filteredRecords["filtered_records"]
                    
                    if (!records || records.length < 0) {
                        return
                    }
                    
                    for(let record of records) {
                        this.items.push(this.entityHelper.toEntity(record))
                    }
                },
                error: (err) => {
                    console.log(err)
                    console.error('filterAPIService.getFilteredRecords returned an error')
                }
            })    
        )
    }
    
    search(): void {
        this.updateUrl(this.searchString)
    }
    
    updateUrl(searchString: string): void {
        let params = this.prepareUrlParams(searchString)
    
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { params: params },
            queryParamsHandling: 'merge',
        })
    }
    
    prepareUrlParams(searchString: string) {
        let params = new HttpParams()
        params = params.set("search", searchString)

        return params
    }
}
