import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekDayDate'
})
export class WeekDayDate implements PipeTransform {

   constructor() {}

  transform(date: string): string {
    const d = new Date(date);

    let formattedDate = d.toLocaleString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    const parts = formattedDate.split(', ');
    const result = `${parts[0]}, ${parts[1]}, ${parts[2]}`;

    return result
  }
    
}