import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { AuthenticationService, PublicPlusService } from 'src/app/_services';

@Component({
  selector: 'public-plus-button',
  templateUrl: './public-plus-button.component.html',
  styleUrl: './public-plus-button.component.scss'
})
export class PublicPlusButtonComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    
    isModalActive: boolean = false
    userIsLoggedIn: boolean = false
    userOnRegistrationProcess: boolean = false
    isVisible = true
    
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private publicPlusService: PublicPlusService,
    ) {
        this.subscriptions.add(
            this.authService.currentUserSubject.subscribe( user => {
                this.userIsLoggedIn = this.authService.isLoggedIn()    
            })
        )
        
        this.subscriptions.add(
            this.publicPlusService.isOpen.subscribe( isOpen => {
                if(isOpen) {
                    this.open()
                } else {
                    this.close()
                }
            })
        )
        
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                this.userOnRegistrationProcess = item.url === '/register' ? true : false
            }
        })
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    openModal() {
        this.isModalActive = true
    }

    closeModal() {
        this.isModalActive = false
    }

    handleCreateOption(option: string) {
        this.closeModal()
    }

    open(): void {
        this.isVisible = true
    }

    close(): void {
        this.isVisible = false
    }
}