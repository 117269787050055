import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Entity, Hive, OptionDropdown } from 'src/app/_models';
import { NavigationService } from 'src/app/_services/navigation.service';

@Component({
	selector: 'tool-bar',
	templateUrl: './tool-bar.component.html',
	styleUrl: './tool-bar.component.scss'
})
export class ToolBarComponent implements OnInit {
  	@Input() hive: Hive | null = null
	@Input() name: string | undefined
	@Input() defaultBackPage: string | undefined
	@Input() backgroundNotWhite: boolean = false
    @Input() entity!: Entity
	@Input() linkTo: Entity | undefined
    @Input() subTitle: string | null = null
    @Input() options: OptionDropdown[] = []
    
	constructor(
		private router: Router,
		private navigationService: NavigationService,
	) {
		
	}
    
    ngOnInit(): void {
        this.navigationService.addHistory(this.router.url)
    }
    
	backIconColor(): string {
		if(this.backgroundNotWhite) {
			return 'white'
		}
        
		return 'black'
	}
	
	goBack(): void {
        if(this.linkTo) {
            let url = '/'
            let params: any = {}
            
            switch(this.linkTo.type) {
                case 'post':
                    url = `/post/${this.linkTo.id}/details`
                break
                
                case 'hive':
                    url = `/${this.linkTo.handle}/posts`
                break
                
                case 'event':
                    url = `/event/${this.linkTo.name}/posts`
                    params['id'] = this.linkTo.id
                break
                
                case 'business':
                    url = `/business/${this.linkTo.handle}/posts`
                break
            }
            
            this.router.navigate([url], { queryParams: params })
            return
        }
        
		const lastPage = this.navigationService.getPreviousPage()
		let page = lastPage

		if (lastPage == "" && this.defaultBackPage) {
			page = this.defaultBackPage
		}
        
		this.router.navigateByUrl(page)
	}
}
