<form [formGroup]="addForm" *ngIf="addForm">       
    <div class="flex flex-center">
        <div class="field-group logo">
            <div class="dropzone logo" 
                (click)="setBusinessLogoImage()" 
                (dragenter)="onLogoDrageEnter($event)" 
                (dragleave)="onLogoDragLeave($event)" 
                (dragover)="onDragOver($event)"
                (dragend)="onDragEnd($event)"
                (drop)="onLogoDrop($event)" [ngClass]="{'hover': logoDragEntered}"
                [style.background]=" logoPreviewUrl ? 'url('+ logoPreviewUrl + ') center / cover no-repeat' : ''">
                <img *ngIf="!logoPreviewUrl" src="/assets/icon-add-image.svg" alt="add image icon">
                <p *ngIf="!logoPreviewUrl">Logo</p>
            </div>
            
            <input #businessLogoImageInput type="file" formControlName="logo" (change)="logoImageChanged($event)">
            
            <div *ngIf="logo?.errors?.['invalidType']">
                <span class="error">Invalid image type</span>    
            </div>
            
        </div>

        <div class="field-group fancy-shmancy grow-flex">
            <div>
                <entity-search 
                    [group]="addForm" 
                    [name]="'name'" 
                    [label]="'Company Name'" 
                    [filterType]="filterType"
                    (itemSelected)="setBusiness($event)"
                    >
                </entity-search>
                <span *ngIf="name?.errors?.['required'] && name?.touched" class="error">Company Name is required</span>
                <div *ngIf="name?.errors?.['existingBusiness']">
                    <p class="error">There is already a business listed as <a (click)="closeModal()" [routerLink]="getBusinessLink(name?.errors?.['existingBusiness'])">{{name?.errors?.['existingBusiness'].name}}</a></p>
                </div>
            </div>
        </div>
    </div>

    <div class="field-group fancy-shmancy">
        <div>
            <select #categoryDropdown class="always-has-content" formControlName="categories">
                <option *ngFor="let c of busCategories" [value]="c.id">{{ c.name }}</option>
            </select>
            <label>Category</label>
            <span *ngIf="categories?.errors?.['required'] && categories?.touched" class="error">Category is required</span>
        </div>
    </div>
    
    <div class="field-group">
        <div class="dropzone promo" 
            (click)="setBusinessPromoImage()" 
            (dragenter)="onPromoDrageEnter($event)" 
            (dragleave)="onPromoDragLeave($event)" 
            (dragover)="onDragOver($event)"
            (dragend)="onDragEnd($event)"
            (drop)="onPromoDrop($event)" [ngClass]="{'hover': promoDragEntered}"
            [style.background]=" promoPreviewUrl ? 'url('+ promoPreviewUrl + ') center / cover no-repeat' : ''">
            <img *ngIf="!promoPreviewUrl" src="/assets/icon-add-image.svg" alt="add image icon">
            <p *ngIf="!promoPreviewUrl">Add Featured Image</p>
        </div>
        
        <input #businessPromoImageInput type="file" formControlName="promo_image" (change)="promoImageChanged($event)">
        <div *ngIf="promoImage?.errors?.['invalidType']">
            <span class="error">Invalid image type</span>    
        </div>
    </div>

    <div class="field-group fancy-shmancy">
        <div>
            <textarea #summaryInput formControlName="summary" hasContent></textarea>
            <label>Describe your business</label>
            <span *ngIf="summary?.errors && summary?.touched" class="error">Summary is required</span>
        </div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <input #emailInput type="text" formControlName="email" hasContent>
            <label>Business Email</label>
            <span *ngIf="email?.errors && email?.touched" class="error">Email is required</span>
        </div>
    </div>

    <div class="field-group fancy-shmancy">
        <div>
            <input #streetInput type="text" formControlName="street" hasContent>
            <label>Street Address</label>
            <span *ngIf="street?.errors && street?.touched" class="error">Street is required</span>
        </div>
    </div>

    <div class="flex align-start">
        <div class="field-group fancy-shmancy">
            <div>
                <input #cityInput type="text" formControlName="city" hasContent>
                <label>City</label>
                <span *ngIf="city?.errors && city?.touched" class="error">City is required</span>
            </div>
        </div>
        <div class="field-group fancy-shmancy space">
            <div>
                <state-selector [form]="addForm"></state-selector>
                <span *ngIf="state?.errors && state?.touched" class="error">State is required</span>
            </div>
        </div>
        <div class="field-group fancy-shmancy">
            <div>
                <input #zipInput type="text" formControlName="zip" hasContent>
                <label>Zip-Code</label>
                <span *ngIf="zip?.errors && (zip?.touched || zip?.dirty)" class="error">Invalid zip code</span>
            </div>
        </div>
    </div>
    
    <div class="field-group">
        <div class="hh-checkbox">
            <label>
                <input type="checkbox" formControlName="show_address">
                <span class="checkmark"></span>
            </label>
            <span class="label">Show address on business profile</span>
        </div>
    </div>
    
    <div class="field-group fancy-shmancy" *ngIf="subCategories.length">
        <div>
            <select class="always-has-content" formControlName="sub_category">
                <option *ngFor="let c of subCategories" [value]="c.id">{{ c.name }}</option>
            </select>
            <label>Sub Category</label>
        </div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <input #phoneInput id="tel" type="tel" formControlName="phone" hasContent 
                phoneMask
                [preValue]="addForm.value.phone"
                [phoneControl]="addForm.controls['phone']" maxlength="14"
                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
            <label>Company Phone Number</label>
            <span *ngIf="phone?.errors?.['required'] && phone?.touched" class="error">Phone is required</span>
            <span *ngIf="phone?.touched && !phone?.errors?.['required'] && phone?.errors?.['invalidPhone']" class="error">Invalid phone number</span>
        </div>
    </div>

    <div class="field-group fancy-shmancy">
        <div>
            <input #hoursInput type="text" formControlName="hours" hasContent>
            <label>Hours of Operation</label>
            <span *ngIf="hours?.errors && hours?.touched" class="error">Hours of Operation are required</span>
        </div>
    </div>

    <div class="field-group fancy-shmancy">
        <div>
            <input #websiteInput type="text" formControlName="website" hasContent>
            <label>Website</label>
            <span *ngIf="website?.errors && website?.touched" class="error">Website is required</span>
        </div>
    </div>
</form>
