import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { asapScheduler, observeOn, Subscription } from 'rxjs';
import { Tab } from 'src/app/_models'
import { DetachedRouteHandleService } from 'src/app/_services/detached-route-handle.service';
@Component({
    selector: 'nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent implements OnInit {
    @Input() tabs: Tab[] = []
    @Output() tabSelected: EventEmitter<string> = new EventEmitter<string>()

	@ViewChild('navbar', { read: ElementRef }) public navContent: ElementRef<any> | null = null

    showLeftArrow: boolean = false
    showRightArrow: boolean = false
    private subscriptions: Subscription = new Subscription()
	private timeoutId: any

	constructor(
        private route: ActivatedRoute,
        private drhs: DetachedRouteHandleService,
	) { 
        
	}
	
	ngOnInit(): void {
        this.onAttach()
        
        this.subscriptions.add(
            this.drhs.setting.subscribe( changes => {
                changes == this.drhs.getPath(this.route) ? this.onDetach() : this.onAttach()
            })
        )
        
    }

	onAttach(): void {
		this.timeoutId = setTimeout(() => {
			this.checkScrollPosition()
		},250)
    }
    
    onDetach(): void {
		clearTimeout(this.timeoutId)
    }

    selectTab(event: Event, title: string) {
        event.preventDefault() 
        this.tabSelected.emit(title)
    }

	scrollRight(): void {
		if (this.navContent) {
			this.navContent.nativeElement.scrollTo({ left: (this.navContent.nativeElement.scrollLeft + 150), behavior: 'smooth' })
		}
	}

	scrollLeft(): void {
		if (this.navContent) {
			this.navContent.nativeElement.scrollTo({ left: (this.navContent.nativeElement.scrollLeft - 150), behavior: 'smooth' })
		}
	}

	checkScrollPosition(): void {
        if (this.navContent) {
            const nativeEl = this.navContent.nativeElement
            const maxScrollLeft = nativeEl.scrollWidth - nativeEl.clientWidth

            this.showLeftArrow = nativeEl.scrollLeft > 0
            this.showRightArrow = nativeEl.scrollLeft < maxScrollLeft - 1

			if (maxScrollLeft !== 0 && this.timeoutId) {
                clearTimeout(this.timeoutId)
                this.timeoutId = null 
            }
        }
    }

    @HostListener('window:resize')
    onResize() {
        this.checkScrollPosition()
    }
}