import { ApplicationRef, Injectable } from '@angular/core'
import { Post, User } from '../_models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DefaultImage } from '../_enums';
import { DatePipe } from '@angular/common'

@Injectable({ providedIn: 'root' })
export class PostHelpers {
    
    constructor(
        private sanitizer: DomSanitizer,
        private datePipe: DatePipe,
    ){}
    
    processPost(post: Post): SafeHtml {
        let text = this.processLinks(post.text.toString())
        text = this.processMentions(text, post.mentions)
        return this.sanitizer.bypassSecurityTrustHtml(text)
    }
    
    processMentions(text: string, mentionedUsers: User[]): string {
        if(!mentionedUsers) {
            return text    
        }
        
        const mentionPattern = /@\{([^}]+)\}/g;
        
        let mentions = new Set<string>()
        let match

        while ((match = mentionPattern.exec(text)) !== null) {
            // Push the cleaned mention (without @{ and }) to the array
            mentions.add(match[1])
        }
        
        let processedTxt = text
        for(let userId of mentions) {
            for(let [i, mention] of mentionedUsers.entries()) {
                if(userId == mention.id) {
                    const mentionHtml = this.createMentionComponent(mention)
                    processedTxt = processedTxt.replaceAll(new RegExp(`@\\{${userId}\\}`, 'g'), mentionHtml)
                }
            }    
        }
        
        return processedTxt
    }
    
    createMentionComponent(user: User): string {
        const avatar = user.avatar ? user.avatar : DefaultImage.UserAvatar
        const html = `
        <span class="mention">
            <a class="user-card">
                <span class="img-container">
                    <img src="${avatar}" alt="${user.first_name} ${user.last_name}">
                </span>
                <span class="flex flex-center user-name">
                    <span>${ user.first_name } ${ user.last_name }</span>
                </span>
            </a>
            <span>@${ user.first_name } ${ user.last_name }</span>
        </span>
        `
 
        return html
    }
    
    processLinks(text: string): string {
        const rgx = /(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/?[\w\-._~:/?#[\]@!$&'()*+,;=]*)?/g
        const replacedText = text.replace(rgx, (url) => {
            let href = url
            
            if (!href.startsWith('http')) {
                href = 'http://' + href
            }
            
            return `<a href="${href}" target="_blank" rel="nofollow">${url}</a>`
        })
        
        return replacedText
    }
    
    formatDate(date: string): string | null {
        return this.datePipe.transform(date, 'MMM d, y');
    }
    
    hasMonkePhrase(text: string): boolean {
        let lowerStr = text.toLowerCase()
        const rgx = /\bmonke\b/
        return rgx.test(lowerStr)
    }
}