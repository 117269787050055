import { Component, Input } from '@angular/core';
import { Attendee, Entity } from 'src/app/_models';

@Component({
  selector: 'entity-profile-card',
  templateUrl: './entity-profile-card.component.html',
  styleUrl: './entity-profile-card.component.scss'
})
export class EntityProfileCardComponent {
    @Input() attendee!: Attendee
    
    getImage(attendee: Attendee): string {
        if (attendee.type === "User") {
            return attendee.image
        } else {
            return attendee.image ? attendee.image : "/assets/business/default-logo.svg";
        }
    }
}
