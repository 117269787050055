import { Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core'
import { Slide } from 'src/app/_models'

@Component({
  selector: 'tutorial-slider',
  templateUrl: './tutorial-slider.component.html',
  styleUrl: './tutorial-slider.component.scss'
})
export class TutorialSliderComponent {
    @ContentChildren(TemplateRef) slideTemplates!: QueryList<TemplateRef<any>>
    @Input() slides: Slide[]= []
    
    private startX = 0
    private currentX = 0
    private isDragging = false

    activeSlideIndex = 0
    
    startDrag(event: MouseEvent | TouchEvent): void {
        this.isDragging = true
        this.startX = this.getPositionX(event)
        this.currentX = this.getPositionX(event)
    }
    
    onDrag(event: MouseEvent | TouchEvent): void {
        if (!this.isDragging) return
    
        this.currentX = this.getPositionX(event)
    }
    
    endDrag(event: MouseEvent | TouchEvent): void {
        if (!this.isDragging) return
    
        const deltaX = this.currentX - this.startX
        
        if (deltaX > 50) {
            this.prevSlide()
        } else if (deltaX < -50) {
            this.nextSlide()
        }
    
        this.isDragging = false
    }
    
    private getPositionX(event: MouseEvent | TouchEvent): number {
        if (event instanceof MouseEvent) {
            return event.clientX
        } else if (event instanceof TouchEvent) {
            return event.touches[0].clientX
        }
        return 0
    }
    
    goToSlide(index: number): void {
        this.activeSlideIndex = index
    }
    
    nextSlide(): void {
        if(this.activeSlideIndex == this.slides.length - 1) {
            return
        }
        
        this.goToSlide((this.activeSlideIndex + 1) % this.slides.length)
    }
    
    prevSlide(): void {
        if(this.activeSlideIndex == 0) {
            return
        }
        
        this.goToSlide((this.activeSlideIndex - 1 + this.slides.length) % this.slides.length)
    }
}
