<div class="relative">
    <div class="suggestions">
        <div class="header">
            <div class="top-row">
                <div class="left-section" *ngIf="!hideBackBtn" (click)="goBack()">
                    <img class="back" src="/assets/icon-back-black.svg" alt="back icon">
                    <span *ngIf="history.length > 0">{{history[history.length-1].name}}</span>
                </div>
                
                <div class="right-section">
                    <img class="close" src="/assets/icon-close.svg" alt="close icon" (click)="close()">
                </div>
            </div>

            <div class="breadcrumbs" *ngIf="history.length > 1">
                <span *ngFor="let p of history; let last = last">
                    {{p.name}}<span *ngIf="!last"> > </span>
                </span>
            </div>
        </div>
        
        <div class="suggestion" *ngFor="let cat of displayCategories; let i = index" (click)="handleSelection(cat)">
            <div class="selector" *ngIf="cat.has_children">
                {{cat.name}}
                <img src="/assets/icon-forward.svg" alt="forward icon">
            </div>
            <div class="selector" *ngIf="!cat.has_children">
                {{cat.name}} 
                <img src="/assets/icon-select.svg" alt="select icon">
            </div>
        </div>
    </div>
</div>
