import { Component, OnInit, Input, Output, EventEmitter, forwardRef, HostListener, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'tag-input',
	templateUrl: './tag-input.component.html',
	styleUrls: ['./tag-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => TagInputComponent),
		multi: true
	}]
})
export class TagInputComponent implements ControlValueAccessor {
	@Input() existingTags: string[] = []
	@Input() maxTags: number = 5
	@Output() tagChange: EventEmitter<string[]> = new EventEmitter<string[]>()

	tags: string[] = []
	tagInput: string = ''
	suggestions: string[] = []
	isHovering: boolean = false

	private onChange: (tags: string[]) => void = () => {}
	private onTouched: () => void = () => {}

	constructor() {}

	writeValue(tags: string[]) {
		this.tags = tags
	}

	registerOnChange(fn: any) {
		this.onChange = fn
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn
	}

	onTagInputChange() {
		this.tagInput = this.cleanInput(this.tagInput)
		this.suggestions = this.existingTags.filter(tag => 
			tag.toLowerCase().includes(this.tagInput.toLowerCase()) && !this.tags.includes(tag)
		)
	}

	addTag(tag: string) {
		if (this.tags.length < this.maxTags && !this.tags.includes(tag)) {
			this.tags.push(tag)
			this.tagInput = ''
			this.suggestions = []
			this.onChange(this.tags)
			this.tagChange.emit(this.tags)
		}
	}

	removeTag(tag: string) {
		this.tags = this.tags.filter(t => t !== tag)
		this.onChange(this.tags)
		this.tagChange.emit(this.tags)
	}

	onInputKeydown(event: KeyboardEvent) {
		if ((event.key === 'Enter' || event.key === 'Tab') && this.suggestions.length > 0) {
			event.preventDefault()
			this.addTag(this.suggestions[0])
		}
	}

	onInputKeyup(event: KeyboardEvent) {
		if (event.key === 'Enter' && this.tagInput.trim()) {
			let tempTag = this.capitalizeWords(this.cleanInput(this.tagInput))
			this.addTag(tempTag.trim())
			return
		} 
		if (event.key === 'Escape' || this.tagInput.length == 0) {
			this.suggestions = []
			return
		}
	}

	onSuggestionMouseEnter() {
		this.isHovering = true
	}

	onSuggestionMouseLeave() {
		this.isHovering = false
	}

	capitalizeWords(text: string): string {
		return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
	}

	cleanInput(input: string) {
		let cleaned = input.replace(/[^a-zA-Z0-9& ']/g, '')
		return cleaned
	}
}
