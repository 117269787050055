<div class="nav-container">
    <div class="scroll-arrow left" (click)="scrollLeft()" *ngIf="showLeftArrow">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
    </div>

    <div class="navbar" #navbar (scroll)="checkScrollPosition()">
        <a 
            *ngFor="let tab of tabs" 
            routerLink="{{tab.link}}"
            (click)="selectTab($event, tab.title)" routerLinkActive="active">
            {{ tab.title }}
        </a>
    </div>

    <div class="scroll-arrow right" (click)="scrollRight()" *ngIf="showRightArrow">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
    </div>
</div>
