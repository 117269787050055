import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HubhiveEvent, OptionDropdown } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { EventAPIService } from 'src/app/_api-services';
import { EntityType } from 'src/app/_enums';
import { ClipboardHelper } from 'src/app/_helpers';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
    @Input() event: HubhiveEvent | null = null
    @Input() listDivClass: string = 'event-card-compressed'
    @Input() outline: boolean = false
    @Input() showOptions: boolean = false
    @Input() entityType: EntityType | undefined
    @Input() entityId: string | undefined
    
    @Output() removedFromHive: EventEmitter<void> = new EventEmitter<void>()
    
    private subscriptions: Subscription = new Subscription()
    
    defaultListClass: string = 'event-card-compressed'
    isDifferentDays: boolean = false
    currentUserId: string = ""
    dropdownOptions: OptionDropdown[] = []


    constructor(
        private authService: AuthenticationService,
        private eventAPIService: EventAPIService,
        private clipboardHelper: ClipboardHelper,
    ) {
        this.currentUserId = this.authService.currentUserValue.user.id
    }

    ngOnInit(): void {
        if((this.entityId !== undefined && this.entityType === undefined) || (this.entityType !== undefined && this.entityId === undefined)) {
            throw new Error('If you sent entityId or entityType you MUST set both')
        }
        
        if (this.event && this.event.end_date) {
            this.isDifferentDays = this.checkDifferentDays(this.event.start_date, this.event.end_date)
        }
        
        this.setOptionDropdown()
    }

    getHostNames(event: HubhiveEvent): string {
        return event.hosts.map((host: any) => host.name).join(', ')
    }

    checkDifferentDays(startDate: string, endDate: string): boolean {
        const start = new Date(startDate)
        const end = new Date(endDate)
        return start.toDateString() !== end.toDateString()
    }
    
    setOptionDropdown(): void {
        if(!this.event) {
            return
        }
        
        if(!this.showOptions) {
            return
        }
        
        switch(this.entityType) {
            case EntityType.Hive:
                this.dropdownOptions.push(
                    {
                        label: 'Remove from hive',
                        action: () => { this.removeFromHive() },
                        keep_open: false,
                    }
                )
            break
        }
        
        this.dropdownOptions.push(
            {
                label: "Share",
                action: () => { 
                    this.clipboardHelper.handleShare(`/event/${this.event!.name}?id=${this.event!.id}`) 
                },
                keep_open: true,
            }
        )
    }
    
    removeFromHive(): void {
        if(!this.event || !this.entityId) {
            return
        }
        
        this.subscriptions.add(
            this.eventAPIService.removeFromHive(this.event.id, this.entityId).subscribe({
                next: event => {
                    this.removedFromHive.emit()
                },
                error: err => {
                    
                }
            })    
        )
    }
}
