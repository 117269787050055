import { Injectable, TemplateRef } from '@angular/core';
import { TutorialModalComponent } from '../shared/tutorials/components/tutorial-modal/tutorial-modal.component';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {
    private modalComponent: TutorialModalComponent | null = null
    private nextAction: Subject<void> = new Subject<void>()
    private backAction: Subject<void> = new Subject<void>()
    private skipAction: Subject<void> = new Subject<void>()
    
    constructor() { }
  
    bindModalComponent(component: TutorialModalComponent): void {
        this.modalComponent = component
    }

    open(content: TemplateRef<any>, context?: any): void {
        if(this.modalComponent) {
            this.modalComponent.open(content, context)
        } else {
            console.log('modal not bound')
        }
    }
    
    close(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.close()
    }
    
    getNextAction(): Observable<void> {
        return this.nextAction.asObservable()
    }
    
    doNext(): void {
        this.nextAction.next()
    }
    
    getBackAction(): Observable<void> {
        return this.backAction.asObservable()
    }
    
    goBack(): void {
        this.backAction.next()
    }
    
    getSkipAction(): Observable<void> {
        return this.skipAction.asObservable()
    }
    
    doSkip(): void {
        this.skipAction.next()
    }
    
    activateBack(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.backActive = true
    }
    
    deactivateBack(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.backActive = false
    }
    
    setNextButtonText(text: string): void {
        if(!this.modalComponent) {
            return
        }
        this.modalComponent.setNextButtonText(text)
    }
}
