import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription, shareReplay } from 'rxjs';
import { UserAPIService } from 'src/app/_api-services';
import { SearchType } from 'src/app/_enums';
import { User } from 'src/app/_models';


@Component({
selector: 'add-member-search',
templateUrl: './add-member-search.component.html',
styleUrl: './add-member-search.component.scss'
})

export class AddMemberSearchComponent implements OnInit, OnDestroy {
    @ViewChild('searchInput') input!: ElementRef<HTMLInputElement>
    @ViewChild('searchContainer') searchContainer!: ElementRef;
    
    @Input() searchType!: SearchType
    @Input() placeholderText: string = 'Search'
    @Input() searchId: string | undefined
    @Output() recordOutput: EventEmitter<[User[] | null, boolean, string[]]> = new EventEmitter<[User[] | null, boolean, string[]]>
    
    private subscriptions: Subscription = new Subscription()
    private readonly dataSearchCache = new Map<string, Observable<any>>()
    private readonly phoneNumPattern = /^\+?1?\d{10,15}$/
    
    loadedDropdownItems: string[] | null = null
    currentHighlightIndex: number = 0
    searchString: string = ''
    theName: string | null = null
    fullNames: string[] | null = null
    
    constructor(
        private userAPIService: UserAPIService,
    ) {
    }
    
    ngOnInit(): void {
        if(this.searchType == null || this.searchType == undefined) {
            throw new Error('Input variable "searchType" must be set!')
        }
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    stopFlashing(event: KeyboardEvent): void {
        if(event.key == 'Enter') {
            event.preventDefault()
        }
    }
    
    searchSuggestions(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
        
        let target = event.target as HTMLInputElement
        this.searchString = target.value
        
        if(this.searchString.length < 2) {
            this.doOutputEvent(null)
            return
        }

        const emailPattern = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*,\s*)*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        
        if (emailPattern.test(this.searchString) && event.key == "Enter") {
            this.search(this.searchString, "email")
            this.input.nativeElement.value = ''
            return
        } 
        
        if(this.phoneNumPattern.test(this.searchString) && event.key == "Enter")  {
            this.search(this.searchString, "phone")
            this.input.nativeElement.value = ''
            return
        }
        
        if(!this.loadedDropdownItems) {
            this.search(this.searchString, this.searchType)    
        }
        
        return
    }
    
    search(searchString: string, type: string = ""): void {
        if (type == "email" || type == "phone") {
            const sString = searchString.replaceAll(' ','')
            this.subscriptions.add(
                this.userAPIService.getCurrentUserHiveContacts(sString, type).subscribe({
                    next: (users) => {
                        users = users ? users : []
                        var unregisteredEmails: string[] = []
                        var registeredEmails: string[] = ['']
                        var emailAndPhone = searchString.split(',')

                        for (var i = 0; i < emailAndPhone.length; i++) {
                            emailAndPhone[i] = emailAndPhone[i].toLowerCase().replaceAll(' ','')
                        }
                        
                        
                        users.forEach((user:any) => {
                            registeredEmails.push(user.email)
                            registeredEmails.push(user.phone)
                        })
                        
                        emailAndPhone.forEach((email:any) => {
                            if(this.phoneNumPattern.test(email)) {
                                return
                            }
                            
                            if (!registeredEmails.includes(email)) {
                                unregisteredEmails.push(email)
                            }
                        })
                        
                        console.log('searching for:', users)
                        
                        this.doOutputEvent(users, true, unregisteredEmails)
                    },
                    error: (err) => {
                        console.log(err)
                    }
                })
            )
            
            return
        }

        const cacheKey = searchString
        
        if(!this.dataSearchCache.get(cacheKey)) {
            this.dataSearchCache.set(
                cacheKey,
                this.userAPIService.getCurrentUserHiveContacts(searchString).pipe(shareReplay(1))
            )
        } 
        
        this.subscriptions.add(
            this.dataSearchCache.get(cacheKey)!.subscribe({
                next: (records) => { 
                    records = records ? records : []
                    this.doOutputEvent(records)
                },
                error: (err) => {
                    this.doOutputEvent(null)
                }
            })
        )
        return
    }
    
    doOutputEvent(users: User[]|null, type: boolean = false, unregisteredEmails: string[] = []): void {
        this.recordOutput.emit([users, type, unregisteredEmails])
    }
}
