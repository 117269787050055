import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchType } from 'src/app/_enums';
import { Hive } from 'src/app/_models';
import { BusinessAPIService, HiveAPIService } from 'src/app/_api-services'
import { BusinessService, ModalService } from 'src/app/_services';

@Component({
  selector: 'business-add-hives',
  templateUrl: './business-add-hives.component.html',
  styleUrl: './business-add-hives.component.scss'
})
export class BusinessAddHivesComponent implements OnDestroy, OnInit {
    @Input() businessId: string = ""

    private subscriptions: Subscription = new Subscription()
    
    defaultHives: Hive[] | null = null
    hives: Hive[] | null = null
    
    searchType: SearchType = SearchType.UserHives
    selectedHiveIds: string[] = []
    
    constructor(
        private hiveAPIService: HiveAPIService,
        private businessAPIService: BusinessAPIService,
        private businessService: BusinessService,
        private modalService: ModalService
    ) {
        this.modalService.activateFooterBtn()
        this.modalService.updateFooterBtnTxt('Back to Business')

        this.subscriptions.add(
            this.modalService.doFooterAction.subscribe( action => {
                this.inviteHives()
            })
        )
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.hiveAPIService.getUserHivesNotInBusiness(this.businessId).subscribe({
                next: (hives) => {
                    this.defaultHives = hives
                    this.hives = this.defaultHives
                },
                error: (err) => {
                    console.log(err)
                }
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    
    toggleHiveSelection(id: string) {
        const index = this.selectedHiveIds.indexOf(id)
        
        if (index >= 0) {
            this.selectedHiveIds.splice(index, 1) 
        } else {
            this.selectedHiveIds.push(id) 
        }
        
        this.updateButton()
    }
    
    updateButton(): void {
        if(this.selectedHiveIds.length > 0) {
            this.modalService.updateFooterBtnTxt('Share to Selected Hives')
            return
        }
        
        this.modalService.updateFooterBtnTxt('Back to Business')
    }

    setHives(hives: Hive[] | null): void {
        if (hives && hives.length < 1) {
            this.hives = null
            return
        }
        
        if (hives && hives.length > 0) {
            this.hives = hives
            return
        } 
        
        this.hives = this.defaultHives
    }
    
    inviteHives() {
        this.modalService.doLoading.next(true)
        this.subscriptions.add(
            this.businessAPIService.addBusinessToHives(this.businessId, this.selectedHiveIds).subscribe({
                next: (business) => {
                    this.businessService.currentBusiness.next(business)
                    this.modalService.close()
                },
                error: (err) => {
                    this.modalService.setRequestFailure(err.error)
                    this.modalService.doLoading.next(false)
                }
            })    
        )

    }
}
