import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({
  selector: '[hasContent]',
})
export class HasContentDirective {

    constructor(private el: ElementRef) { }
    
    ngAfterViewInit(): void {
        this.toggleHasContentClass(this.el.nativeElement.value)
    }

    @HostListener('input', ['$event.target.value'])
    @HostListener('change', ['$event.target.value'])
    onInput(value: string): void {
        this.toggleHasContentClass(value)
    }

    private toggleHasContentClass(value: string): void {
        if (value.trim()) {
            this.el.nativeElement.classList.add('has-content')
        } else {
           this.el.nativeElement.classList.remove('has-content')
        }
    }
}
