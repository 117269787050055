import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HubhiveEvent } from 'src/app/_models';

@Component({
  selector: 'event-node-card',
  templateUrl: './event-node-card.component.html',
  styleUrl: './event-node-card.component.scss'
})
export class EventNodeCardComponent {
    @Input() event!: HubhiveEvent
    @Input() relationship: string | null = null
    @Input() addBtnTxt: string = 'Add'
    @Input() noBoundary: boolean = false
    
    @Output() toggled: EventEmitter<string> = new EventEmitter<string>()
    
    added: boolean = false
    
    toggleAdd(): void {
        this.added = !this.added
        this.toggled.emit(this.event.id)    
    }
}
