import { Component, Input } from '@angular/core';

@Component({
  selector: 'alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent {
    @Input() title: string = ''
    @Input() message: string = ''
    @Input() buttons: { name: string, action: () => void }[] = []

    isVisible: boolean = false

    show() {
        this.isVisible = true
    }

    hide() {
        this.isVisible = false
    }
}