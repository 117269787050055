<div class="events-container" [ngClass]="listDivClass == defaultListClass ? 'content-container' : ''" *ngIf="event">
    <div class="event-card-container {{ listDivClass }}">
        <div class="event-card" class="event-card" [ngClass]="{'outline': outline}">
            <div class="info-section flex space-between">
                <p class="event-title align-center">{{event.name}}</p>
                <options-dropdown *ngIf="showOptions" [options]="dropdownOptions"></options-dropdown>
            </div>
            
            <a class="flex flex-center" routerLink="/event/{{event.name}}/details"
               [queryParams]="{ id: event.id }">
                
                <div class="event-info">
                    <div class="event-time-container info-section">
                        <p *ngIf="!isDifferentDays || !event.end_date" class="event-time">{{ event.start_date | dateTime }}</p>
                        <p *ngIf="isDifferentDays && event.end_date" class="event-time">{{ event.start_date | weekDayDate }} - {{ event.end_date | weekDayDate}}</p>
                    </div>
                    <p *ngIf="event.hosts" class="event-host info-section">Hosted by: {{ getHostNames(event) }}</p>
                    <part-of-hives [hives]="event.hives" class="info-section"></part-of-hives>
                    <a routerLink="/event/{{event.name}}/details" [queryParams]="{id: event.id}" class="attendees info-section">
                        <div class="attendee" *ngFor="let a of event.attendees | slice:0:5">
                            <img *ngIf="a.image" [src]="a.image" [alt]="a.name">
                            <missing-avatar *ngIf="!a.image" [userEntity]="a" class="tiny"></missing-avatar>
                        </div>
                    </a>
                </div>
                
                <img *ngIf="event.image" src="{{event.image}}" alt="Event Image" class="event-img">
                <img *ngIf="!event.image" src="/assets/default_event_image.png" alt="Event Image" class="event-img">
            </a>
        </div>
    </div>
</div>
