import { Injectable, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { BehaviorSubject, filter, Subject, Subscription } from 'rxjs'

@Injectable()
export class HeaderService implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    private routeHeaderState: { [url: string]: { hideHeader: boolean, showLightIcons: boolean } } = {}
    
    showLightIcons: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    hideHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    unstickHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    
    constructor(private router: Router) { 
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                const currentUrl = this.router.url

                if (this.routeHeaderState[currentUrl]) {
                    // Restore header state for the current route
                    const state = this.routeHeaderState[currentUrl]
                    this.hideHeader.next(state.hideHeader)
                    this.showLightIcons.next(state.showLightIcons)
                } else {
                    // If no state exists for the current route, store the current state
                    this.saveCurrentHeaderState()
                }
            })
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    setHeaderState(showLight: boolean, hideHeader: boolean = false): void {
        this.hideHeader.next(hideHeader)
        this.showLightIcons.next(showLight)
        this.saveCurrentHeaderState()
    }
    
    saveCurrentHeaderState(): void {
        const currentUrl = this.router.url
        
        this.routeHeaderState[currentUrl] = {
            hideHeader: this.hideHeader.getValue(),
            showLightIcons: this.showLightIcons.getValue()
        }
    }
}