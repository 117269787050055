import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'file-dropzone',
  templateUrl: './file-dropzone.component.html',
  styleUrl: './file-dropzone.component.scss'
})
export class FileDropzoneComponent implements OnInit {
    @Input() form!: FormGroup
    @Input() inputName: string = 'file'
    @Input() fileType: string = 'image'
    @Input() label: string = ''
    @Input() maxFileSize: number = 5 //Default 5 MB
    
    @Output() doFileOutput: EventEmitter<File|null> = new EventEmitter<File|null>()
    @Output() fileAdded: EventEmitter<null> = new EventEmitter<null>()
    
    @ViewChild('fileInput') fileInput!: ElementRef
    
    private dragCounter: number = 0
    private file: File | null = null
        
    dragEntered: boolean = false
    imgPreviewUrl: string | null = null
        
    constructor() { }

    ngOnInit(): void {
        if (!this.form || !this.form.controls[this.inputName]) {
            throw new Error(`Form control with name ${this.inputName} does not exist in the provided form group.`);
        }
    }
    
    onClick(): void {
        this.fileInput.nativeElement.click()
    }
    
    onDragOver(e: DragEvent): boolean {
        e.preventDefault()
        e.stopPropagation()
        return false
    }
    
    onDragEnter(e: DragEvent): void {
        e.preventDefault()
        e.stopPropagation()
        
        this.dragCounter++
        this.dragEntered = true
    }
    
    onDragEnd(e: DragEvent): boolean {
        e.preventDefault()
        e.stopPropagation()
        return false
    }
    
    onDragLeave(e: DragEvent): void {
        e.preventDefault()
        e.stopPropagation()
        
        this.dragCounter--
        
        if(this.dragCounter === 0) {
            this.dragEntered = false
        }
    }
    
    onDrop(e: DragEvent): void {
        e.stopPropagation()
        e.preventDefault()
        
        const files = e.dataTransfer?.files;
        this.dragCounter = 0;
        this.dragEntered = false;
    
        if (files && files.length > 0) {
            // If the drop contains files, handle them
            this.evaluateFile(files[0]);
        } else {
            // If no files are present, check for URLs
            const htmlData = e.dataTransfer?.getData('text/html');
            if (htmlData) {
                const doc = new DOMParser().parseFromString(htmlData, 'text/html');
                const imgElement = doc.querySelector('img');
                if (imgElement && imgElement.src) {
                    this.handleImageFromURL(imgElement.src);
                }
            }
        }
    }
    
    handleImageFromURL(imageUrl: string): void {
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                const file = new File([blob], 'image.jpg', { type: blob.type });
                this.evaluateFile(file); // Reuse the existing method to handle the file
            })
            .catch(error => console.error('Error fetching the image:', error));
    }
    
    fileChanged(e: any): void {
        const files = e.target.files
        
        if(!files || files.item.length < 1) {
            return
        }
        
        this.evaluateFile(files[0])
    }
    
    evaluateFile(file: File): void {
        this.file = file
        this.doFileOutput.emit(this.file)
        
        const control = this.form.controls[this.inputName]
        
        control.updateValueAndValidity()
        
        if(control.errors) {
            this.file = null
            this.resetImagePreview()
            this.doFileOutput.emit(this.file)
            return
        }
        
        this.doImageFileAddedEvent()
        this.updateImagePreview()
    }

    updateImagePreview() {
        if(!this.file || this.fileType != 'image') {
            return
        }
        
        const reader = new FileReader()
        reader.onload = (e) => {
            this.imgPreviewUrl = e.target?.result as string
        }
        
        reader.readAsDataURL(this.file)
    }
    
    resetImagePreview(): void {
        this.imgPreviewUrl = null
    }
    
    doImageFileAddedEvent(): void {
        this.fileAdded.emit()
    }
}
