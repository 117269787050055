<div class="modal-overlay" (click)="doCloseForm()">
    <div class="modal" stopPropagation>
        <div class="modal-header">
            <span class="close" (click)="doCloseForm()">X</span>
        </div>
        <div class="modal-content">
            <iframe src="https://formless.ai/c/lSep6UZ9p8A6" class="formless-embed" width="100%" height="600px" loading="lazy" allow="microphone" style="border: 0; display: block"></iframe>
            <script src="https://embed.formless.ai/embed.js" async></script>
        </div>
    </div>
</div>