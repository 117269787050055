import { HttpParams } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges, OnDestroy, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, Subscription, catchError, debounceTime, map, of, switchMap, take } from 'rxjs';
import { BusinessAPIService, FilterAPIService } from 'src/app/_api-services';
import { FilterType } from 'src/app/_enums';
import { EntityHelper } from 'src/app/_helpers';
import { Business, BusinessCategory, Entity } from 'src/app/_models';
import { ModalService } from 'src/app/_services';
import { AccountNavigationService } from 'src/app/_services/account-navigation.service';

@Component({
  selector: 'business-add',
  templateUrl: './business-add.component.html',
  styleUrl: './business-add.component.scss'
})
export class BusinessAddComponent implements OnDestroy, OnChanges, OnInit {
    @Input() business: Business | null = null
    @Output() changeStep: EventEmitter<number> = new EventEmitter<number>()
    @Output() eventCreation: EventEmitter<FormData> = new EventEmitter<FormData>()
    
    @ViewChild('businessLogoImageInput') businessLogoImageInput!: ElementRef
    @ViewChild('businessPromoImageInput') businessPromoImageInput!: ElementRef

	private subscriptions: Subscription = new Subscription()
    private logoDragCounter: number = 0
    private promoDragCounter: number = 0 
    
    public readonly filterType: FilterType = FilterType.Business
    
	addForm!: FormGroup
	id: string | null = null
    logoDragEntered: boolean = false
    logoDropped: boolean = false
    businessLogoImage: File | null = null
    logoPreviewUrl: string = ''
    promoDragEntered: boolean = false
    promoDropped: boolean = false
    businessPromoImage: File | null = null
    promoPreviewUrl: string = ''
    dropdownItems: Entity[] | null = null

    busCategories: BusinessCategory[] = []
    subCategories: BusinessCategory[] = []


	constructor(
		private fb: FormBuilder,
        private businessAPIService: BusinessAPIService,
		private route: ActivatedRoute,
		private router: Router,
		private modalService: ModalService,
        private accountNavigationService: AccountNavigationService,
        private gtmService: GoogleTagManagerService,
        private filterAPIService: FilterAPIService,
        private entityHelper: EntityHelper,
	) {
        this.id = this.route.snapshot.paramMap.get("id") || ''
        this.formSetup()
        
        this.modalService.activateFooterBtn()
        this.modalService.updateFooterBtnTxt('Create Business')
        
        this.subscriptions.add(
            this.modalService.doFooterAction.subscribe( action => {
                this.submit()
            })
        )
        
        this.subscriptions.add(
            this.businessAPIService.getBusinessCategories().subscribe(res=>{
                this.busCategories = res
            })    
        )
	}

    ngOnChanges(changes: SimpleChanges): void {
        this.business = changes['business'].currentValue
    }
    
	ngOnInit(): void {
        this.formSetup()
    }

	ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    getFormValidationErrors(form: FormGroup): { [key: string]: any } {
        const errors: { [key: string]: any } = {}
      
        Object.keys(form.controls).forEach((key) => {
            const controlErrors = form.get(key)?.errors
            if (controlErrors) {
                errors[key] = controlErrors
            }
        
            // If the control is a FormGroup or FormArray, recursively call the function
            const control = form.get(key)
            if (control instanceof FormGroup) {
                errors[key] = this.getFormValidationErrors(control)
            }
        })
      
        return errors
    }
    
    get name() {
        return this.addForm?.get('name')
    }

    get categories() {
        return this.addForm?.get('categories')
    }
    
    get summary() {
        return this.addForm?.get('summary')
    }
    
    get email() {
        return this.addForm?.get('email')
    }
    
    get phone() {
        return this.addForm?.get('phone')
    }
    
    get website() {
        return this.addForm?.get('website')
    }
    
    get street() {
        return this.addForm?.get('street')
    }
    
    get city() {
        return this.addForm?.get('city')
    }
    
    get state() {
        return this.addForm?.get('state')
    }
    
    get zip() {
        return this.addForm?.get('zip')
    }
    
    get showAddress() {
        return this.addForm?.get('show_address')
    }

	get logo() {
        return this.addForm?.get('logo')
    }

	get promoImage() {
        return this.addForm?.get('promo_image')
    }
    
	get hours() {
		return this.addForm?.get('hours')
	}

    get claimed() {
		return this.addForm?.get('claimed')
	}

    formSetup(): void {
		this.addForm = this.fb.group({
			name: new FormControl("", {
                validators: [Validators.required],
                asyncValidators: [this.checkIfNameExists.bind(this)],
            }),
            categories: new FormControl("", [Validators.required]),
			summary: new FormControl("", [Validators.required]),
            email: new FormControl("", [Validators.required, Validators.email]),
			phone: new FormControl("", [Validators.required]),
			website: new FormControl(""),
			street: new FormControl("", [Validators.required]),
			city: new FormControl("", [Validators.required]),
			state: new FormControl("", [Validators.required]),
			zip: new FormControl("", [Validators.required, Validators.pattern(/\d{5}/)]),
            show_address: new FormControl(""),
			logo: new FormControl(null),
			promo_image: new FormControl(null),
			hours: new FormControl(""),
            claimed: new FormControl(true),
		})
  	}

	formTakeDown(): void {
		this.modalService.close()
	}

    onLogoDrageEnter(e: DragEvent): void {
        e.preventDefault()
        e.stopPropagation()
        this.logoDragCounter++
        this.logoDragEntered = true
    }

    onPromoDrageEnter(e: DragEvent): void {
        e.preventDefault()
        e.stopPropagation()
        this.promoDragCounter++
        this.promoDragEntered = true
    }
    
    onLogoDragLeave(e: DragEvent): void {
        this.logoDragCounter--
        
        if(this.logoDragCounter === 0) {
            this.logoDragEntered = false
        }
    }

    onPromoDragLeave(e: DragEvent): void {
        this.promoDragCounter--
        
        if(this.promoDragCounter === 0) {
            this.promoDragEntered = false
        }
    }
    
    onDragOver(e: DragEvent): boolean {
        e.preventDefault()
        e.stopPropagation()
        return false
    }
    
    onDragEnd(e: DragEvent): boolean {
        e.preventDefault()
        e.stopPropagation()
        return false
    }
    
    onLogoDrop(e: DragEvent): void {
        e.preventDefault()
        e.stopPropagation()
        
        this.logoDropped = true
        
        
        const files = e.dataTransfer?.files
        this.logoDragCounter = 0
        this.logoDragEntered = false

        if(!files || files.item.length < 1) {
            this.logoDropped = false
            return
        }
        
        this.businessLogoImage = files[0]
        
        if(!this.isLogoValidImage()) {
            return 
        }
        
        this.updateLogoImagePreview()
    }

    onPromoDrop(e: DragEvent): void {
        e.preventDefault()
        e.stopPropagation()
        
        this.promoDropped = true

        const files = e.dataTransfer?.files
        this.promoDragCounter = 0
        this.promoDragEntered = false

        if(!files || files.item.length < 1) {
            this.promoDropped = false
            return
        }
        
        this.businessPromoImage = files[0]
        
        if(!this.isPromoValidImage()) {
            return 
        }
        
        this.updatePromoImagePreview()
    }
    
    logoImageChanged(e: any): void {
        const files = e.target.files
        
        if(!files || files.item.length < 1) {
            return
        }
        
        this.businessLogoImage = files[0]
        
        if(!this.isLogoValidImage()) {
            return
        }
        
        this.updateLogoImagePreview()
    }

    promoImageChanged(e: any): void {
        const files = e.target.files
        
        if(!files || files.item.length < 1) {
            return
        }
        
        this.businessPromoImage = files[0]
        
        if(!this.isPromoValidImage()) {
            return
        }
        
        this.updatePromoImagePreview()
    }
    
    updateLogoImagePreview(): void {
        if(!this.businessLogoImage) {
            return
        }
        
        const reader = new FileReader()
        reader.onload = (business) => {
            this.logoPreviewUrl = business.target?.result as string
        }
        
        reader.readAsDataURL(this.businessLogoImage)
    }

    updatePromoImagePreview(): void {
        if(!this.businessPromoImage) {
            return
        }
        
        const reader = new FileReader()
        reader.onload = (business) => {
            this.promoPreviewUrl = business.target?.result as string
        }
        
        reader.readAsDataURL(this.businessPromoImage)
    }
    
    isLogoValidImage(): boolean {
        if(!this.businessLogoImage) {
            return this.setLogoImageIsValid(false)
        }

        return this.setLogoImageIsValid(this.imageTypeValid(this.businessLogoImage.type))
    }

    isPromoValidImage(): boolean {
        if(!this.businessPromoImage) {
            return this.setPromoImageIsValid(false)
        }
         
        return this.setPromoImageIsValid(this.imageTypeValid(this.businessPromoImage.type))
    }
    
    imageTypeValid(type: string): boolean {
        const validMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']

        if(validMimeTypes.includes(type)) {
            return true
        }
        
        return false
    }
    
    setLogoImageIsValid(valid: boolean): boolean {
        this.addForm.get('logoImage')?.setErrors({ invalidType: valid })
        this.addForm.get('logoImage')?.updateValueAndValidity()
        return valid
    }

    setPromoImageIsValid(valid: boolean): boolean {
        this.addForm.get('promoImage')?.setErrors({ invalidType: valid })
        this.addForm.get('promoImage')?.updateValueAndValidity()
        return valid
    }

    setBusinessLogoImage(): void {
        this.businessLogoImageInput.nativeElement.click()
    }

    setBusinessPromoImage(): void {
        this.businessPromoImageInput.nativeElement.click()
    }

    checkIfNameExists(control: AbstractControl): Observable<ValidationErrors | null> {
        return control.valueChanges.pipe(
            debounceTime(300),
            switchMap((value) => {
                if (!value) {
                    return of(null)
                }
                
                let str = value.toString()
                
                return this.businessAPIService.getBusinessByName(str.trim()).pipe(
                    map((response) => (response ? { existingBusiness: response } : null)),
                    catchError(() => of(null))
                )
            }),
            take(1)
        )
    }
    
    
    setBusiness(item: Entity): void {
        let b: any = { handle: item.handle }
        this.router.navigate([this.getBusinessLink(b as Business)])
        this.modalService.close()
    }

    getBusinessLink(business: Business) {
        return `/business/${business.handle}/details`
    }
    
    submit(): void {
		if(!this.addForm?.valid) {
			this.addForm?.markAllAsTouched()
			return
		}

        this.business = this.addForm?.value
        if(!this.business) {
            return
        }
        
        this.modalService.doLoading.next(true)
        this.business.phone.replace(/\D/g, '')
        this.business.email?.toLocaleLowerCase()

        const formData = new FormData()
        
        if(this.business.id) {
            formData.append('id', this.business.id)
        }
        
        formData.append('name', this.business.name)
        formData.append('email', this.business.email)
        formData.append('summary', this.business.summary)
        formData.append('street', this.business.street)
        formData.append('city', this.business.city)
        formData.append('state', this.business.state)
        formData.append('zip', this.business.zip)
        formData.append('phone', this.business.phone)
        formData.append('claimed', this.business.claimed.toString())
        formData.append("show_address", this.business.show_address.toString())
        
        if(this.business.website) {
            formData.append('website', this.business.website)
        }
        
        if(this.business.hours) {
            formData.append('hours', JSON.stringify(this.business.hours))
        }
        
        if(this.businessLogoImage) {
            formData.append('logo', this.businessLogoImage, this.businessLogoImage?.name)
        }
        
        if(this.businessPromoImage) {
            formData.append('promo_image', this.businessPromoImage, this.businessPromoImage?.name)
        }

        let cats = this.busCategories.filter((cat: any)=>{
            return this.business?.categories == cat.id
        })

        if(cats.length>0){
            formData.append('categories', JSON.stringify(cats))
        }

		if(this.business) {
			this.subscriptions.add(
                this.businessAPIService.addBusiness(formData).subscribe( {
                    next: (b) => {
                        this.modalService.doLoading.next(false)
                        this.gtmService.pushTag({
                            event: 'business_created',
                            category: 'Form',
                            label: 'Create Business',
                            value: b,
                        })
                        this.formTakeDown()
                        this.accountNavigationService.addMyBusiness(b)
                        this.modalService.doLoading.next(false)
                        this.router.navigate([`/business/${b.handle}/details`])
                    },
                    error: (err) => {
                        this.modalService.doLoading.next(false)
                        this.modalService.setRequestFailure(err.error)
                    }
                })
            )

		}
	}
    
    closeModal() {
        this.modalService.close()
    }

}
