<div class="hive">
    <div class="flex flex-center">
        <img class="back" src="/assets/icon-back.svg" alt="icon back"  (click)="goBack()">
        <p class="text-center" *ngIf="hiveName">  {{ hiveName }}</p>
    </div>
    
    <div class="flex toolbar">
        <img src="/assets/icon-share.svg" alt="share icon">
        <img src="/assets/icon-search.svg" alt="search icon">
    </div>
</div>