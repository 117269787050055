import { query } from '@angular/animations'
import { Injectable } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router'
import { Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DetachedRouteHandleService {

    private readonly store: Map<any, DetachedRouteHandle>
    private shouldNotSaveNextRoute: boolean = false

    public readonly setting: Subject<string> 
    public readonly getting: Subject<string> = new Subject()

    constructor() {
        this.store = new Map()
        this.setting = new Subject()
    }

    private nextSetting(path: string): void {
        this.setting.next(path)
    }
    
    private nextGetting(path: string): void {
        this.getting.next(path)
    }

    public set(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        if(this.shouldNotSaveNextRoute) {
            this.shouldNotSaveNextRoute = false
            return
        }
        
        let path = this.configureRoute(route)
        this.store.set(path, handle)
        this.nextSetting(path)
    }

    public delete(route: ActivatedRouteSnapshot): void {
        let path = this.configureRoute(route)
        this.store.delete(path)
        this.nextSetting(path)
    }

    public get(route: ActivatedRouteSnapshot): DetachedRouteHandle | undefined {
        let path = this.configureRoute(route)
        this.nextGetting(path)
        return this.store.get(path)
    }

    public has(route: ActivatedRouteSnapshot): boolean {
        let path = this.configureRoute(route)
        return this.store.has(path)
    }

    public clear(): void {
        this.shouldNotSaveNextRoute = true
        this.store.clear()
    }
    
    public getPath(route: ActivatedRoute): string {
        return this.configureRoute(route.snapshot)
    }
    
    private configureRoute(route: ActivatedRouteSnapshot): string {
        let name = route.component?.toString() || ''
        
        const params = route.paramMap.keys
            .map(key => `${key}=${route.paramMap.get(key)}`)
            .join('&')
        
        
        const queryParams = Object.keys(route.queryParams)
            .map(key => `${key}=${route.queryParams[key]}`)
            .join('&')
        
        if(route.parent) {
            name += this.getParentRouteParams(route.parent)    
        }
        
        if(params) {
            name += `;${params}`
        }
        
        if(queryParams) {
            name += `?${queryParams}`
        }
        
        return name
    }
    
    private getParentRouteParams(route: ActivatedRouteSnapshot): string {
        let path = ''
        const params = route.paramMap.keys
            .map(key => `${key}=${route.paramMap.get(key)}`)
            .join('&')
        
        
        const queryParams = Object.keys(route.queryParams)
            .map(key => `${key}=${route.queryParams[key]}`)
            .join('&')
            
        if(params) {
            path += `;${params}`
        }
        
        if(queryParams) {
            path += `?${queryParams}`
        }
        
        return path
    }
}
