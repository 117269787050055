<div [ngClass]="{'modal-search': isModalSearch}" class="search-input-container" #searchContainer>
    <img src="/assets/icon-search.svg" alt="search icon">
    <input [ngClass]="{'modal-search': isModalSearch}" #searchInput type="text" [value]="searchString" placeholder="{{placeholderText}}" (keyup)="searchSuggestions($event)" (keydown)="stopCursorReset($event)">
    
    
    <div class="relative">
        <div *ngIf="loadedDropdownItems" class="suggestions">
            <div class="suggestion highlight" (click)="selectDropdownItem(searchString)">{{ searchString }}</div>
            <div *ngFor="let item of loadedDropdownItems" class="suggestion" (click)="selectDropdownItem(item)">
                <p>{{ item }}</p>
            </div>
        </div>
    </div>
</div>
