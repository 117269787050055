import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Config } from 'src/app/_models';

@Component({
  selector: 'image-slider',
  templateUrl: './image-slider.component.html',
  styleUrl: './image-slider.component.scss'
})
export class ImageSliderComponent implements OnChanges {
    @Input() images: string[] | undefined
    
    slides: { img: string }[] = []

    showDots: boolean = true;
    slideConfig: {} = {}
    
    constructor() {
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.slides = []
        this.images = changes['images'].currentValue
        this.setSlideImages()
    }
    
    setSlideImages(): void {
        if(this.images){
            for( let i of this.images) {
                this.slides.push({
                    img: i
                })
            }
            
            return
        } else{
            this.slides.push({
                img: '/assets/listings/default-product-image.jpeg'
            })
        }
        
        
        this.showDots = this.slides.length > 1 ? true : false
        this.setSlideConfig()
    }

    setSlideConfig(): void {
        this.slideConfig = {
            dots: this.showDots,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            arrows: false,
            //variableWidth: true,
            adaptiveHeight: true,
            centerPadding: '0px',
        }
    }
    
    
}
