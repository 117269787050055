import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {

	constructor() {}

  	transform(start: string, end: string, type: string = ""): string {

		const startDate = new Date(start)
		const endDate = new Date(end)

		let diff = endDate.getTime() - startDate.getTime()
		
		diff = diff < 0 ? 0 : diff

		const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))
		diff -= years * (1000 * 60 * 60 * 24 * 365)

		const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30))
		diff -= months * (1000 * 60 * 60 * 24 * 30)

		const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7))
		diff -= weeks * (1000 * 60 * 60 * 24 * 7)

		const days = Math.floor(diff / (1000 * 60 * 60 * 24))
		diff -= days * (1000 * 60 * 60 * 24)

		const hours = Math.floor(diff / (1000 * 60 * 60))
		diff -= hours * (1000 * 60 * 60)

		const minutes = Math.floor(diff / (1000 * 60))
		diff -= minutes * (1000 * 60)

		const seconds = Math.floor(diff / 1000)
		diff -= seconds * 1000

		const milliseconds = diff
	
		if (years > 0) {
			if (type=="expanded") {
				return (years > 1) ? `${years} years ago`: `${years} year ago`
			}
			return `${years}y`
		} 
		if (months > 0) {
			if (type=="expanded") {
				return (months > 1) ? `${months} months ago`: `${months} month ago`
			}
			return `${months}mo`
		} 
		if (weeks > 0) {
			if (type=="expanded") {
				return (weeks > 1) ? `${weeks} weeks ago`: `${weeks} week ago`
			}
			return `${weeks}w`
		}
		if (days > 0) {
			if (type=="expanded") {
				return (days > 1) ? `${days} days ago`: `${days} day ago`
			}
			return `${days}d`
		} 
		if (hours > 0) {
			if (type=="expanded") {
				return (hours > 1) ? `${hours} hours ago`: `${hours} hour ago`
			}
			return `${hours}h`
		} 
		if (minutes > 0) {
			if (type=="expanded") {
				return (minutes > 1) ? `${minutes} minutes ago`: `${minutes} minute ago`
			}
			return `${minutes}m`
		}
		if (type=="expanded") {
			return `just now`
		}
		return `Now`;
	}
}