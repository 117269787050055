import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FilterAPIService } from 'src/app/_api-services';
import { FilterType } from 'src/app/_enums';

@Component({
  selector: 'search-products',
  templateUrl: './search-products.component.html',
  styleUrl: './search-products.component.scss'
})
export class SearchProductsComponent implements OnDestroy{
    @Output() products: EventEmitter<any[] | null> = new EventEmitter<any[] | null>

    private subscriptions: Subscription = new Subscription()
    private readonly dataSearchCache = new Map<string, Observable<any>>()
    private readonly searchResultsCache = new Map<string, Observable<any>>()
    searchString: string | null = null

    constructor(
        private filterAPIService: FilterAPIService,
    )
    {}

    searchSuggestions(event: KeyboardEvent) {
        let target = event.target as HTMLInputElement
        this.searchString = target.value
        this.getRecords(this.searchString)
    }

    getRecords(searchString: string): void {
        let params = new HttpParams();
        params = params.set('search_string', searchString)
        
        if(searchString) {
            this.filterAPIService.getFilteredRecords(params, FilterType.Product).subscribe({
                next: (items) => {
                    this.products.emit(items["filtered_records"])
                },
                error: () => {
                    console.log('filterAPIService.getFilteredRecords returned an error')
                }
            })
            return
        } 
        
        
        this.products.emit(null)    
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
