import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Business } from 'src/app/_models';

@Component({
  selector: 'business-node-card',
  templateUrl: './business-node-card.component.html',
  styleUrl: './business-node-card.component.scss'
})
export class BusinessNodeCardComponent {
    @Input() business!: Business
    @Input() relationship: string | null = null
    @Input() addBtnTxt: string = 'Add'
    @Input() noBoundary: boolean = false
    
    @Output() toggled: EventEmitter<string> = new EventEmitter<string>()
    
    added: boolean = false
    
    toggleAdd(): void {
        this.added = !this.added
        this.toggled.emit(this.business.id)    
    }
}
