<div *ngIf="!showManual && !showScanner && !showResults">
    <search-products (products)="setProducts($event)"></search-products>

    <div *ngIf="!products">
        <button class="btn btn-primary btn-rounded local-btn" (click)="toggleScanner()">Scan</button>
        <button class="btn btn-primary btn-rounded local-btn" (click)="toggleManual()">Add Product Manually</button>
    </div>
    <div *ngIf="products">
        <section>
            <div class="container products">
                <product-grid-item *ngFor="let product of products" [product]="product" [offering]="true" (offerProduct)="selectSearchProduct(product)" class="list-product"></product-grid-item>
            </div>
        </section>
    </div>
</div>

<product-scanner (results)="onResults($event)" *ngIf="showScanner"></product-scanner>
<product-create *ngIf="showManual" (offerExisting)="selectSearchProduct($event)"></product-create>
<product-results [product]="results" *ngIf="showResults"></product-results>

<button *ngIf="showScanner" class="btn btn-primary btn-rounded local-btn" (click)="close()">Exit Scanner</button>
