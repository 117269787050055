import { Component, HostListener, Input } from '@angular/core';
import { ClipboardHelper } from 'src/app/_helpers';
import { OptionDropdown } from 'src/app/_models';

@Component({
    selector: 'options-dropdown',
    templateUrl: './options-dropdown.component.html',
    styleUrls: ['./options-dropdown.component.scss']
})
export class OptionsDropdownComponent {
    @Input() options: OptionDropdown[] = []

    isDropdownOpen = false
    
    constructor(public cbh: ClipboardHelper) {}

    toggleModal() {
        this.isDropdownOpen = !this.isDropdownOpen
    }

    onOptionClick(action: () => void, keepOpen: boolean) {
        action()
        
        if(!keepOpen) {
            this.toggleModal()  
        }
        
    }
    
    
}