import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Business, Config, Hive, HubhiveEvent, Product, User } from '../_models'
import { Observable } from 'rxjs'
import { SearchType } from '../_enums'

@Injectable({ providedIn: 'root' })
export class SearchAPIService {
    constructor(private http: HttpClient) { }
    
    list(type:string, search: string, id?: string, limit: number = 0): Observable<any[]> {
        let results!: Observable<Business[]> | Observable<Hive[]> | Observable<Product[]> | Observable<User[]> | Observable<HubhiveEvent[]>
        
        let params: any = {
            limit: limit
        }
        
        if(search) {
            params['search'] = search
        }
        
        switch(type) {
            case SearchType.Business:
                results = this.http.get<Business[]>(`${Config.apiUrl}/business`, { params: params } )
            break

            case SearchType.Event:
                results = this.http.get<HubhiveEvent[]>(`${Config.apiUrl}/event`, { params: params } )
            break
            
            case SearchType.Hive:
                results = this.http.get<Hive[]>(`${Config.apiUrl}/hive`, { params: params } )
            break
            
            case SearchType.User:
                results = this.http.get<User[]>(`${Config.apiUrl}/user/hive-contacts`, { params: params } )
            break
            
            case SearchType.HiveBusinesses:
                if(!id) {
                    throw new Error('handle is required when searching within a hive')
                }
                results = this.http.get<Business[]>(`${Config.apiUrl}/business/hive/${id}`, { params: params } )
            break
            
            case SearchType.HiveProducts:
                if(!id) {
                    throw new Error('handle is required when searching within a hive')
                }
                results = this.http.get<Business[]>(`${Config.apiUrl}/business/hive/${id}`, { params: params } )
            break
            
            case SearchType.HiveUsers:
                if(!id) {
                    throw new Error('handle is required when searching within a hive')
                }
                results = this.http.get<Business[]>(`${Config.apiUrl}/business/hive/${id}`, { params: params } )
            break

            case SearchType.HivePeers:
                if(!id) {
                    throw new Error('id is required when searching within a hive')
                }
                results = this.http.get<User[]>(`${Config.apiUrl}/user/hive/${id}`, { params: params } )
                console.log(results)
            break
            
            case SearchType.BusinessProducts:
                if(!id) {
                    throw new Error('id is required when searching within a business')
                }
                results = this.http.get<Business[]>(`${Config.apiUrl}/listing/business/${id}/products`, { params: params } )
            break
            
            case SearchType.UserHives:
                results = this.http.get<Hive[]>(`${Config.apiUrl}/hive/user/search`, { params: params } )
            break
            
            case SearchType.Product:
                results = this.http.get<Product[]>(`${Config.apiUrl}/product/search`, { params: params } )
            break
        }
        
        return results
    }
    
    search(type: string, term: string, id?: string): Observable<string[]> {
        let results!: Observable<string[]>

        switch(type) {
            case SearchType.Business:
                results = this.http.get<string[]>(`${Config.apiUrl}/business/search`, { params: { search: term } } )
            break
            
            case SearchType.Event:
                results = this.http.get<string[]>(`${Config.apiUrl}/event/search`, { params: { search: term } } )
            break

            case SearchType.Hive:
                results = this.http.get<string[]>(`${Config.apiUrl}/hive/search`, { params: { search: term } } )
            break
            
            case SearchType.User:
                results = this.http.get<string[]>(`${Config.apiUrl}/user/hive-peers/search`, { params: { search: term } } ) 
            break
            
            case SearchType.HiveBusinesses:
                if(!id) {
                    throw new Error('handle is required when searching within a hive')
                }
                results = this.http.get<string[]>(`${Config.apiUrl}/business/hive/${id}/search`, { params: { search: term } } )
            break
            
            case SearchType.HiveProducts:
                if(!id) {
                    throw new Error('handle is required when searching within a hive')
                }
                results = this.http.get<string[]>(`${Config.apiUrl}/products/hive/${id}/search`, { params: { search: term } } )
            break
            
            case SearchType.HiveUsers:
                if(!id) {
                    throw new Error('handle is required when searching within a hive')
                }
                results = this.http.get<string[]>(`${Config.apiUrl}/business/hive/${id}/search`, { params: { search: term } } )
            break
            
            case SearchType.BusinessProducts:
                if(!id) {
                    throw new Error('id is required when searching within a business')
                }
                results = this.http.get<string[]>(`${Config.apiUrl}/products/business/${id}/search`, { params: { search: term } } )
            break
            
            case SearchType.UserHives:
                results = this.http.get<string[]>(`${Config.apiUrl}/hive/user/search/suggestions`, { params: { search: term } } )
            break
            
            case SearchType.Product:
                results = this.http.get<string[]>(`${Config.apiUrl}/product/search/suggestions`, { params: { search: term } } )
            break
        }
        
        return results
    }
}