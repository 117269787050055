import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Observable, Subscription, shareReplay } from 'rxjs';
import { SearchType } from 'src/app/_enums';
import { Business, Hive, Product, User } from 'src/app/_models';
import { SearchAPIService } from 'src/app/_api-services/search-api.service';
import { HttpParams } from '@angular/common/http'
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('searchInput') input!: ElementRef<HTMLInputElement>
    @ViewChild('searchContainer') searchContainer!: ElementRef;
    
    @Input() searchType!: SearchType
    @Input() placeholderText: string = 'Search'
    @Input() searchId: string | undefined
    @Input() isModalSearch: boolean = false
    @Output() recordOutput: EventEmitter<any[]|null> = new EventEmitter<any[]|null>
    
    private subscriptions: Subscription = new Subscription()
    private readonly dataSearchCache = new Map<string, Observable<any>>()
    private readonly dropdownSearchCache = new Map<string, Observable<any>>()
    private clickListener!: () => void
    
    loadedDropdownItems: string[] | null = null
    currentHighlightIndex: number = 0
    searchString: string = ''
    
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private searchAPIService: SearchAPIService,
        private elem: ElementRef,
        private renderer: Renderer2
    ) {
    }
    
    ngOnInit(): void {
        if(this.searchType == null || this.searchType == undefined) {
            throw new Error('Input variable "searchType" must be set!')
        }
        
        this.subscriptions.add(
            this.route.queryParams.subscribe(url => {
                const params = new URLSearchParams(url['params'])
    
                const searchParam = params.get('search')
                if(searchParam){
                    this.searchString = searchParam
                }
            })    
        )
    }
    
    ngAfterViewInit(): void {
        // Check if a click happens outside of search container and close dropdown if it's true
        this.clickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
            const clickedInside = this.searchContainer.nativeElement.contains(event.target)
            
            if (!clickedInside) {
                this.loadedDropdownItems = null
            }
        })
    }
    
    ngOnDestroy(): void {
        this.clickListener()
        this.subscriptions.unsubscribe()
    }
    
    highlightDropdownItem(): void {
        let highlightedSuggestion = this.elem.nativeElement.querySelector('.highlight')
        let suggestions = this.elem.nativeElement.querySelectorAll('.suggestion')
        
        if(highlightedSuggestion) {
            highlightedSuggestion.classList.remove('highlight')
        }
        
        if(!suggestions.length) {
            return
        }
        
        suggestions[this.currentHighlightIndex].classList.add('highlight')
        
        this.scrollToItem(suggestions)
    }
    
    scrollToItem(suggestions: any): void {
        suggestions[this.currentHighlightIndex].scrollIntoView({ block: 'nearest' })
    }
    
    moveHighlightDown(): void {
        if(!this.loadedDropdownItems) {
            return
        }

        if(this.currentHighlightIndex == this.loadedDropdownItems.length) {
            this.currentHighlightIndex = 0
        } else {
            this.currentHighlightIndex++
        }
        
        this.highlightDropdownItem()
    }
    
    moveHighlightUp(): void {
        if(!this.loadedDropdownItems) {
            return
        }
        
        if(this.currentHighlightIndex == 0) {
            this.currentHighlightIndex = this.loadedDropdownItems.length
        } else {
            this.currentHighlightIndex--
        }
        
        this.highlightDropdownItem()
    }
    
    selectHighlightedDropdownItem(): void {
        if(!this.loadedDropdownItems) {
            return
        }
        
        let searchTerm: string
        
        if(this.currentHighlightIndex == 0) {
            searchTerm = this.searchString
        } else {
            // need + 1 because the current
            searchTerm = this.loadedDropdownItems[this.currentHighlightIndex - 1]
        }
        
        this.selectDropdownItem(searchTerm)
        this.currentHighlightIndex = 0
    }
    
    searchSuggestions(event: KeyboardEvent) {
        let target = event.target as HTMLInputElement
        this.searchString = target.value
        
        // Return/Enter key pressed
        if(event.key == "Enter") {
            if(this.loadedDropdownItems) {
                this.selectHighlightedDropdownItem()
                return
            }
            
            this.search(this.searchString)
            return
        }

        if(this.searchString.length < 3) {
            this.resetDropdown()
            this.doOutputEvent(null)
            return
        }
        
         // Up arrow key pressed
         if(event.key == "ArrowUp") {
            this.moveHighlightUp()
            return
        }
        
        // Down arrow key pressed
        if(event.key == "ArrowDown") {
            this.moveHighlightDown()
            return
        }
        
        // Escape key pressed
        if(event.key == "Escape") {
            this.resetDropdown()
            return
        }
        
        const cacheKey = this.searchString
        const obs = this.dropdownSearchCache.get(cacheKey)
        
        if(!obs) {
            if(!this.searchId) {
                this.dropdownSearchCache.set(
                    cacheKey,
                    this.searchAPIService.search(this.searchType, this.searchString).pipe(shareReplay(1))
                )
            } else {
                this.dropdownSearchCache.set(
                    cacheKey,
                    this.searchAPIService.search(this.searchType, this.searchString, this.searchId).pipe(shareReplay(1))
                )
            }
            
            this.subscriptions.add(
                this.dropdownSearchCache.get(cacheKey)?.subscribe({
                    next: (suggestions) => {
                        this.loadedDropdownItems = suggestions
                        this.highlightBackToTop()
                    },
                    error: (err) => {
                        console.log(err)
                    }
                })
            )
        } else {
            this.subscriptions.add(
                obs.subscribe({
                    next: (items) => { 
                        this.loadedDropdownItems = items
                        this.highlightBackToTop()
                    },
                    error: (err) => {
                        this.resetDropdown()
                    }
                })
            )
        }
    }
    
    search(searchString: string): void {
        const cacheKey = searchString
        const obs = this.dataSearchCache.get(cacheKey)
        
        if (!this.isModalSearch) this.updateUrl(searchString)
        

        this.doOutputEvent(null)

        if(!this.dataSearchCache.get(cacheKey)) {
            if(!this.searchId) {
                this.dataSearchCache.set(
                    cacheKey,
                    this.searchAPIService.list(this.searchType, searchString).pipe(shareReplay(1))
                )    
            } else {
                this.dataSearchCache.set(
                    cacheKey,
                    this.searchAPIService.list(this.searchType, searchString, this.searchId).pipe(shareReplay(1))
                )  
            }
        }
        
        this.subscriptions.add(
            this.dataSearchCache.get(cacheKey)!.subscribe({
                next: (records) => { 
                    records = records ? records : []
                    this.doOutputEvent(records)
                },
                error: (err) => {
                    console.log(err)
                    this.doOutputEvent(null)
                }
            })
        )
    }

    updateUrl(searchString: string): void {
        let params = this.prepareUrlParams(searchString)
    
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { params: params },
          queryParamsHandling: 'merge',
        })
    }

    prepareUrlParams(searchString: string) {
        let params = new HttpParams()
        params = params.set("search", searchString)

        return params
      }
    
    selectDropdownItem(searchTerm: string): void {
        this.input.nativeElement.value = searchTerm
        this.resetDropdown()
        this.search(searchTerm)
    }
    
    resetDropdown(): void {
        this.loadedDropdownItems = null
        this.currentHighlightIndex = 0
    }
    
    highlightBackToTop(): void {
        this.currentHighlightIndex = 0
        this.highlightDropdownItem()
    }
    
    stopCursorReset(event: KeyboardEvent): void {
        if(event.key == "ArrowUp" || event.key == "ArrowDown") {
            event.preventDefault()
        }
    }
    
    doOutputEvent(records: any[]|null): void {
        let output: any[]|null = records
        
        switch(this.searchType) {
            case SearchType.Hive:
            case SearchType.UserHives:
                output = records as Hive[]
            break
            
            case SearchType.Business:
                output = records as Business[]
            break
            
            case SearchType.User:
                output = records as User[]
            break

            case SearchType.Event:
                output = records as Event[]
            break
            
            case SearchType.Product:
                output = records as Product[]
            break
        }
        
        this.recordOutput.emit(output)
    }
}
