import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'splitCurrency'
})
export class SplitCurrencyPipe implements PipeTransform {

    transform(value: string | null): string {
        if(!value) {
            return ''
        }
        
        const parts = value.split('.')
        
        if (parts.length === 2) {
            const mainPart = parts[0]
            const fractionalPart = parts[1]
            return `${mainPart}<span class="fractional">${fractionalPart}</span>`
        }
        
        return value
    }

}
