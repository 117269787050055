import { ChangeDetectorRef, Injectable, TemplateRef } from '@angular/core';
import { ModalComponent } from '../shared/common/components/modal/modal.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
    private modalComponent: ModalComponent | null = null
    goBack: Subject<boolean> = new Subject<boolean>()
    doFooterAction: Subject<boolean> = new Subject<boolean>()
    doLoading: Subject<boolean> = new Subject<boolean>()
    doSuccess: Subject<void> = new Subject<void>()
    doFailure: Subject<string> = new Subject<string>()
    doRequestReset: Subject<void> = new Subject<void>()
    
    
    bindModalComponent(component: ModalComponent): void {
        this.modalComponent = component
    }
    
    open(title: string, content: TemplateRef<any>, context?: any): void {
        if(this.modalComponent) {
            this.modalComponent.open(title, content, context)
        } else {
            console.log('modal not bound')
        }
    }
    
    updateTitle(title: string): void {
        if(this.modalComponent) {
            this.modalComponent.setTitle(title)
        }
    }
    
    updateFooterBtnTxt(txt: string): void {
        if(this.modalComponent) {
            this.modalComponent.footerBtnTxt = txt
        }
    }
    
    activateBackButton(): void {
        if(this.modalComponent) {
            this.modalComponent.backBtnActivated = true
        }
    }
    
    deactivateBackBtn(): void {
        if(this.modalComponent) {
            this.modalComponent.backBtnActivated = false
        }
    }
    
    activateFooterBtn(): void {
        if(this.modalComponent) {
            this.modalComponent.footerBtnActivated = true
        }
    }
    
    deactivateFooterBtn(): void {
        if(this.modalComponent) {
            this.modalComponent.footerBtnActivated = false
        }
    }
    
    showLoader(): void {
        if(this.modalComponent) {
            this.modalComponent.showLoader = true
        }
    }
    
    hideLoader(): void {
        if(this.modalComponent) {
            this.modalComponent.showLoader = false
        }
    }
    
    setRequestSuccess(): void {
        this.doSuccess.next()
    }
    
    setRequestFailure(errorMessage: string = ""): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.setRequestFailure(errorMessage)
    }
    
    setRequestReset(): void {
        if(!this.modalComponent) {
            return
        }
        
        this.modalComponent.resetRequestStatus()
    }
    
    close(): void {
        if(this.modalComponent) {
            this.deactivateBackBtn()
            this.deactivateFooterBtn()
            this.updateTitle("")
            this.setRequestReset()
            this.modalComponent.close()
        }
    }
}
