import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {

   constructor() {}

  transform(start: string, end: string | null): string {
    const startDate = new Date(start);
    
    let formattedStartDate = startDate.toLocaleString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      });
      
    if(!end){
        return formattedStartDate
    }

    const endDate = new Date(end);

    let formattedEndDate = endDate.toLocaleString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        });
    
    return `${formattedStartDate} - ${formattedEndDate}`;
  }
    
}