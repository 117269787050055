import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { Business } from '../_models'

@Injectable({ providedIn: 'root' })
export class BusinessService {
    currentBusiness: BehaviorSubject<Business|null> = new BehaviorSubject<Business|null>(null)
    userIsOwner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    userClaimed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    showInfoForm: Subject<boolean> = new Subject<boolean>()
    
    constructor() {}
}