<form [formGroup]="addForm">
    <add-member-search [searchType]="searchType" (recordOutput)="setMembers($event)"></add-member-search>

    <div class="tab">
        <button class="tablinks" (click)="setActiveTab('Contacts')" [class.active]="isActive('Contacts')">Contacts</button>
        <button class="tablinks" (click)="setActiveTab('Hives')" [class.active]="isActive('Hives')">Hives</button>
    </div>
</form>

<div *ngIf="isActive('Contacts')" id="Contacts" class="tabcontent">
    <div class="members" *ngIf="members || unregisteredEmails.length">
        <div *ngFor="let unregisteredEmail of unregisteredEmails" class="flex space-between flex-center member">
            <unregistered-user-card [email]="unregisteredEmail"></unregistered-user-card>
            <button class="add-btn btn btn-secondary btn-rounded" (click)="toggleMemberSelection(unregisteredEmail)" [ngClass]="{'added': selectedMemberIds.includes(unregisteredEmail)}">
                Invite
            </button>
        </div>
        <div class="members" *ngIf="members">
            <div *ngFor="let member of members" class="flex space-between flex-center member">
                <user-card  [tileOnly]="true" [user]="member" [relationship]="'In a hive you\'re in'"></user-card>
                <button class="add-btn btn btn-secondary btn-rounded" (click)="toggleMemberSelection(member.id)" [ngClass]="{'added': selectedMemberIds.includes(member.id)}">
                    Invite
                </button>
            </div>
        </div>
    </div>
    <p *ngIf="!members">No Members found</p>
</div>

<div *ngIf="isActive('Hives')" id="Hives" class="tabcontent">
    <p *ngIf="!hives">No Hives found</p>
    <div class="members" *ngIf="hives">
        <div *ngFor="let hive of hives" class="flex space-between flex-center member">
            <hive-card [hive]="hive" [type]="'tile'"></hive-card>
            <button class="add-btn btn btn-secondary btn-rounded" (click)="toggleHiveSelection(hive.id)" [ngClass]="{'added': selectedHiveIds.includes(hive.id)}">
                Invite
            </button>
        </div>
    </div>
</div>
