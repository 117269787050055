import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class ClipboardHelper {
    copiedMessageVisable = false
    shareMessage = ''

    handleShare(shareUrl: string) {
        const homeUrl = window.location.origin
    
        shareUrl = homeUrl + shareUrl
    
        if (navigator.clipboard) {
            navigator.clipboard.writeText(shareUrl).then(() => {
                this.shareMessage = "Link copied to clipboard!"
            })
            .catch(err => {
                console.error('Failed to copy text using Clipboard API: ', err)
            })
        } else {
            console.error('Clipboard API not supported')
        }

        this.copiedMessageVisable = true
        
        setTimeout(() => {
            this.copiedMessageVisable = false
        }, 2000);  
    }
}