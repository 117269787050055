import { FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms'

export class CommonValidators extends Validators {
	static number(control: FormControl): ValidationErrors | null {
		let NUMBER_REG = /^[1-9]{1}\d*$/
		
		return NUMBER_REG.test(control.value) || control.value == 0 ? null : { number: true }
	}

	static decimal(control: FormControl): ValidationErrors | null {
		let DECIMAL_REG = /^([1-9]{1})?(\d+)?(\.[0-9]([0-9]{1})?)?$/

		return DECIMAL_REG.test(control.value) || control.value == 0 ? null : { decimal: true }
	}

	static whiteSpace(control: FormControl): ValidationErrors | null {
		return /\S/.test(control.value) ? null : { whiteSpace: true }
	}

	static phone(control: FormControl): ValidationErrors | null {
		let reg = /^\(\d{3}\)\s\d{3}-\d{4}$/
		return reg.test(control.value) ? null : { invalidPhone: true }
	}

	static dateAfterToday(control: FormControl): ValidationErrors | null {

		if(isNaN(Date.parse(control.value))) {
			return { invalidDate: true }
		}

		let today = new Date()
		let date = new Date(control.value)

		return date > today ? null : { invalidDate: true }
	}

	static dateTodayForward(control: FormControl): ValidationErrors | null {
		if(isNaN(Date.parse(control.value))) {
			return { invalidDate: true }
		}

		let separatedDate = control.value.split(/[\/\-]/)
		let today = new Date()
		let date = new Date(separatedDate[0], separatedDate[1] - 1, separatedDate[2]) // -1 because january starts at 0

		today.setHours(0,0,0,0) // Set time to start of day to compare to selected date

		return date >= today ? null : { invalidDate: true }
	}

	static lessThan(amount: number): ValidationErrors | null {

		return (control: FormControl) => {
			return control.value < amount ? null : { lessThan: true }
		}
	}
    
    static maxFileSize(maxSize: number, getFile: () => File | null): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const image = getFile()

            if(!image) {
                return null
            }
            
            const sizeInBytes = maxSize * 1024 * 1024 //maxSize is size in MB
            
            return image && image.size > sizeInBytes ? { maxSize: true } : null
        }
    }
    
    static isImage(getFile: () => File | null): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const acceptedMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
            const file = getFile()
        
            
            if(!file) {
                return null
            }
            
            return !acceptedMimeTypes.includes(file.type) ? { fileType: true } : null
        }
    }
    
    static isURL(control: AbstractControl): ValidationErrors | null {
        
        if(!control.value) {
            return null
        }
        
        let URL_REG = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\/?([\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/
		
		return URL_REG.test(control.value) ? null : { url: true }
    }
}