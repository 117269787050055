import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'state-selector',
  templateUrl: './state-selector.component.html',
  styleUrl: './state-selector.component.scss'
})
export class StateSelectorComponent {
    @Input() label: string = 'State'
    @Input() name: string = 'state'
    @Input() form: FormGroup | null = null
}
