import { Injectable } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private history: string[] = []
	private currentPage: string = ""
	private prevPage: string = ""
	private prevNavigatedPage: string = ""
    private excludedPrefixes: string[] = ['/post']

	constructor(
        private router: Router, 
        private cookieService: CookieService
    ) {
		this.router.events.pipe(
			filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			this.handleNavigation(event.url)
		})
  	}
    
    private handleNavigation(url: string): void {
		this.prevPage = this.currentPage
		this.currentPage = url
	}
    
    getHistory(): string[] {
        return this.history
    }

	clearHistory(): void {
		this.history = []
		this.cookieService.delete('history')
	}

	handleInitialNavigation(): void {
		this.currentPage = this.router.url
	}

	addHistory(curUrl: string): void {
		let prevUrl = this.currentPage

		if (this.currentPage == curUrl) {
			prevUrl = this.prevPage
		}

		if (prevUrl != this.prevNavigatedPage && prevUrl != "/" && this.shouldStoreInHistory(prevUrl)) {
			this.history.push(prevUrl)
			this.saveToCookies()
		}
        
		this.prevNavigatedPage = '/'
	}
    
    shouldStoreInHistory(url: string): boolean {
        return !this.excludedPrefixes.some(prefix => url.startsWith(prefix))
    }

	getPreviousPage(): string {
		this.loadFromCookies()
		this.prevNavigatedPage = this.currentPage

		let lastPage = '/'
		if (this.history.length > 0) {
			lastPage = this.history[this.history.length - 1]
			this.history.pop()
			this.saveToCookies()
		}

		return lastPage
	}
    
    goBack(url: string = ''): void {
        let goTo = url
        
        if(!goTo) {
            goTo = this.getPreviousPage()   
        }
        
        this.router.navigateByUrl(goTo)
    }

	private saveToCookies(): void {
		if (this.cookieService.check("history")) {
			this.cookieService.delete('history')
		}
		this.cookieService.set('history', JSON.stringify(this.history), { path: '/' })
	}

	private loadFromCookies(): void {
		const history = this.cookieService.get('history')
		if (history) {
			this.history = JSON.parse(history)
		}
	}
}