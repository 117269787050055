import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { Hive } from '../_models'

@Injectable({
    providedIn: 'root'
})
export class HiveService {
    currentHive: BehaviorSubject<Hive | null> = new BehaviorSubject<Hive | null>(null)
    
    constructor() { }
}