
<div class="notification">
    <div class="readContainer" (click)="changeReadState($event)">
        <div [ngClass]="hubHiveNotification.read ? 'read':'unread'"></div>
    </div>
        
    <a *ngIf="hubHiveNotification" 
        [routerLink]="link"
        [queryParams]="queryParams"
        (click)="changeReadState()"
        >
        <div class="notification-content">
            <p class="notification-message"><span class="person" *ngIf="!isBusinessClaimApproved">
                {{ hubHiveNotification.initiator.first_name }} {{ hubHiveNotification.initiator.last_name}}</span> {{ hubHiveNotification.message }}
            </p>
            <div class="notification-time">{{ hubHiveNotification.created | timeDifference:currentDateTime }}</div>
        </div>
        <img *ngIf="hubHiveNotification.affects.image" 
            src="{{ hubHiveNotification.affects.image }}" 
            alt="Event Image" class="profile-image">
    </a>
</div>
