import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { HasContentDirective } from './directives/has-content.directive';


@NgModule({
  declarations: [
    StopPropagationDirective,
    HasContentDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StopPropagationDirective,
    HasContentDirective,
  ]
})
export class DirectivesModule { }
