import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Config, Product, ProductCategory, ProductOffer } from '../_models'
import { config, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ProductAPIService {
    constructor(private http: HttpClient) { }
    
    getProduct(productId: string) {
        return this.http.get<Product>(`${Config.apiUrl}/product/${productId}`)    
    }
    
    createProduct(product: FormData) {
        return this.http.post<Product>(`${Config.apiUrl}/product`, product)
    }

    createOffer(offer: FormData) {
        return this.http.post<Product>(`${Config.apiUrl}/product/offer`, offer)
    }
    
    updateProduct(product: Product) {
        return this.http.patch<Product>(`${Config.apiUrl}/product`, product)
    }
    
    updateOffer(offer: ProductOffer): Observable<ProductOffer> {
        return this.http.patch<ProductOffer>(`${Config.apiUrl}/product/offer`, offer)
    }
    
    deleteProduct(id: string) {
        return this.http.delete<any>(`${Config.apiUrl}/product/${id}`)
    }
    
    deleteOffer(variantId: string, businessId: string): Observable<Product> {
        return this.http.delete<Product>(`${Config.apiUrl}/product/variant/${variantId}/offer/${businessId}`)
    }
    
    list(limit: number = 0, search: string | null = null): Observable<Product[]> {
        let params: any = {
            limit: limit
        }
        
        if(search) {
            params['search'] = search
        }
        
        return this.http.get<Product[]>(`${Config.apiUrl}/product`, { params: params } )
    }
    
    getHiveProducts(hiveId: string): Observable<Product[]> {
        return this.http.get<Product[]>(`${Config.apiUrl}/product/hive/${hiveId}`)
    }
    
    getBusinessProducts(businessId: string): Observable<Product[]> {
        return this.http.get<Product[]>(`${Config.apiUrl}/product/business/${businessId}`)
    }
    
    getParentProductCategories(): Observable<ProductCategory[]> {
        return this.http.get<ProductCategory[]>(`${Config.apiUrl}/product/categories`)
    }

    getChildProductCategories(id: string): Observable<ProductCategory[]> {
        return this.http.get<ProductCategory[]>(`${Config.apiUrl}/product/categories/${id}`)
    }

    getProductByBarcode(barcode: string): Observable<Product> {
        return this.http.get<Product>(`${Config.apiUrl}/product/barcode/${barcode}`)
    }
    
}