import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserAPIService } from 'src/app/_api-services';
import { Business, UserEntity } from 'src/app/_models';
import { RouteService } from 'src/app/_services';

@Component({
  selector: 'user-owned-entities-dropdown',
  templateUrl: './user-owned-entities-dropdown.component.html',
  styleUrl: './user-owned-entities-dropdown.component.scss'
})
export class UserOwnedEntitiesDropdownComponent implements OnDestroy, OnInit {
    @Input() type!: string
    @Input() form!: FormGroup 
    
    private subscriptions: Subscription = new Subscription()
    
    entityType: string | null = null
    entityID: string | null = null
    userEntities: UserEntity[] = []
    selectedEntity: UserEntity | null = null
    
    constructor(
        private userAPIService: UserAPIService,
        private routeService: RouteService,
    ) { }
    
    ngOnInit(): void {
        if(!this.type) {
            throw Error('entity type must be set!')
        }
        
        this.setRouteData()
        
        if(this.type == 'product') {
            this.getUserBusinesses()
            return
        }
        
        this.getUserEntities()
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    updateSelectedEntity(event: Event): void {
        const selectElement = event.target as HTMLSelectElement;
        const selectedValue = selectElement.value;
        this.selectedEntity = this.userEntities.find(entity => entity.id === selectedValue) || null;
    }
    
    getUserBusinesses(): void {
        this.subscriptions.add(
            this.userAPIService.getUserBusinesses().subscribe({
                next: businesses => {
                    this.convertBusinessToEntity(businesses)
                    this.setCurrentEntity()
                },
                error: err => {
                    console.log(err)
                }
            })
        )    
    }
    
    getUserEntities(): void {
        this.subscriptions.add(
            this.userAPIService.getUserEntities().subscribe({
                next: entities => {
                    this.userEntities = entities
                    this.setCurrentEntity()
                },
                error: err => {
                    console.log(err)
                }
            })
        )
    }
    
    convertBusinessToEntity(businesses: Business[]): void {
        if(!businesses || !businesses.length) {
            return
        }
        
        for(let b of businesses) {
            this.userEntities.push({
                'id': b.id,
                'name': b.name,
                'image': b.logo,
                'handle': b.handle,
            })
        }
    }
    
    setRouteData(): void {
        this.subscriptions.add(
            this.routeService.getRouteData().subscribe( data => {
                this.entityType = data['type']
            })
        )
        
        this.subscriptions.add(
            this.routeService.getRouteParams().subscribe( params => {
                this.entityID = params['id'] || params['handle']
            })
        )
    }
    
    setCurrentEntity(): void {
        if(!this.userEntities.length) {
            return
        }
   
        if(this.entityType === 'business' || this.entityType === 'hive') {
            for(let e of this.userEntities) {
                if(e.id === this.entityID || e.handle == this.entityID) {
                    this.form.controls['entity'].patchValue(e.id)
                    this.selectedEntity = e
                    return
                }
            }
        }
        
        this.form.controls['entity'].patchValue(this.userEntities[0].id)
        this.selectedEntity = this.userEntities[0]
    }
}
