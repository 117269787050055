import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { HubhiveEvent } from 'src/app/_models'
import { ModalService, RouteService } from 'src/app/_services'
import { CommonValidators } from 'src/app/_validators/common.validators'
import { DateTime } from 'src/app/_pipes'

@Component({
  selector: 'event-create',
  templateUrl: './event-create.component.html',
  styleUrl: './event-create.component.scss',
  providers: [DateTime],
})
export class EventCreateComponent implements OnDestroy, OnChanges, OnInit, AfterViewInit {
    @Input() event: HubhiveEvent | null = null
    @Output() changeStep: EventEmitter<number> = new EventEmitter<number>()
    @Output() eventCreation: EventEmitter<FormData> = new EventEmitter<FormData>()
    
    @ViewChild('eventImageInput') eventImageInput!: ElementRef
    @ViewChild('nameInput') nameInput!: ElementRef
    @ViewChild('locationInput') locationInput!: ElementRef
    @ViewChild('streetInput') streetInput!: ElementRef
    @ViewChild('cityInput') cityInput!: ElementRef
    @ViewChild('zipInput') zipInput!: ElementRef
    @ViewChild('urlInput') urlInput!: ElementRef
    @ViewChild('descriptionInput') descriptionInput!: ElementRef
    
    private subscriptions: Subscription = new Subscription()

    timeGapInMillis: number = 24 * 60 * 60 * 1000
    createEventForm!: FormGroup
    eventImage: File | null = null
    previewUrl: string = ''
    endDateAdded: boolean = false
    isUpdate: boolean = false
    eventId: string = ""
    
    
    
    constructor(
        private fb: FormBuilder, 
        private modalService: ModalService,
    ) {
        this.modalService.activateFooterBtn()
        this.subscriptions.add(
            this.modalService.doFooterAction.subscribe( () => {
                this.createUpdateEvent()
            })
        )
    }
    
    ngOnInit(): void {
        this.createForm()
        
        if(!this.event){
            return
        }

        this.eventId = this.event.id
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.event = changes['event'].currentValue
    }
    
    ngAfterViewInit(): void {
        this.doChangeOnAllInputs()
        
        if(this.event){
            this.modalService.updateFooterBtnTxt('Update Event')
        } else{
            this.modalService.updateFooterBtnTxt('Create Event')

        }
    }
    
    private formatDate(date: Date): string {
        let day = ("0" + date.getDate()).slice(-2)
        let month = ("0" + (date.getMonth() + 1)).slice(-2)
        let year = date.getFullYear()
        
        return `${year}-${month}-${day}`
    }
    
    private formatTime(date: Date): string {
        let hours = ("0" + date.getHours()).slice(-2)
        let minutes = ("0" + date.getMinutes()).slice(-2)
        
        return `${hours}:${minutes}`
    }
    
    createForm() {
        let date= new Date()
        date = new Date(date.getTime() + this.timeGapInMillis)
        
        if(this.event){
            this.isUpdate = true
            const event = this.event
            const startDateTime = new Date(this.event.start_date);
            const endDateTime = this.event.end_date ? new Date(this.event.end_date) : null;
            if (endDateTime){
                this.endDateAdded = true
            }
            this.createEventForm = this.fb.group({
                entity: new FormControl(event.hosts[0].first_name, Validators.required),
                name: new FormControl(event.name, Validators.required),
                image: new FormControl(null, [
                    CommonValidators.isImage(() => this.eventImage), 
                    CommonValidators.maxFileSize(5, () => this.eventImage)
                ]),
                start_date: new FormControl(this.formatDate(startDateTime), [Validators.required]),
                start_time: new FormControl(this.formatTime(startDateTime), Validators.required),
            
                ...(endDateTime ? {
                    end_date: new FormControl(this.formatDate(endDateTime), Validators.required),
                    end_time: new FormControl(this.formatTime(endDateTime), Validators.required)
                  } : {}),
                
                location: new FormControl(event.location),
                street: new FormControl(event.street),
                city: new FormControl(event.city),
                state: new FormControl(event.state),
                zip: new FormControl(event.zip),
                url: new FormControl(event.url),
                description: new FormControl(event.description, Validators.required)
            }, { validators: this.endDateAfterStartDateValidator })
            this.doChangeOnAllInputs()
        } else {
            this.createEventForm = this.fb.group({
                entity: new FormControl('', Validators.required),
                name: new FormControl('', Validators.required),
                image: new FormControl(null, [
                    CommonValidators.isImage(() => this.eventImage), 
                    CommonValidators.maxFileSize(5, () => this.eventImage)
                ]),
                start_date: new FormControl(this.formatDate(date), [Validators.required]), 
                start_time: new FormControl(this.formatTime(date), Validators.required),
                location: new FormControl(''),
                street: new FormControl(''),
                city: new FormControl(''),
                state: new FormControl(''),
                zip: new FormControl(''),
                url: new FormControl(''),
                description: new FormControl('', Validators.required)
            }, { validators: this.endDateAfterStartDateValidator })

        }

        this.createEventForm.get('start_date')?.valueChanges.subscribe(() => this.updateEndDateTime())
        this.createEventForm.get('start_time')?.valueChanges.subscribe(() => this.updateEndDateTime())
    }

    endDateAfterStartDateValidator(group: AbstractControl): { [key: string]: any } | null {
        const startDate = group.get('start_date')?.value
        const startTime = group.get('start_time')?.value
        const endDate = group.get('end_date')?.value
        const endTime = group.get('end_time')?.value
    
        if (!startDate || !startTime || !endDate || !endTime) {
            return null
        }
        
        const startDateTime = new Date(`${startDate}T${startTime}:00`)
        const endDateTime = new Date(`${endDate}T${endTime}:00`)
        
        if (endDateTime < startDateTime) {
            return { 'endDateBeforeStartDate': true }
        }
        
        return null
    }
    
    calculateTimeGap() {
        const startDate = this.createEventForm.get('start_date')?.value
        const startTime = this.createEventForm.get('start_time')?.value
        const endDate = this.createEventForm.get('end_date')?.value
        const endTime = this.createEventForm.get('end_time')?.value
        
        if (!startDate || !startTime || !endDate || !endTime) {
            return
        }

        const startDateTime = new Date(`${startDate}T${startTime}:00`)
        const endDateTime = new Date(`${endDate}T${endTime}:00`)

        this.timeGapInMillis = endDateTime.getTime() - startDateTime.getTime()
        
        return
    }
    
    updateEndDateTime() {
        const startDate = this.createEventForm.get('start_date')?.value
        const startTime = this.createEventForm.get('start_time')?.value

        if (!startDate || !startTime) {
            return
        }

        const startDateTime = new Date(`${startDate}T${startTime}:00`)
        const endDateTime = new Date(startDateTime.getTime() + this.timeGapInMillis)

        if (this.endDateAdded) {
            this.createEventForm.get('end_date')?.setValue(this.formatDate(endDateTime))
            this.createEventForm.get('end_time')?.setValue(this.formatTime(endDateTime))
        }
    }
    
    get entity() {
        return this.createEventForm.get('entity')
    }

    get name() {
        return this.createEventForm.get('name')
    }
    
    get image() {
        return this.createEventForm.get('image')
    }
    
    get startDate() {
        return this.createEventForm.get('start_date')
    }
    
    get endDate() {
        return this.createEventForm.get('end_date')
    }
    
    get location() {
        return this.createEventForm.get('location')
    }
    
    get street() {
        return this.createEventForm.get('street')
    }
    get city() {
        return this.createEventForm.get('city')
    }
    get state() {
        return this.createEventForm.get('state')
    }
    get zip() {
        return this.createEventForm.get('zip')
    }

    get url() {
        return this.createEventForm.get('url')
    }
    
    get description() {
        return this.createEventForm.get('description')
    }
    
    doChangeOnAllInputs(): void {
        if(!this.event) {
            return
        }
        
        if(this.event.name) {
            if(!this.nameInput){
                return
            }
            this.createEventForm.get('name')?.patchValue(this.event?.name)
        }

        if(this.event.location) {
            this.createEventForm.get('location')?.patchValue(this.event?.location)   
        }
        
        if(this.event.street) {
            this.createEventForm.get('street')?.patchValue(this.event?.street)   
        }
        
        if(this.event.city) {
            this.createEventForm.get('city')?.patchValue(this.event?.city)   
        }
        
        if(this.event.zip) {
            this.createEventForm.get('zip')?.patchValue(this.event?.zip)   
        }
        
        if(this.event.url) {
            this.createEventForm.get('url')?.patchValue(this.event?.url)
        }
        
        if(this.event.description) {
            this.createEventForm.get('description')?.patchValue(this.event?.description)   
        }
    }

    toggleEndDateAndTime(): void {
        if (this.endDateAdded) {
            this.endDateAdded = false;
            this.createEventForm.removeControl('end_date');
            this.createEventForm.removeControl('end_time');
            return 
        }
        
        let startDate = this.createEventForm.get('start_date')?.value;
        let startTime = this.createEventForm.get('start_time')?.value;

        if (!startDate || !startTime) {
            return
        }
        
        let startDateTime = new Date(startDate + 'T' + startTime);
        let endDateTime = new Date(startDateTime.getTime() + this.timeGapInMillis);

        this.createEventForm.addControl('end_date', new FormControl(this.formatDate(endDateTime), Validators.required));
        this.createEventForm.addControl('end_time', new FormControl(this.formatTime(endDateTime), Validators.required));
        
        this.subscriptions.add(
            this.createEventForm.get('end_date')?.valueChanges.subscribe(() => {
                this.calculateTimeGap()
            })    
        )
        
        this.subscriptions.add(
            this.createEventForm.get('end_time')?.valueChanges.subscribe(() => {
                this.calculateTimeGap()
            })    
        )
        
        this.endDateAdded = true;
    }
    
    setEventImage(file: File | null) {
        this.eventImage = file
    }
    
    createUpdateEvent(): void {
        if(!this.createEventForm.valid) {
            this.createEventForm.markAllAsTouched()
            return
        }
        
        this.event = this.createEventForm.value

        if(!this.event) {
            return
        }
        
        let startDate = this.createEventForm.value['start_date']
        let startTime = this.createEventForm.value['start_time']
        
        let endDate = this.createEventForm.value['end_date']
        let endTime = this.createEventForm.value['end_time']

        const offset = new Date().getTimezoneOffset()
        const offsetHours = Math.floor(Math.abs(offset) / 60)
        const offsetMinutes = Math.abs(offset % 60)
        const offsetSign = offset > 0 ? '-' : '+'
        const timezoneOffset = offsetSign + String(offsetHours).padStart(2, '0') + ':' + String(offsetMinutes).padStart(2, '0')

        this.event.start_date = new Date(startDate + 'T' + startTime + ':00' + timezoneOffset).toISOString()
        
        if(endDate) {
            this.event.end_date = new Date(endDate + 'T' + endTime +  ':00' + timezoneOffset).toISOString()
        }

        if(isNaN(new Date(this.event.start_date).getTime())) {
            return
        }

        if(endDate && isNaN(new Date(this.event.end_date!).getTime())) {
            return
        }

        
        const formData = new FormData()
        
        formData.append('id', this.eventId)
        formData.append('entity', this.createEventForm.controls['entity'].value)
        formData.append('name', this.event.name)
        formData.append('start_date', this.event.start_date)
        formData.append('location', this.event.location)
        formData.append('street', this.event.street)
        formData.append('city', this.event.city)
        formData.append('state', this.event.state)
        formData.append('zip', this.event.zip)
        formData.append('url', this.event.url)
        formData.append('description', this.event.description)

        if(this.event.end_date) {
            formData.append('end_date', this.event.end_date)    
        }
        
        if(this.eventImage) {
            formData.append('image', this.eventImage, this.eventImage.name)
        }
        
        this.eventCreation.emit(formData)
    }
}
