import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'business-review-overview',
  templateUrl: './business-review-overview.component.html',
  styleUrls: ['./business-review-overview.component.scss']
})
export class BusinessReviewOverviewComponent implements OnInit {
    @Input() trustScore: number | undefined
    @Input() totalReviews: number | undefined
    @Input() averageRating: number | undefined
    @Input() context: string = 'normal'
    @Input() companyName: string | undefined
    @Input() claimed: boolean = false
    @Input() logo: string | undefined
    
    starWidth: string = ''
    showScoreExplanation: boolean = false
    
    ngOnInit(): void {
        if(this.averageRating) {
            this.starWidth = this.averageRating / 5 * 100 + "%"
        }
    }
    
    toggleScoreExplanation(event: MouseEvent): void {
        event.preventDefault()
        event.stopPropagation()
        
        this.showScoreExplanation = true
    }
    
    closeModal(event: MouseEvent): void {
        event.preventDefault()
        event.stopPropagation()
        
        this.showScoreExplanation = false
    }
}
