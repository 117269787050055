<div class="icon-links">
    <a routerLink="/hives" class="icon-link">
        <img src="/assets/icon-hives-purple.svg" alt="Hives">
        <div>Hives</div>
    </a>
    <a routerLink="/business" class="icon-link">
        <img src="/assets/icon-business-purple.svg" alt="Businesses">
        <div>Businesses</div>
    </a>
    <a routerLink="/products" class="icon-link">
        <img src="/assets/icon-products-purple.svg" alt="Products">
        <div>Products</div>
    </a>
    <a routerLink="/event" class="icon-link">
        <img src="/assets/icon-calendar-purple.svg" alt="Events">
        <div>Events</div>
    </a>
</div>