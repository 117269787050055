import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: number | string): string {
    let phoneString = phoneValue + ''; // Ensure the phone is a string

    // Remove all non-digits characters from the string
    phoneString = phoneString.replace(/\D/g, '');

    // Check if phoneString meets the length requirements
    if (phoneString.length !== 10) {
      return phoneString; // Return original string if it's not 10 digits long
    }

    // Perform the formatting
    const areaCode = phoneString.slice(0, 3);
    const middle = phoneString.slice(3, 6);
    const last = phoneString.slice(6, 10);

    return `(${areaCode}) ${middle}-${last}`;
  }

}
