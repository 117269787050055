import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: number): string {
    if(!value) {
        return ''
    }
    
    if (value > 1000000) {
        return (value / 1000000).toFixed(1) + 'M'    
    }
    
    if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k';  // Adjust the decimal point as needed
    } 
    
    return value.toString();  // Return the original number if less than 1000
  }
}