<div class="modal-background" (click)="close()" [ngClass]="{'active': isVisible}">
    <div class="modal-content" [ngClass]="{'transform': transform, 'no-transform': !transform}" stopPropagation>
        <div class="modal-header" *ngIf="showCancelBtn || showPrimaryBtn">
            <button *ngIf="showCancelBtn" class="cancel btn btn-secondary btn-rounded btn-bordered" (click)="close()">Cancel</button>
            
            <loading-spinner *ngIf="loading" class="save"></loading-spinner>
            <button *ngIf="showPrimaryBtn" class="save btn btn-primary btn-rounded btn-bordered" (click)="doPrimaryAction()">{{ primaryBtnTxt }}</button>
        </div>
        
        <div class="modal-body">
            <h2 class="title" *ngIf="title">{{ title }}</h2>
            <ng-container *ngIf="content">
                <ng-container *ngTemplateOutlet="content; context: { data: context }"></ng-container>
            </ng-container>
        </div>    
    </div>
</div>  