<div>
    <label for="deviceSelect">Select Camera:</label>
    <select id="deviceSelect" (change)="onDeviceSelectChange($event)">
        <option *ngFor="let device of availableDevices" [value]="device.deviceId">{{ device.label }}</option>
    </select>
</div>
  
<zxing-scanner
	*ngIf="!loading"	
	[device]="selectedDevice"
	[formats]="allowedFormats"
	(scanSuccess)="onCodeResult($event)">
</zxing-scanner>

<div *ngIf="loading">
    <p>Fetching Data</p>
    <loading-spinner></loading-spinner>
</div>
