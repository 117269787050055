import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessAPIService, HiveAPIService, UserAPIService } from 'src/app/_api-services';
import { EntityType } from 'src/app/_enums';
import { Business, Hive, HubhiveEvent, User } from 'src/app/_models';
import { ModalBottomService } from 'src/app/_services';


interface Data {
    type: string
    name: string
    image: string
}

@Component({
  selector: 'image-selector',
  templateUrl: './image-selector.component.html',
  styleUrl: './image-selector.component.scss'
})
export class ImageSelectorComponent implements OnInit, OnDestroy {
    @Input() entityType!: EntityType
    @Input() entityId!: string
    @Input() editorType: string = 'circle'
    @Input() field: string | null = null
    @Input() image: string | undefined
    
    @Output() imageUploaded: EventEmitter<Business | Hive | User | HubhiveEvent> = new EventEmitter<Business | Hive | User | HubhiveEvent>()
    
    @ViewChild('choosePhoto') choosePhoto!: ElementRef
    @ViewChild('video') video!: ElementRef<HTMLVideoElement>
    @ViewChild('canvas') canvas!: ElementRef
    
    private subscriptions: Subscription = new Subscription()
    
    baseTempURL: string | ArrayBuffer | null = null
    tempURL: string | ArrayBuffer | null = null
    error: boolean = false
    imageName: string = ''
    repositioning: boolean = false
    imageToUpload: string = ''
    
    constructor(
        private hAPIService: HiveAPIService,
        private uAPIService: UserAPIService,
        private bAPIService: BusinessAPIService,
        private bModalService: ModalBottomService,
    ) {}
    
    ngOnInit(): void {
        if(this.entityType === null || this.entityType === undefined) {
            throw new Error('Entity type must be set!')
        }
        
        if(!this.entityId) {
            throw new Error('Entity ID must be set!')
        }
        
        this.subscriptions.add(
            this.bModalService.doPrimaryAction.subscribe(() => {
                this.uploadImage()
            })    
        )
        
        this.subscriptions.add(
            this.bModalService.doCloseAction.subscribe(() => {
                this.reset()
            })
        )
        
        this.subscriptions.add(
            this.bModalService.doOpenAction.subscribe(() => {
                if(this.image) {
                    this.baseTempURL = this.image
                    this.tempURL = this.image
                }
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    private reset(): void {
        this.tempURL = null
        this.baseTempURL = null
    }
    
    selectFile(): void {
        this.bModalService.noTransform()
        this.choosePhoto.nativeElement.click()
    }
    
    repositionClicked(): void {
        if(!this.tempURL) {
            return
        }
        
        this.bModalService.noTransform()
        setTimeout(() => {
            this.repositioning = true    
        }, 400)
    }
    
    cancelRepositioning(): void {
        this.repositioning = false
        this.tempURL = null
        this.bModalService.transform()
    }
    
    applyCrop(base64Image: string): void {
        this.tempURL = base64Image
        this.repositioning = false
        this.imageToUpload = base64Image
        this.bModalService.transform()
    }
    
    setPreview(event: any): void {
        const file: File = event.target.files[0]
        
        if(!file || !this.imageIsValid(file.type)) {
            return
        }

        if(file) {
            this.error = false
            this.imageName = file.name
            this.readImage(file)
        }
    }
    
    imageIsValid(type: string): boolean {
        const validMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']

        if(validMimeTypes.includes(type)) {
            return true
        }
        
        return false
    }
    
    readImage(file: File): void {
        const reader = new FileReader()
        
        reader.onload = (e: any) => {
            this.baseTempURL = e.target.result
            this.tempURL = this.baseTempURL
            this.repositioning = true
        }
        
        reader.readAsDataURL(file)
    }
    
    uploadImage(): void {
        if(!this.imageToUpload) {
            return
        }
        
        this.bModalService.startLoading()
        let data: Data = {
            image: this.imageToUpload, 
            name: this.imageName, 
            type: '',
        }
        
        switch(this.entityType) {
            case EntityType.Business:
                this.updateBusinessImage(data)
            break
            
            case EntityType.Hive:
                this.updateHiveLogo(data)
            break
            
            case EntityType.Event:
                this.updateEventImage()        
            break
            
            case EntityType.User:
                this.updateUserProfileImage(data)
            break
        }
    }
    
    updateBusinessImage(data: Data): void {
        data.type = 'logo'
        
        if(this.field) {
            data.type = this.field
        }
        
        this.subscriptions.add(
            this.bAPIService.updateImage(this.entityId, data).subscribe({
                next: b => {
                    this.imageUploaded.emit(b as Business)
                    this.bModalService.stopLoading()
                    this.bModalService.close()
                },
                error: err => {
                    console.log(err)
                    this.bModalService.stopLoading()
                }
            })
        )
    }
    
    updateHiveLogo(data: Data): void {
        data.type = 'cover_image'
        
        this.subscriptions.add(
            this.hAPIService.updateImage(this.entityId, data).subscribe({
                next: h => {
                    this.imageUploaded.emit(h as Hive)
                    this.bModalService.stopLoading()
                    this.bModalService.close()
                },
                error: err => {
                    console.log(err)
                    this.bModalService.stopLoading()
                }
            })
        )
    }
    
    updateEventImage(): void {
        
    }
    
    updateUserProfileImage(data: Data): void {
        this.subscriptions.add(
            this.uAPIService.uploadAvatar(data.name, data.image).subscribe({
                next: u => {
                    this.imageUploaded.emit(u as User)
                    this.bModalService.stopLoading()
                    this.bModalService.close()
                },
                error: err => {
                    console.log(err)
                    this.bModalService.stopLoading()
                }
            })
        )
    }

}
