import { Component, Input } from '@angular/core';
import { Tab } from 'src/app/_models'
@Component({
  selector: 'nav-home',
  templateUrl: './nav-home.component.html',
  styleUrl: './nav-home.component.scss'
})
export class NavHomeComponent {
  @Input() tabs: Tab[] = [];

  selectTab(event: Event) {
    event.preventDefault();
    const tabs = document.querySelector('.navbar');
    if (!tabs) return;

    const links = tabs.querySelectorAll('a');
    links.forEach(link => link.classList.remove('active'));

    const target = event.currentTarget as HTMLElement;
    target.classList.add('active');
  }
}