<form *ngIf="hiveForm" [formGroup]="hiveForm" class="modal-form">
    <div>
        <div class="field-group" *ngIf="uptHive">
            <h5>Type</h5>
            <select formControlName="type" class="custom-select">
                <option value="public">Public</option>
                <option value="private">Private</option>
            </select>

            <div *ngIf="type?.errors && type?.touched">
                <p class="error" *ngIf="type?.errors?.['required']">Type is required</p>
            </div>

            <p *ngIf="type?.value === 'public'" class="small">Anyone can view, post, and connect in this community</p>
            <p *ngIf="type?.value === 'private'" class="small">Only approved members can post and connecting is by invite only</p>
        </div>
        
        <div class="field-group">
            <file-dropzone class="hive" [form]="hiveForm" [inputName]="'image'" [label]="'Add Hive Image'"  (doFileOutput)="setHiveImage($event)"></file-dropzone>
        </div>
        
        
        <div class="field-group fancy-shmancy" style="margin-top: 30px;">
            <div>
                <input #nameInput class="search-input" type="text" formControlName="name" hasContent>
                <label>Hive Name</label>
                <div *ngIf="name?.errors && name?.touched">
                    <p class="error" *ngIf="name?.errors?.['required']">Name is required</p>
                </div>
                <div *ngIf="name?.errors?.['nameExists']">
                    <p class="error">This name is unavailable.</p>
                </div>
            </div>
        </div>
        
        <div class="field-group fancy-shmancy">
            <textarea #descriptionInput formControlName="description" hasContent></textarea>
            <label>Description</label>
        </div>

        <div class="field-group fancy-shmancy" *ngIf="uptHive">
            <div>
                <select class="always-has-content" formControlName="category" (change)="setSubCategories($event)">
                    <option *ngFor="let c of categories" [value]="c.id">{{ c.name }}</option>
                </select>
                <label>Category</label>
            </div>
        </div>

        <div class="field-group fancy-shmancy" *ngIf="subCategories.length">
            <div>
                <select class="always-has-content" formControlName="sub_category" (change)="setTags($event)">
                    <option *ngFor="let c of subCategories" [value]="c.id">{{ c.name }}</option>
                </select>
                <label>Sub Category</label>
            </div>
        </div>

        <div class="field-group fancy-shmancy" *ngIf="uptHive && tags.length">
            <div>
                <tag-input
                    formControlName="tags"
                    [existingTags]="tagNames"
                    [maxTags]="5"
                    (tagChange)="onTagChange($event)"
                ></tag-input>
            </div>
        </div>
    </div>
</form>
