import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/_enums';
import { GeneralNotification } from 'src/app/_models/general-notification.model';
import { NotificationAPIService } from 'src/app/_api-services';

@Component({
  selector: 'notification-card',
  templateUrl: './notification-card.component.html',
  styleUrl: './notification-card.component.scss'
})
export class NotificationCardComponent implements OnInit, OnChanges, OnDestroy {
    private subscriptions: Subscription = new Subscription()

    @Input() hubHiveNotification!: GeneralNotification
    @Output() readStateChanged: EventEmitter<void> = new EventEmitter<void>()
    
    defaultListClass: string = 'event-card-compressed'
    isDifferentDays: boolean = false
    link: string | undefined
    queryParams: {} = {}
    currentDateTime: string = ''
    isBusinessClaimApproved: boolean = false
    
    constructor(
        private cdr: ChangeDetectorRef,
        private notificationAPIService: NotificationAPIService,
    ) {}
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    ngOnInit(): void {
        this.generateLink()
        this.updateTime()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['hubHiveNotification'] && this.hubHiveNotification) {
            this.isBusinessClaimApproved = this.hubHiveNotification.type == NotificationType.BusinessClaimApproved
            this.generateLink()
        }
    }
    
    generateLink(): void {
        let lt = this.hubHiveNotification.links_to
        let link = `/${lt?.type}`
        
        switch(lt?.type) {
            case 'post':
                let type = this.hubHiveNotification.type

                if(type == NotificationType.ReplyMention || type == NotificationType.ReplyLike || type == NotificationType.PostReply) {
                    link += `/${this.hubHiveNotification.parent_post.id}/comment/${lt.id}`
                } else {
                    link += `/${lt.id}/details`
                }
                
            break
            
            case 'hive':
                if(this.hubHiveNotification.type == NotificationType.PostEntity) {
                    link = `/${lt.handle}/posts`
                }
                
                if(this.hubHiveNotification.type == NotificationType.HiveInvite) {
                    link = `/${lt.handle}/about`
                }
                
                if(this.hubHiveNotification.type == NotificationType.JoinHive) {
                    link = `/${lt.handle}/members`
                }

                if(this.hubHiveNotification.type == NotificationType.PostHive) {
                    link = `/${lt.handle}/posts`
                } 
                
            break
            case 'business':
                if(this.hubHiveNotification.type == NotificationType.PostEntity) {
                    link += `/${lt.handle}/posts`
                } else {
                    link += `/${lt.handle}/details`
                }
            break
            case 'event':
                if(this.hubHiveNotification.type == NotificationType.PostEntity) {
                    link += `/${lt.name}/posts`
                } else {
                    link += `/${lt.name}/details`    
                }
                
                this.queryParams = { id: lt.id }
                
            break
        }
        
        this.link = link
        this.cdr.detectChanges()
    }
    
    updateTime(): void {
        this.currentDateTime = new Date().toISOString()
        this.cdr.markForCheck()
        setTimeout(() => this.updateTime(), 60000)
    }

    changeReadState(toggle: Event | null = null) {
        if (toggle) {
            toggle.stopPropagation()
            this.hubHiveNotification.read = !this.hubHiveNotification.read
        } else {
            this.hubHiveNotification.read = true
        }
        
        this.subscriptions.add(this.notificationAPIService.updateNotification(this.hubHiveNotification).subscribe({
            next: () => {
                this.readStateChanged.emit()
            }
        }))
    }
}