import { Component, Input } from '@angular/core';
import { Config, User } from 'src/app/_models';
import { DateTime } from 'src/app/_pipes';

@Component({
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss'
})
export class UserCardComponent {
    @Input() user!: User
    @Input() tileOnly: boolean = false
    @Input() relationship: string | null = null
    @Input() timeStamp: string | null = null
    @Input() isLink: boolean = true
    
    
    loading: boolean = false
    added: boolean = false
    addBtnTxt: string = "Add"
    
    constructor() {
        
    }
}
