<div class="modal-background" [class.active]="isActive" (click)="closeModal()">
    <div class="modal-content" stopPropagation>
        <h2>Add a new</h2>
        <ul>
            <li (click)="createHive(createHiveModal)"><img src="/assets/icon-hives.svg" alt="hives icon">
                <p>Hive</p>
            </li>
            <li (click)="createBusiness(createBusinessModal)"><img src="/assets/icon-business.svg" alt="business icon">
                <p>Business</p>
            </li>
            <li (click)="createEvent(createEventModal)"><img src="/assets/icon-calendar.svg" alt="event icon">
                <p>Event</p>
            </li>
            <li (click)="createProduct(createProductModal)"><img src="/assets/icon-products.svg" alt="product icon">
                <p>Product</p>
            </li>
        </ul>
    </div>
</div>

<ng-template #createHiveModal let-data="data">
    <create-hive-container></create-hive-container>
</ng-template>

<ng-template #createBusinessModal let-data="data">
    <business-add></business-add>
</ng-template>

<ng-template #createEventModal let-data="data">
    <event-create-container></event-create-container>
</ng-template>

<ng-template #createProductModal let-data="data">
    <product-create-container></product-create-container>
</ng-template>