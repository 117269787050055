import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class URLValidator {
    isValidRedirect(url: string): boolean {
        return url.startsWith('/')
    }

    getRefFromUrl(route: ActivatedRoute): string {
        const ref = route.snapshot.queryParamMap.get('ref')
        if (ref) {
            return ref
        }
    
        const redirect = route.snapshot.queryParamMap.get('redirect')
        if (redirect) {
            const queryString = redirect.split('?')[1]
            if (queryString) {
                const redirectParams = new URLSearchParams(queryString)
                return redirectParams.get('ref') || ''
            }
        }
    
        return '';
    }
}