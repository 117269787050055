<form [formGroup]="createEventForm">
    <div class="field-group">
        <user-owned-entities-dropdown [form]="createEventForm" [type]="'event'"></user-owned-entities-dropdown>
    </div>

    <div class="field-group fancy-shmancy">
        <div>
            <input #nameInput type="text" formControlName="name" hasContent>
            <label>Event Name</label>
            <span *ngIf="name?.errors && name?.touched" class="error">Event Name is required</span>
        </div>
    </div>
    
    <div class="field-group">
        <file-dropzone [form]="createEventForm" [inputName]="'image'" [label]="'Add Event Image'" (doFileOutput)="setEventImage($event)"></file-dropzone>
    </div>
    
    <div class="flex">
        <div class="field-group fancy-shmancy">
            <div>
                <input type="date" formControlName="start_date" class="always-has-content">
                <label>Start Date</label>
            </div> 
        </div>
        <div class="field-group fancy-shmancy">
            <div>
                <input type="time" formControlName="start_time" class="always-has-content" step="300">
                <label>Start Time</label>
            </div>
        </div>
    </div>
        
    <div class="flex" *ngIf="endDateAdded">
        <div class="field-group fancy-shmancy">
            <div>
                <input type="date" formControlName="end_date" class="always-has-content">
                <label>End Date</label>
            </div> 
        </div>
        <div class="field-group fancy-shmancy">
            <div>
                <input type="time" formControlName="end_time" class="always-has-content" step="300">
                <label>End Time</label>
            </div>
        </div>
    </div>
    <span *ngIf="createEventForm.errors?.['endDateBeforeStartDate']" class="error">
        End date and time must be after start date and time.
    </span>
    <p *ngIf="!endDateAdded" (click)="toggleEndDateAndTime()" class="add-end-date">+ Add end date and time</p>
    <p *ngIf="endDateAdded" (click)="toggleEndDateAndTime()" class="add-end-date">- Remove end date and time</p>
    
    <div class="field-group fancy-shmancy">
        <div>
            <input #locationInput type="text" formControlName="location" hasContent>
            <label>Location</label>
            <span *ngIf="location?.errors && location?.touched" class="error">Location is required</span>
        </div>
    </div>

    <div class="field-group fancy-shmancy">
        <div>
            <input #streetInput type="text" formControlName="street" hasContent>
            <label>Street Address</label>
            <span *ngIf="street?.errors && street?.touched" class="error">Street is required</span>
        </div>
    </div>

    <div class="flex">
        <div class="field-group fancy-shmancy">
            <div>
                <input #cityInput type="text" formControlName="city" hasContent>
                <label>City</label>
                <span *ngIf="city?.errors && city?.touched" class="error">City is required</span>
            </div>
        </div>
        <div class="field-group fancy-shmancy space">
            <div>
                <state-selector [form]="createEventForm"></state-selector>
                <span *ngIf="state?.errors && state?.touched" class="error">State is required</span>
            </div>
        </div>
        <div class="field-group fancy-shmancy">
            <div>
                <input #zipInput type="text" formControlName="zip" hasContent>
                <label>Zip-Code</label>
                <span *ngIf="zip?.errors && zip?.touched" class="error">Zip-Code is required</span>
            </div>
        </div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <input #urlInput type="text" formControlName="url" hasContent>
            <label>Link to RSVP</label>
            <span *ngIf="url?.errors && url?.touched" class="error">Link to RSVP is required</span>
        </div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <textarea #descriptionInput formControlName="description" hasContent></textarea>
            <label>Description</label>
            <span *ngIf="description?.errors && description?.touched" class="error">Description is required</span>
        </div>
    </div>
</form>