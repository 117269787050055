import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core'

@Component({
  selector: 'image-editor',
  templateUrl: './image-editor.component.html',
  styleUrl: './image-editor.component.scss'
})
export class ImageEditorComponent {
    @Input() imageUrl: string | ArrayBuffer | null = null
    @Input() cropType: string = 'circle'
    
    @Output() canceled: EventEmitter<void> = new EventEmitter<void>()
    @Output() applied: EventEmitter<string> = new EventEmitter<string>()
    
    @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>
    
    private context: CanvasRenderingContext2D | null = null
    private image: HTMLImageElement = new Image()
    private circle = { x: 100, y: 100, radius: 100, dragging: false }
    private defaultRectWidth = 618
    private defaultRectHeight = 295
    private rect = { x: 100, y: 100, width: 618, height: 295, dragging: false }
    private relativeRectX: number = 0
    private relativeRectY: number = 0
    
    private imageX!: number
    private imageY!: number
    private imageWidth!: number
    private imageHeight!: number
    private scale: number = 1
    private prevScale: number = 1
    private lastDistance: number | null = null
    

    constructor() { }

    ngAfterViewInit(): void {
        this.context = this.canvas.nativeElement.getContext('2d')
        this.loadImage()
        window.addEventListener('resize', this.resizeCanvas.bind(this))
        this.addEventListeners()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['imageUrl'] && this.imageUrl) {
            this.loadImage()
        }
    }
    
    private loadImage(): void {
        if(typeof this.imageUrl === 'string') {
            this.image.src = this.imageUrl
            this.image.crossOrigin = 'anonymous'
            this.image.onload = () => this.resizeCanvas()
            return
        }
        
        if(this.imageUrl instanceof ArrayBuffer) {
            const blob = new Blob([this.imageUrl])
            const url = URL.createObjectURL(blob)
            this.image.src = url
            this.image.onload = () => {
                URL.revokeObjectURL(url)
                this.resizeCanvas()
            }
            return
        }
        
        throw Error('Invalid image url')
    }
    
    resizeCanvas(): void {
        this.resizeCaptureArea()
        
        const canvas = this.canvas.nativeElement
        const canvasWidth = canvas.clientWidth
        const canvasHeight = canvas.clientHeight

        canvas.width = canvasWidth
        canvas.height = canvasHeight
        
        // Reset scale and calculate image dimensions and position
        this.scale = 1
        this.calculateImageDimensions()
        
        let widthCenter = canvasWidth / 2
        let heightCenter = canvasHeight / 2
        
        
        if(this.cropType == 'circle') {
            this.circle.x = widthCenter
            this.circle.y = heightCenter   
        }
        
        if(this.cropType == 'rect') {
            this.rect.x = widthCenter - this.rect.width / 2
            this.rect.y = heightCenter - this.rect.height / 2
        }
        
        
        this.draw()
    }
    
    resizeCaptureArea(): void {
        const screenWidth = window.innerWidth
        const aspectRatio = this.defaultRectWidth / this.defaultRectHeight
        
        let rectWidth = Math.min(screenWidth, this.defaultRectWidth)
        let rectHeight = rectWidth / aspectRatio
        
        this.rect.width = rectWidth
        this.rect.height = rectHeight
    }
    
    private calculateImageDimensions(): void {
        const canvas = this.canvas.nativeElement
        const canvasWidth = canvas.width
        const canvasHeight = canvas.height
    
        // Calculate the aspect ratio of the image
        const aspectRatio = this.image.width / this.image.height
        
        
        let minWidth: number = 0
        let minHeight: number = 0
        
        if(this.cropType == 'circle') {
            minWidth = this.circle.radius * 2
            minHeight = this.circle.radius * 2    
        }
        
        if(this.cropType == 'rect') {
            minWidth = this.rect.width
            minHeight = this.rect.height
        }
        
    
        // Check if the image is portrait or landscape
        if (aspectRatio > 1) {
            // Landscape: scale based on width
            this.imageWidth = canvasWidth * this.scale
            this.imageHeight = this.imageWidth / aspectRatio
        } else {
            // Portrait or square: scale based on height
            this.imageHeight = canvasHeight * this.scale
            this.imageWidth = this.imageHeight * aspectRatio
        }
        
        // Ensure image respects the minimum size constraints
        if (this.imageHeight < minHeight) {
            this.scale = this.prevScale
            this.imageHeight = minHeight
            this.imageWidth = this.imageHeight * aspectRatio
        }

        if (this.imageWidth < minWidth) {
            this.scale = this.prevScale
            this.imageWidth = minWidth
            this.imageHeight = this.imageWidth / aspectRatio
        }
        
    
        // Calculate the position to center the image in the canvas
        this.imageX = (canvasWidth - this.imageWidth) / 2
        this.imageY = (canvasHeight - this.imageHeight) / 2
        
        
        if(this.cropType == 'circle') {
            this.setCirclePosition(this.circle.x, this.circle.y)    
        }
        
        if(this.cropType == 'rect') {
            this.setRectPosition(this.rect.x, this.rect.y)
        }
        
        this.prevScale = this.scale
    }
    
    private draw(): void {
        if(!this.context) {
            return
        }
        
        const canvas = this.canvas.nativeElement
        const image = this.image

        const canvasWidth = canvas.width
        const canvasHeight = canvas.height

        // Clear the canvas
        this.context.clearRect(0, 0, canvasWidth, canvasHeight)

        // Draw the image
        this.context.drawImage(image, this.imageX, this.imageY, this.imageWidth, this.imageHeight)
        
        // Draw the overlay
        this.context.fillStyle = 'rgba(0, 0, 0, 0.5)'
        this.context.fillRect(0, 0, canvasWidth, canvasHeight)

        // Draw the circle
        if(this.cropType == 'circle') {
            this.context.save()
            this.context.beginPath()
            this.context.arc(this.circle.x, this.circle.y, this.circle.radius, 0, Math.PI * 2)
            this.context.clip()
            this.context.clearRect(this.circle.x - this.circle.radius, this.circle.y - this.circle.radius, this.circle.radius * 2, this.circle.radius * 2)
            this.context.drawImage(image, this.imageX, this.imageY, this.imageWidth, this.imageHeight)
            this.context.restore()

            // Draw the circle border
            this.context.beginPath()
            this.context.arc(this.circle.x, this.circle.y, this.circle.radius, 0, Math.PI * 2)
            this.context.strokeStyle = 'white'
            this.context.lineWidth = 2
            this.context.stroke()    
        }
        
        if(this.cropType == 'rect') {
            this.context.save()
            this.context.beginPath()
            this.context.rect(this.rect.x, this.rect.y, this.rect.width, this.rect.height)
            this.context.clip()
            this.context.clearRect(this.rect.x, this.rect.y, this.rect.width, this.rect.height)
            this.context.drawImage(image, this.imageX, this.imageY, this.imageWidth, this.imageHeight)
            this.context.restore()

            // Draw the rectangle border
            this.context.beginPath()
            this.context.rect(this.rect.x, this.rect.y, this.rect.width, this.rect.height)
            this.context.strokeStyle = 'white'
            this.context.lineWidth = 2
            this.context.stroke()
        }
        
    }
    
    private addEventListeners(): void {
        const canvas = this.canvas.nativeElement
        
        if(this.cropType == 'circle') {
            canvas.addEventListener('mousedown', this.startDraggingCircle.bind(this))
            canvas.addEventListener('mousemove', this.dragCircle.bind(this))
            canvas.addEventListener('mouseup', this.stopDraggingCircle.bind(this))
            canvas.addEventListener('mouseleave', this.stopDraggingCircle.bind(this))
        
            canvas.addEventListener('touchstart', this.startDraggingCircle.bind(this))
            canvas.addEventListener('touchmove', this.handleTouchMoveCircle.bind(this))
            canvas.addEventListener('touchend', this.stopDraggingCircle.bind(this))
            canvas.addEventListener('touchcancel', this.stopDraggingCircle.bind(this))    
        }
        
        
        if(this.cropType == 'rect') {
            canvas.addEventListener('mousedown', this.startDraggingRect.bind(this))
            canvas.addEventListener('mousemove', this.dragRect.bind(this))
            canvas.addEventListener('mouseup', this.stopDraggingRect.bind(this))
            canvas.addEventListener('mouseleave', this.stopDraggingRect.bind(this))
        
            canvas.addEventListener('touchstart', this.startDraggingRect.bind(this))
            canvas.addEventListener('touchmove', this.handleTouchMoveRect.bind(this))
            canvas.addEventListener('touchend', this.stopDraggingRect.bind(this))
            canvas.addEventListener('touchcancel', this.stopDraggingRect.bind(this))
        }
    }
    
    private startDraggingCircle(event: MouseEvent | TouchEvent): void {
        const { x, y } = this.getEventPosition(event)
        const distance = Math.hypot(x - this.circle.x, y - this.circle.y)

        if (distance < this.circle.radius) {
            this.circle.dragging = true
        }
        
        if (event instanceof TouchEvent && event.touches.length === 2) {
            this.lastDistance = this.getDistanceBetweenTouches(event)
        }
    }
    
    private startDraggingRect(event: MouseEvent | TouchEvent): void {
        const { x, y } = this.getEventPosition(event)
    
        // Check if the event position is within the rectangle's bounds
        if (
            x >= this.rect.x &&
            x <= this.rect.x + this.rect.width &&
            y >= this.rect.y &&
            y <= this.rect.y + this.rect.height
        ) {
            this.rect.dragging = true
        }
        
        if (event instanceof TouchEvent && event.touches.length === 2) {
            this.lastDistance = this.getDistanceBetweenTouches(event)
        }
    }
    
    private dragCircle(event: MouseEvent | TouchEvent): void {
        if (!this.circle.dragging) {
            return
        }
        
        const { x, y } = this.getEventPosition(event)
        
        let circleBounds = this.getCircleBounds(x, y)
        
        this.setCirclePosition(circleBounds.x, circleBounds.y)
        this.draw()
    }
    
    private dragRect(event: MouseEvent | TouchEvent): void {
        if (!this.rect.dragging) {
            return
        }
        
        const { x, y } = this.getEventPosition(event)
        
        let rectangleBounds = this.getRectangleBounds(x, y)
        
        this.setRectPosition(rectangleBounds.x, rectangleBounds.y)
        this.draw()
    }
    
    private handleTouchMoveCircle(event: TouchEvent): void {
        if (this.circle.dragging && event.touches.length === 1) {
            const { x, y } = this.getEventPosition(event)
            let circleBounds = this.getCircleBounds(x, y)
            this.setCirclePosition(circleBounds.x, circleBounds.y)
            this.draw()
            event.preventDefault()
            return
        }
        
        if(event.touches.length === 2) {
            const distance = this.getDistanceBetweenTouches(event)

            if (this.lastDistance) {
                const scaleChange = distance / this.lastDistance
                this.scale *= scaleChange

                this.calculateImageDimensions()
            }

            this.lastDistance = distance
            this.draw()
        }
        
        event.preventDefault()
    }
    
    private handleTouchMoveRect(event: TouchEvent): void {
        if (this.rect.dragging && event.touches.length === 1) {
            const { x, y } = this.getEventPosition(event)
            let rectBounds = this.getRectangleBounds(x, y)
            this.setRectPosition(rectBounds.x, rectBounds.y)
            this.draw()
            event.preventDefault()
            return
        }
    
        if (event.touches.length === 2) {
            const distance = this.getDistanceBetweenTouches(event)
    
            if (this.lastDistance) {
                const scaleChange = distance / this.lastDistance
                this.scale *= scaleChange
    
                this.calculateImageDimensions()
            }
    
            this.lastDistance = distance
            this.draw()
        }
        
        event.preventDefault()
    }
    
    private stopDraggingCircle(): void {
        this.circle.dragging = false
        this.lastDistance = null
    }
    
    private stopDraggingRect(): void {
        this.rect.dragging = false
        this.lastDistance = null
    }
    
    private getEventPosition(event: MouseEvent | TouchEvent): { x: number, y: number } {
        const rect = this.canvas.nativeElement.getBoundingClientRect()
        let clientX: number
        let clientY: number

        if (event instanceof MouseEvent) {
            clientX = event.clientX
            clientY = event.clientY
        } else {
            clientX = event.touches[0].clientX
            clientY = event.touches[0].clientY
        }

        return {
            x: clientX - rect.left,
            y: clientY - rect.top
        }
    }
    
    private getDistanceBetweenTouches(event: TouchEvent): number {
        const touch1 = event.touches[0]
        const touch2 = event.touches[1]
        return Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY)
    }
    
    private getCircleBounds(x: number, y: number): { x: number, y: number } {
        const canvas = this.canvas.nativeElement
        const canvasWidth = canvas.width
        const canvasHeight = canvas.height
    
        // Determine the bounds based on the image size relative to the canvas size
        let boundXMin: number, boundXMax: number, boundYMin: number, boundYMax: number

        if (this.imageWidth > canvasWidth) {
            // If the image is wider than the canvas, constrain the circle within the canvas width
            boundXMin = this.circle.radius
            boundXMax = canvasWidth - this.circle.radius
        } else {
            // Otherwise, constrain the circle within the image width
            boundXMin = this.imageX + this.circle.radius
            boundXMax = this.imageX + this.imageWidth - this.circle.radius
        }

        if (this.imageHeight > canvasHeight) {
            // If the image is taller than the canvas, constrain the circle within the canvas height
            boundYMin = this.circle.radius
            boundYMax = canvasHeight - this.circle.radius
        } else {
            // Otherwise, constrain the circle within the image height
            boundYMin = this.imageY + this.circle.radius
            boundYMax = this.imageY + this.imageHeight - this.circle.radius
        }
        
        
        return { 
            x: Math.max(boundXMin, Math.min(x, boundXMax)), 
            y: Math.max(boundYMin, Math.min(y, boundYMax))
        }
    }
    
    private getRectangleBounds(x: number, y: number): { x: number, y: number } {
        const canvas = this.canvas.nativeElement
        const canvasWidth = canvas.width
        const canvasHeight = canvas.height
    
        const halfRectWidth = this.rect.width / 2
        const halfRectHeight = this.rect.height / 2
    
        // Determine the bounds based on the image size relative to the canvas size
        let boundXMin: number, boundXMax: number, boundYMin: number, boundYMax: number
    
        if (this.imageWidth > canvasWidth) {
            // If the image is wider than the canvas, constrain the rectangle within the canvas width
            boundXMin = this.imageX
            boundXMax = canvasWidth - halfRectWidth
        } else {
            // Otherwise, constrain the rectangle within the image width
            boundXMin = this.imageX
            boundXMax = this.imageX + this.imageWidth - halfRectWidth
        }
    
        if (this.imageHeight > canvasHeight) {
            // If the image is taller than the canvas, constrain the rectangle within the canvas height
            boundYMin = this.imageY
            boundYMax = canvasHeight - halfRectHeight
        } else {
            // Otherwise, constrain the rectangle within the image height
            boundYMin = this.imageY
            boundYMax = this.imageY + this.imageHeight - halfRectHeight
        }
    
        // Return the constrained x and y coordinates within the rectangle's bounds
        return { 
            x: Math.max(boundXMin, Math.min(x, boundXMax)), 
            y: Math.max(boundYMin, Math.min(y, boundYMax))
        }
    }
    
    private setCirclePosition(x: number, y: number): void {
        this.circle.x = Math.max(this.imageX + this.circle.radius, Math.min(x, this.imageX + this.imageWidth - this.circle.radius))
        this.circle.y = Math.max(this.imageY + this.circle.radius, Math.min(y, this.imageY + this.imageHeight - this.circle.radius))
    }
    
    private setRectPosition(x: number, y: number): void {
        // Calculate the visible boundaries of the image (the part of the image visible on the canvas)
        const visibleXMin = Math.max(0, this.imageX) // Visible left boundary (image may be off-canvas)
        const visibleYMin = Math.max(0, this.imageY) // Visible top boundary (image may be off-canvas)

        const visibleXMax = Math.min(this.canvas.nativeElement.width, this.imageX + this.imageWidth) // Visible right boundary
        const visibleYMax = Math.min(this.canvas.nativeElement.height, this.imageY + this.imageHeight) // Visible bottom boundary

    
        const newX = x - this.rect.width / 2
        const newY = y - this.rect.height / 2
        
        this.rect.x = Math.max(visibleXMin, Math.min(newX, visibleXMax - this.rect.width))
        this.rect.y = Math.max(visibleYMin, Math.min(newY, visibleYMax - this.rect.height))
    }
    
    private storeRectPositionRelativeToImage(): void {
        // Store the rectangle's position relative to the image dimensions
        this.relativeRectX = (this.rect.x - this.imageX) / this.imageWidth
        this.relativeRectY = (this.rect.y - this.imageY) / this.imageHeight
    }
    
    private restoreRectPositionAfterScaling(): void {
        // Restore the rectangle's position relative to the new image dimensions
        this.rect.x = this.imageX + this.relativeRectX * this.imageWidth
        this.rect.y = this.imageY + this.relativeRectY * this.imageHeight

        // Now constrain the rectangle within the visible image boundaries
        const visibleXMin = Math.max(0, this.imageX)
        const visibleYMin = Math.max(0, this.imageY)
        const visibleXMax = Math.min(this.canvas.nativeElement.width, this.imageX + this.imageWidth)
        const visibleYMax = Math.min(this.canvas.nativeElement.height, this.imageY + this.imageHeight)

        this.rect.x = Math.max(visibleXMin, Math.min(this.rect.x, visibleXMax - this.rect.width))
        this.rect.y = Math.max(visibleYMin, Math.min(this.rect.y, visibleYMax - this.rect.height))
    }

    zoomIn(): void {
        this.storeRectPositionRelativeToImage()
        this.scale *= 1.1
        this.calculateImageDimensions()
        this.restoreRectPositionAfterScaling()
        this.draw()
    }
    
    zoomOut(): void {
        this.storeRectPositionRelativeToImage()
        this.scale *= 0.9
        this.calculateImageDimensions()
        this.restoreRectPositionAfterScaling()
        this.draw()
    }
    
    cropImage(): string | void {
        const tempCanvas = document.createElement('canvas')
        const tempContext = tempCanvas.getContext('2d')
        
        if(this.cropType == 'circle'){
            return this.cropToCircle(tempCanvas, tempContext)    
        }
        
        
        if(this.cropType == 'rect') {
            return this.cropToRect(tempCanvas, tempContext)
        }
    }
    
    cropToCircle(tempCanvas: HTMLCanvasElement, tempContext: CanvasRenderingContext2D | null): string | void {
        // Set the dimensions of the temporary canvas to match the circle's diameter
        tempCanvas.width = this.circle.radius * 2
        tempCanvas.height = this.circle.radius * 2

        if (tempContext) {
            // Create a circular clipping path
            tempContext.beginPath()
            tempContext.arc(this.circle.radius, this.circle.radius, this.circle.radius, 0, Math.PI * 2)
            tempContext.clip()
    
            // Calculate the source rectangle on the main canvas
            const sx = this.circle.x - this.circle.radius
            const sy = this.circle.y - this.circle.radius
            const sWidth = this.circle.radius * 2
            const sHeight = this.circle.radius * 2
    
            // Draw the relevant portion of the main canvas onto the temporary canvas
            tempContext.drawImage(this.canvas.nativeElement, sx, sy, sWidth, sHeight, 0, 0, sWidth, sHeight)
    
            // Convert the temporary canvas to a base64 image
            return tempCanvas.toDataURL('image/png')
        } else {
            alert('Something went wrong when trying to save your image')
        }
    }
    
    cropToRect(tempCanvas: HTMLCanvasElement, tempContext: CanvasRenderingContext2D | null): string | void {
        // Set the tempCanvas size based on the rectangle
        tempCanvas.width = this.defaultRectWidth
        tempCanvas.height = this.defaultRectHeight
        
        if (tempContext) {
            const scaleX = this.image.width / this.imageWidth
            const scaleY = this.image.height / this.imageHeight
            const sourceX = (this.rect.x - this.imageX) * scaleX
            const sourceY = (this.rect.y - this.imageY) * scaleY
            const sWidth = this.rect.width * scaleX
            const sHeight = this.rect.height * scaleY

            // Draw the relevant portion of the main canvas onto the temporary canvas
            tempContext.drawImage(this.image, sourceX, sourceY, sWidth, sHeight, 0, 0, this.defaultRectWidth, this.defaultRectHeight)

            // Convert the temporary canvas to a base64 image
            return tempCanvas.toDataURL('image/png')
        }
    }
    
    cancel(): void {
        this.canceled.emit()
    }
    
    apply(): void {
        let url = this.cropImage()
        
        if(typeof url != 'string') {
            return
        }
        
        this.applied.emit(url)
    }
}
