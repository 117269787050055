import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'formless',
  templateUrl: './formless.component.html',
  styleUrls: ['./formless.component.scss']
})
export class FormlessComponent {
    @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
    
    doCloseForm(): void {
        this.closeForm.emit()
    }
}
