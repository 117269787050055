import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Business, BusinessClaim, BusinessWithOwnership, Config, BusinessCategory, ConfirmBusiness } from '../_models'
import { Observable } from 'rxjs'
import { Socket } from 'ngx-socket-io'

@Injectable({ providedIn: 'root' })
export class BusinessAPIService {
    private roomId: string = ''
    
    constructor(
        private http: HttpClient,
        private socket: Socket,
    ) { 
        this.socket.on('connect', () => {
            this.joinRoom(this.roomId)
        })
        
        this.socket.on('disconnect', () => {
            this.leaveRoom(this.roomId)
        })
        
        this.joinRoom(this.roomId)
    }
    
    joinRoom(roomId: string): void {
        this.socket.emit('join_room', roomId)
    }
    
    leaveRoom(roomId: string): void {
        this.socket.emit('leave_room', roomId)
    }
    
    listenForBusinessApproval(roomId: string): Observable<Business> {
        this.joinRoom(roomId)
        return this.socket.fromEvent<Business>('business_approved')
    }
    
    getBusiness(id: string) {
        return this.http.get<Business>(`${Config.apiUrl}/business/${id}`)    
    }
    
    addBusiness(business: FormData) {
        return this.http.post<Business>(`${Config.apiUrl}/business`, business)
    }
    
    updateBusiness(business: FormData) {
        return this.http.post<Business>(`${Config.apiUrl}/business/update`, business)
    }
    
    deleteBusiness(id: string) {
        return this.http.delete<any>(`${Config.apiUrl}/business/${id}`)
    }
    
    list(limit: number = 0, search: string | null = null) {
        
        let params: any = {
            limit: limit
        }
        
        if(search) {
            params['search'] = search
        }
        
        return this.http.get<Business[]>(`${Config.apiUrl}/business`, { params: params } )
    }
    
    getHiveBusinesses(handle: string, limit: number = 0) {
        return this.http.get<Business[]>(`${Config.apiUrl}/business/hive/${handle}`, { params: { limit: limit } } )
    }
    
    getBusinessWithOwnership(handle: string) {
        return this.http.get<BusinessWithOwnership>(`${Config.apiUrl}/business/${handle}/with-ownership`)
    }

    getBusinessByName(name: string): Observable<Business> {
        return this.http.get<Business>(`${Config.apiUrl}/business/exists`, { params: {name} })
    }
    
    getClaims(id: string): Observable<BusinessClaim[]> {
        return this.http.get<BusinessClaim[]>(`${Config.apiUrl}/business/${id}/claims`)
    }
    
    approveClaim(business: Business, userId: string): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/business/${business.id}/claims/approve`, { user_id: userId })
    }
    
    denyClaim(businessId: string, userId: string): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/business/${businessId}/claims/deny`, { user_id: userId })
    }
    
    confirmAndClaimBusiness(businessId: string, token: string, businessEmail: string, userId: string): Observable<Business> {
        return this.http.get<Business>(`${Config.apiUrl}/business/confirm`, { params: { 
            business_id: businessId, 
            token: token, 
            business_email: businessEmail,
            user_id: userId,
        }})
    }
    
    createClaimBusinessConfirmation(business: FormData): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/business/confirm`, business )
    }
    getBusinessCategories(): Observable<BusinessCategory[]> {
        return this.http.get<BusinessCategory[]>(`${Config.apiUrl}/business/categories`)
    }

    getMyBusinesses(): Observable<Business[]> {
        return this.http.get<Business[]>(`${Config.apiUrl}/business/my-businesses`)
    }
    
    getMyBusinessesNotIn(hive: string): Observable<Business[]> {
        return this.http.get<Business[]>(`${Config.apiUrl}/business/my-businesses/not-in/hive/${hive}`)
    }
    
    getMyBusinessClaims(): Observable<Business[]> {
        return this.http.get<Business[]>(`${Config.apiUrl}/business/claims`)
    }
    
    getBusinessConfirmation(businessId: string): Observable<ConfirmBusiness> {
        return this.http.get<ConfirmBusiness>(`${Config.apiUrl}/business/${businessId}/pending-confirmation`)
    }

    addBusinessToHives(businessId: string, hiveIds: string[]): Observable<any> {
        return this.http.post<any>(`${Config.apiUrl}/business/hive/post`, {businessId: businessId, hiveIds: hiveIds});
    }
    
    updateImage(id: string, data: {}): Observable<Business> {
        return this.http.patch<Business>(`${Config.apiUrl}/business/${id}/image`, data)
    }
}