<div [formGroup]="form">
    <div class="dropzone" 
        (click)="onClick()" 
        (dragenter)="onDragEnter($event)" 
        (dragleave)="onDragLeave($event)" 
        (dragover)="onDragOver($event)"
        (dragend)="onDragEnd($event)"
        (drop)="onDrop($event)" [ngClass]="{'hover': dragEntered}"
        [style.background]=" imgPreviewUrl ? 'url('+ imgPreviewUrl + ') center / cover no-repeat' : ''">
        
        <img *ngIf="!imgPreviewUrl" src="/assets/icon-add-image.svg" alt="add image icon">
        <p *ngIf="!imgPreviewUrl">{{ label }}</p>
    </div>
    <input #fileInput type="file" formControlName="{{ inputName }}" (change)="fileChanged($event)">
    
    <div *ngIf="form.get(inputName)?.errors && form.get(inputName)?.errors?.['fileType']" class="error">
        Invalid image: Use png, jpg, jpeg, or webp
    </div>
    <div *ngIf="form.get(inputName)?.errors && form.get(inputName)?.errors?.['maxSize']" class="error">
        Max image size: {{ maxFileSize }}MB
    </div>
</div>