<div class="node-card" [ngClass]="{'no-boundary': noBoundary}">
    <div class="node-interaction">
        <div class="node-tile">
            <div class="node-tile-image">
                <img *ngIf="event.image" src="{{ event.image }}" alt="event image">
            </div>
            
            <div class="node-tile-info">
                <p title="{{ event.name }}">{{ event.name }}</p>
                <p class="text-muted" *ngIf="relationship">{{ relationship }}</p>
            </div>
        </div>
        <button class="btn btn-secondary" [ngClass]="{'added': added}" (click)="toggleAdd()">{{ addBtnTxt }}</button>
    </div>
</div>