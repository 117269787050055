
<h5 *ngIf="!product?.variants?.length && !offer">We couldn't find this product.</h5>
<form *ngIf="product?.variants?.length || offer" [formGroup]="productResultsForm" class="modal-form">
    <div *ngIf="!offer">
        <div class="field-group">
            <user-owned-entities-dropdown [form]="productResultsForm" [type]="'product'"></user-owned-entities-dropdown>    
        </div>

        <h4>{{product?.name}}</h4>
        <image-slider *ngIf="product?.images" [images]="product?.images"></image-slider>
        <div *ngFor="let item of filteredResults" class="product-attribute">
            <div class="key">{{ item.key }}</div>
            <div class="value">{{ item.value }}</div>
        </div>
    </div>
    
    <div *ngIf="offer">
        <h4>{{ offer.variant.product.name }}</h4>
        <image-slider *ngIf="offer.variant.product.images" [images]="offer.variant.product.images"></image-slider>
    </div>
    

    <div class="field-group fancy-shmancy">
        <div>
            <select class="has-content" formControlName="condition">
                <option value="" disabled>Select Condition</option>
                <option value="New">New</option>
                <option value="Like New">Like New</option>
                <option value="Very Good">Very Good</option>
                <option value="Good">Good</option>
                <option value="Acceptable">Acceptable</option>
            </select>
            <label>Condition</label>
        </div>
        <div *ngIf="condition?.errors && condition?.touched" class="error">Condition required</div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <input #priceInput priceMask type="text" formControlName="price" hasContent>
            <label>Price</label>
        </div>
        
        <div *ngIf="price?.errors && price?.touched" class="error">Enter a valid price (0.00)</div>
    </div>
    
    <div class="field-group fancy-shmancy">
        <div>
            <input type="text" formControlName="home_link" hasContent>
            <label>Link to product</label>
        </div>
        <div *ngIf="homeLink?.errors && homeLink?.touched" class="error">Invalid URL</div>
    </div>

    <div class="field-group fancy-shmancy">
        <div>
            <input type="text" formControlName="sku" hasContent>
            <label>SKU</label>
        </div>
    </div>
</form>