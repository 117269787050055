import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PublicPlusService implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    private routeHeaderState: { [url: string]: { isOpen: boolean } } = {}
    
    isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
        
    constructor(private router: Router) {
        this.subscriptions.add(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(() => {
                    const currentUrl = this.router.url
                    
                    if (this.routeHeaderState[currentUrl]) {
                        // Restore header state for the current route
                        const state = this.routeHeaderState[currentUrl]
                        this.isOpen.next(state.isOpen)
                    } else {
                        // If no state exists for the current route, store the current state
                        this.saveCurrentState()
                    }
                })
        )
        
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    saveCurrentState(): void {
        const currentUrl = this.router.url
        
        this.routeHeaderState[currentUrl] = {
            isOpen: this.isOpen.getValue(),
        }
    }
    
    open(): void {
        this.isOpen.next(true)
    }
    
    close(): void {
        this.isOpen.next(false)
    }
}
