import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { CountrySelector } from 'src/app/_models';

@Component({
  selector: 'country-selector',
  templateUrl: './country-selector.component.html',
  styleUrl: './country-selector.component.scss'
})
export class CountrySelectorComponent {
    @Output() countrySelected: EventEmitter<CountrySelector> = new EventEmitter<CountrySelector>()
    
    COUNTRIES: CountrySelector[] = [
        { name: 'United States', dialCode: '+1' },
        // { name: 'Canada', dialCode: '+1' },
        // { name: 'United Kingdom', dialCode: '+44' },
        // { name: 'Australia', dialCode: '+61' },
    ]
    filteredCountries: CountrySelector[] = this.COUNTRIES
    selectedCountryName: string = ''
    phoneNumber: string = ''
    showDropdown: boolean = false
    currentHighlightIndex: number = 0
    dropdownClicked: boolean = false
    
    constructor(private elem: ElementRef, private cdRef: ChangeDetectorRef) {}
    
    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.input-group')) {
            this.showDropdown = false
        }
    }
  
    filterCountries(): void {
        const searchValue = this.selectedCountryName.toLowerCase()
        
        if(!searchValue) {
            this.filteredCountries = this.COUNTRIES
        } else {
            this.filteredCountries = this.COUNTRIES.filter(country =>
                country.name.toLowerCase().includes(searchValue)
            )    
        }
        
        
        this.cdRef.detectChanges();
        this.highlightCountry(0)
    }
    
    manageKeyPress(e: KeyboardEvent): void {
        if(e.key == "Enter") {
            if(this.filteredCountries) {
                this.selectHighlightedCountry()
            }
            
            return
        }
        
        if(e.key == "ArrowUp") {
            this.moveHighlightUp()
            return
        }
        
        // Down arrow key pressed
        if(e.key == "ArrowDown") {
            this.moveHighlightDown()
            return
        }
        
        // Escape key pressed
        if(e.key == "Escape") {
            this.hideSuggestions()
            return
        }
    }
    
    selectCountry(country: { name: string, dialCode: string }): void {
        this.dropdownClicked = true
        this.selectedCountryName = country.name
        this.phoneNumber = country.dialCode + ' '
        this.hideSuggestions()
        
        this.countrySelected.emit(country)
    }
    
    highlightCountry(index: number): void {
        console.log(this.filteredCountries.length, this.filteredCountries[index])
        if(!this.filteredCountries.length || !this.filteredCountries[index]) {
            return;
        }
        
        this.currentHighlightIndex = index
        
            this.setHighlight()    
        
    }
    
    setHighlight(): void {
        let highlightedCountry = this.elem.nativeElement.querySelector('.highlight')
        let suggestions = this.elem.nativeElement.querySelectorAll('.dropdown-item')
        
        if(highlightedCountry) {
            highlightedCountry.classList.remove('highlight')
        }
        
        if(!suggestions.length) {
            return
        }
        
        suggestions[this.currentHighlightIndex].classList.add('highlight')
        
        
        this.scrollToItem(suggestions)
    }
    
    scrollToItem(suggestions: any): void {
        suggestions[this.currentHighlightIndex].scrollIntoView({ block: 'nearest' })
    }
    
    moveHighlightDown(): void {
        if(!this.filteredCountries) {
            return
        }

        if(this.currentHighlightIndex == this.filteredCountries.length) {
            this.currentHighlightIndex = 0
        } else {
            this.currentHighlightIndex++
        }
        
        this.highlightCountry(this.currentHighlightIndex)
    }
    
    moveHighlightUp(): void {
        if(!this.filteredCountries) {
            return
        }
        
        if(this.currentHighlightIndex == 0) {
            this.currentHighlightIndex = this.filteredCountries.length
        } else {
            this.currentHighlightIndex--
        }
        
        this.highlightCountry(this.currentHighlightIndex)
    }
    
    selectHighlightedCountry(): void {
        if(!this.filteredCountries || !this.filteredCountries[this.currentHighlightIndex]) {
            return
        }
        
        this.selectCountry(this.filteredCountries[this.currentHighlightIndex])
    }
    
    onBlur() {
        if (!this.dropdownClicked) {
            this.hideSuggestions()
        }
        
        this.dropdownClicked = false
    }

    hideSuggestions(): void {
        this.showDropdown = false
        this.filteredCountries = this.COUNTRIES
    }
}
