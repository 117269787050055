import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAPIService } from 'src/app/_api-services';
import { SearchType } from 'src/app/_enums';
import { User } from 'src/app/_models';
import { AuthenticationService, ModalService } from 'src/app/_services';

@Component({
  selector: 'event-add-members',
  templateUrl: './event-add-members.component.html',
  styleUrl: './event-add-members.component.scss'
})
export class EventAddMembersComponent implements OnDestroy {
    @Input() selectedMemberIds: string[] = []
    @Output() changeStep: EventEmitter<number> = new EventEmitter<number>()
    @Output() addMembers: EventEmitter<string[]> = new EventEmitter<string[]>()
    
    private subscpriptions: Subscription = new Subscription()
    
    defaultMembers: User[] | null = null
    members: User[] | null = null
    unregisteredEmails: string[] = []
    
    searchType: SearchType = SearchType.HivePeers
    
    constructor(
        private authService: AuthenticationService,
        private userAPIService: UserAPIService,
        private modalService: ModalService
    ) {
        this.subscpriptions.add(
            this.userAPIService.getCurrentUserHiveContacts().subscribe({
                next: (members) => {
                    this.defaultMembers = members
                    this.members = this.defaultMembers
                },
                error: (err) => {
                    console.log(err)
                }
            })
        )
        
        this.subscpriptions.add(
            this.modalService.doFooterAction.subscribe( action => {
                this.inviteMembers()
            })
        )
        
        this.modalService.updateFooterBtnTxt('Skip')
    }
    
    ngOnDestroy(): void {
        this.subscpriptions.unsubscribe()
    }
    
    toggleMemberSelection(id: string) {
        const index = this.selectedMemberIds.indexOf(id);

        if (index == -1) {
            this.selectedMemberIds.push(id); 
        } else {
            this.selectedMemberIds.splice(index, 1); 
        }
        
        this.updateButton()
    }
    
    updateButton(): void {
        if(this.selectedMemberIds.length > 0) {
            this.modalService.updateFooterBtnTxt('Invited Selected')
            return
        }
        
        this.modalService.updateFooterBtnTxt('Skip')
    }
    
    setMembers(members: [User[] | null, boolean, string[]]): void {
        const removeDuplicates = (arr: User[], key: keyof User) => {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        }
        
        let users = members[0]
        let bool = members[1]
        let unregisteredEmails = members[2]
        
        if (users && users.length < 1 && unregisteredEmails.length < 1) {
            this.members = null
            this.unregisteredEmails = this.getEmailsThatAreInvited()
            return
        }
        
        if (users && users.length > 0) {
            this.members = users.filter(person => person.id !== this.authService.currentUserValue.user.id)
            this.members = removeDuplicates(this.members, 'id')
            
            if(bool) {
                this.members.forEach((member:any) => {
                    if(!this.selectedMemberIds.includes(member.id)) {
                        this.selectedMemberIds.push(member.id)
                    }
                })
            }
            
        } else {
            this.members = this.defaultMembers
        }

        if (unregisteredEmails.length > 0) {
            this.unregisteredEmails = this.getEmailsThatAreInvited()
            this.unregisteredEmails = [...new Set([...this.unregisteredEmails, ...unregisteredEmails])]
            this.selectedMemberIds.push(...this.unregisteredEmails)
            this.selectedMemberIds = [...new Set(this.selectedMemberIds)]
        }
        
        if(this.selectedMemberIds.length) {
            this.modalService.updateFooterBtnTxt('Invite Members')
        }
    }
    
    getEmailsThatAreInvited(): string[] {
        if(!this.unregisteredEmails) {
            return []
        }
        
        let invitedEmails = this.selectedMemberIds.filter( id => this.unregisteredEmails?.includes(id))
        return invitedEmails
    }
    
    inviteMembers() {
        this.addMembers.emit(this.selectedMemberIds)
    }
}
