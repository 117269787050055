import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[priceMask]'
})
export class PriceMaskDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @HostListener('input', ['$event'])
    onInputChange(event: KeyboardEvent): void {
        const input = this.el.nativeElement;
        let value = input.value;
        let cursorPosition = input.selectionStart;

        // Remove all non-numeric characters except for the decimal point
        value = value.replace(/[^0-9.]/g, '');

        // Ensure the value has at least one digit before the decimal
        if (value.indexOf('.') === 0) {
            value = '0' + value;
            cursorPosition++;
        }

        // Limit to two decimal places
        const decimalIndex = value.indexOf('.');
        if (decimalIndex >= 0) {
            value = value.substring(0, decimalIndex + 3);
        }

        // Set the formatted value back to the input element
        this.renderer.setProperty(input, 'value', value);

        // Restore the cursor position
        if (cursorPosition !== null && cursorPosition <= value.length) {
            input.setSelectionRange(cursorPosition, cursorPosition);
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: Event): void {
        const input = this.el.nativeElement;
        let value = input.value;

        // Ensure the value has two decimal places
        if (value) {
            const parts = value.split('.');
            if (parts.length === 1) {
                value = `${value}.00`;
            } else if (parts.length === 2) {
                if (parts[1].length === 0) {
                    value = `${value}00`;
                } else if (parts[1].length === 1) {
                    value = `${value}0`;
                }
            }
        }

        // Set the formatted value back to the input element
        this.renderer.setProperty(input, 'value', value);
    }
}
