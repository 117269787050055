<div [ngClass]="{'search-wrapper': !name}">
    
    <div *ngIf="name" [formGroup]="group">
        <input type="text" #input hasContent 
            [formControlName]="name" 
            (keyup)="bindKeys($event)" 
            (keydown)="stopCursorReset($event)" 
            (blur)="resetDropdown()">
        <label *ngIf="label">{{ label }}</label>
    </div>
    
    <div *ngIf="!name" class="search">
        <img src="/assets/icon-search.svg" alt="search icon">
        <input type="text" #input hasContent [placeholder]="placeholderText" [value]="searchString"
            (keyup)="bindKeys($event)" 
            (keydown)="stopCursorReset($event)" 
            (blur)="resetDropdown()">
        <label *ngIf="label">{{ label }}</label>
    </div>

    <div class="relative">
        <div *ngIf="items.length" class="suggestions" stopPropagation>
            <div *ngFor="let item of items" class="suggestion" (mousedown)="selectDropdownItem(item)" stopPropagation>
                <div class="node-tile">
                    <div class="node-tile-image square">
                        <img *ngIf="item.image" src="{{ item.image }}" alt="{{item.name}}">
                        <img *ngIf="!item.image" src="/assets/listings/default-product-image.jpeg" alt="tile image">
                    </div>
                    
                    <div class="node-tile-info">
                        <p title="{{ item.name }}">{{ item.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>