import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../_models';

@Injectable({
    providedIn: 'root',
})
export class PostEditorService {
    addMention: Subject<User> = new Subject<User>()
    focus: Subject<void> = new Subject<void>()
    clear: Subject<void> = new Subject<void>()
    
    constructor() {}
}
