<div class="invite-members">

    <add-member-search [searchType]="searchType" (recordOutput)="setMembers($event)"></add-member-search>
    
    <p *ngIf="!members && !unregisteredEmails">No Member found</p>
    
    <div class="members" *ngIf="members || unregisteredEmails">
        <div *ngFor="let unregisteredEmail of unregisteredEmails" class="flex space-between flex-center member">
            <unregistered-user-card [email]="unregisteredEmail"></unregistered-user-card>
            <button class="add-btn btn btn-secondary btn-rounded" (click)="toggleMemberSelection(unregisteredEmail)" [ngClass]="{'added': selectedMemberIds.includes(unregisteredEmail)}">
                Invite
            </button>
        </div>
        <div *ngFor="let member of members" class="flex space-between flex-center member">
            <user-card  [tileOnly]="true" [user]="member" [relationship]="'In a hive you\'re in'"></user-card>
            <button class="add-btn btn btn-secondary btn-rounded" (click)="toggleMemberSelection(member.id)" [ngClass]="{'added': selectedMemberIds.includes(member.id)}">
                Invite
            </button>
        </div>
    </div>
</div>